import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from "./Cookies";

const PrivateRoute = () => {
    const token = getCookie("token");
    const isAuthenticated = token;

  return isAuthenticated ? <Outlet /> : <Navigate to='/' replace />;
};

export default PrivateRoute;
