import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./stacked.css";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { Typography } from "@mui/material";
import { AutoLogout } from "../../Services/AutoLogout";

const StackedBarChart1 = () => {
  const [apiRes, setApiRes] = useState([]);
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/pacs-count-by-state",
        {},
        config
      )
      .then((response) => {
        const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        if(response?.status === 200){
          setApiRes(decodedData);
        }
        else if (response?.response?.status === 403) {
          AutoLogout();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const categories = apiRes.map((item, index) =>
    index === 0 ? "Andaman Nicobar" : item.stateCode
  );

  const seriesData = [
    {
      name: "PACS Count",
      data: apiRes.map((item) => item.pacsCount),
      color: "#14B8A6",
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        trim: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    grid: {
      show: false,
    },
    colors: seriesData.map((series) => series.color),
  };

  return (
    <div className="stackCont">
      <Typography
        sx={{
          paddingLeft: "10px",
          paddingTop: "10px",
          fontSize: "20px",
          fontFamily: "Poppins",
        }}
      >
        Count of Registration no. of PACS
      </Typography>
      <div className="widthCheck">
        <ReactApexChart
          options={options}
          series={seriesData}
          type="bar"
          height={400}
        />
      </div>
    </div>
  );
};

export default StackedBarChart1;
