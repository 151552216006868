import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authenticatedEmail, setAuthenticatedEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [ok, setOk] = useState(false);
  const [district, setDistrict] = useState();
  const [state, setState] = useState();
  const [nav, setNav] = useState("");
  const [selectedBtnSanc, setSelectedBtnSanc] = useState(
    "Andaman and Nicobar Islands"
  );
  const [selectedBtnSancGr, setSelectedBtnSancGr] = useState(
    "Andaman and Nicobar Islands"
  );
  const [selectedYear, setSelectedYear] = useState(
    "2023-24"
  );
  const [funds, setFunds] = useState();
  const [fund1, setFund1] = useState();

  return (
    <AuthContext.Provider
      value={{
        fund1,
        setFund1,
        funds,
        setFunds,
        selectedBtnSanc,
        setSelectedBtnSanc,
        authenticatedEmail,
        setAuthenticatedEmail,
        accessToken,
        setAccessToken,
        ok,
        setOk,
        district,
        setDistrict,
        state,
        setState,
        nav,
        setNav,
        selectedBtnSancGr,
        setSelectedBtnSancGr,
        selectedYear,
        setSelectedYear
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
