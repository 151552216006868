import React, { useState } from "react";
import {
  Typography,
  Box,
  Stack,
  Divider,
  Button,
  Modal,
  Popover,
  MenuItem,
  Tooltip,
  Breadcrumbs,
  Link,
  Grid,
  tooltipClasses,
} from "@mui/material";
import Icon from "../../Assets/Logo.svg";
import { getCookie, setCookie } from "../../Services/Cookies";
import { useNavigate } from "react-router-dom";
import profileI from "../../Assets/profile.svg";
import profileC from "../../Assets/profilee.svg";
import atteandanceI from "../../Assets/attendance.svg";
import logouttwo from "../../Assets/logouttwo.svg";
import { AutoLogout } from "../../Services/AutoLogout";
import close from "../../Assets/close-gray.svg";
import infoGray from "../../Assets/info-gray.svg";
import styled from "@emotion/styled";
const MainHeader = ({ breadcrumbs }) => {
  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: "#065036",
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#065036",
  //     color: "#ffffff",
  //   },
  // }));
  // const [openDi, setOpenDi] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const avatarStyle = {
    width: "45px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#065036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: "600",
  };

  const initials = getCookie("userName")
    .split(" ")
    .map((name) => name[0])
    .join("");

  const open = Boolean(anchorEl);
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const [tooltip2Open, setTooltip2Open] = useState(false);

  // const handleTooltip2Toggle = () => {
  //     setTooltip2Open(!tooltip2Open);
  // };

  //   const handleTooltipToggle = () => {
  //       setTooltipOpen(!tooltipOpen);
  //   };
  //   const [phoneNumber, setPhoneNumber] = useState('');
  //   const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  //   const handleChange = (event) => {
  //       const value = event.target.value;
  //       setPhoneNumber(value);

  //       // Regex for validating a phone number (10 digits)
  //       const phoneRegex = /^\d{10}$/;

  //       // Check if the entered value matches the regex pattern
  //       const isValid = phoneRegex.test(value);
  //       setIsValidPhoneNumber(isValid);
  //   };

  return (
    <>
      <style>
        {`
        .MuiBreadcrumbs-separator{
          margin-left: 0px !important;
          margin-right: 0px !important
        }`}
      </style>
      <Stack
        sx={{
          paddingLeft: { xs: "10px", md: "10px" },
          paddingRight: { xs: "10px", md: "10px" },
          paddingTop: "0px",
          top: "0",
          zIndex: "1100",
          left: "auto",
          right: "0",
          width: "100%",
          backgroundColor: "white",
        }}
        direction="row"
        justifyContent={{ xs: "space-between", md: "space-between" }}
        alignItems="center"
        position="fixed"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          marginLeft={{ xs: "0px", md: "0px" }}
        >
          <Stack
            direction="row"
            spacing={1}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "#999999",
                  borderWidth: "1px",
                  marginTop: "10px !important",
                  height: "55px",
                  alignSelf: "auto",
                  display: { xs: "none", md: "flex" },
                }}
              />
            }
            alignItems="center"
            sx={{
              cursor: "pointer",
              display: { md: "flex" },
              width: { xs: "70px", md: "220px" },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              sx={{ display: { xs: "flex-start", md: "flex" } }}
            >
              <Stack
                sx={{
                  width: { xs: "40px", md: "50px" },
                  height: { xs: "40px", md: "52px" },
                  marginTop: "5px",
                }}
              >
                <img src={Icon} alt="" />
              </Stack>
              <Typography
                sx={{
                  color: "#065036",
                  fontWeight: "600",
                  fontFamily: "auto",
                  fontSize: { xs: "12px", md: "14px" },
                }}
              >
                NABARD
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: "#065036",
                width: { xs: "162px", md: "210px" },
                fontWeight: "500",
                fontSize: { xs: "10px", md: "12px" },
                lineHeight: "1.2",
                fontFamily: "'Open Sans', sans-serif",
                display: { xs: "none", md: "flex" },
                marginTop: "15px !important",
              }}
            >
              NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT
            </Typography>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              justifyContent: { xs: "flex-start", md: "flex-start" },
              display: { xs: "flex", md: "flex" },
              marginLeft: { xs: "5px", md: "17px !important" },
            }}
          >
            {breadcrumbs && (
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="none"
                  color="primary"
                  href="/datacollection"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "10px", md: "14px" },
                    lineHeight: "0px !important",
                    letterSpacing: "0rem !important",
                  }}
                >
                  Data Collection
                </Link>
                <Link
                  underline="none"
                  href="/DailyProgressReport"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "10px", md: "14px" },
                    lineHeight: "0px !important",
                    letterSpacing: "0rem !important",
                    color: "black",
                  }}
                >
                  Daily Progress Report
                </Link>
              </Breadcrumbs>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                // backgroundColor: '#065036',
                borderRadius: "6px",
              },
            }}
          >
            {/* <MenuItem
              onClick={() => { setOpenDi(true); setAnchorEl(null); }}
              sx={{
                fontSize: "13px",
                fontFamily: "Poppins",
                paddingBottom: { xs: "0px", md: "6px" },
                minHeight: { xs: "30px" },
              }}
            >
              <img alt="" src={profileC} style={{ paddingRight: "10px" }}></img>
              Complete Your Profile</MenuItem> */}
            <MenuItem
              sx={{
                fontSize: "13px",
                fontFamily: "Poppins",
                color: "#252525",
                minHeight: { xs: "30px" },
              }}
              onClick={() => {
                navigate("/attendance");
                setAnchorEl(null);
              }}
            >
              <img
                alt=""
                src={atteandanceI}
                style={{ paddingRight: "10px" }}
              ></img>
              Attendance
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "13px",
                fontFamily: "Poppins",
                color: "#252525",
                minHeight: { xs: "30px" },
              }}
              onClick={() => {
                AutoLogout();
                setAnchorEl(null);
              }}
            >
              <img
                alt=""
                src={logouttwo}
                style={{ paddingRight: "10px" }}
              ></img>
              Logout
            </MenuItem>
          </Popover>
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                fontFamily: "Poppins",
                display: { xs: "none", md: "flex" },
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              {" "}
              Home{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                marginRight: "20px !important",
                fontFamily: "Poppins",
                display: { xs: "none", md: "flex" },
              }}
              onClick={() => {
                handleDashboardClick();
              }}
            >
              {" "}
              Dashboard{" "}
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Tooltip>
              <div style={avatarStyle} onClick={handlePopoverOpen}>
                {initials}
              </div>
            </Tooltip>
          </Stack>
          <Typography
            noWrap
            style={{
              marginLeft: "10px",
              color: "#00261C",
              fontSize: "16px",
            }}
          >
            {getCookie("userName")}
          </Typography>
        </Stack>
      </Stack>
      {/* {openDi && (
        <Modal
          open={openDi}
          onClose={() => setOpenDi(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              bgcolor: "white",
              boxShadow: 24,
              borderRadius: "6px",
              pb:2,
            }}>
            <Grid container
              p={2}
            >
              <Grid item xs={8}
                sx={{
                  fontFamily: "Poppins",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Complete Your Profile
              </Grid>
              <Grid item xs={4}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <img
                  alt="close"
                  src={close}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px"
                  }}
                  onClick={() => { setOpenDi(false) }}
                ></img>
              </Grid>

            </Grid>
            <Divider
            sx={{
              borderColor:"#e6e6e6"
            }}
            />
            <Grid container p={2} pb={1}>
              <Grid item xs={4}
               sx={{
                fontFamily: "Poppins",
                color: "#000000",
                fontSize: "13px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              >Partner Name</Grid>
              <Grid item xs={8}>
                <input style={{
                  width: "240px",
                  height: "40px",
                  backgroundColor:"#f0f0f0",
                  borderRadius:"7.69px",
                  border:"1px solid #d0d5dd",
                  outline:"#d0d5dd",
                  color:"#c5c5c5",
                  padding:"15px",
                  fontFamily:"Poppins"
                }}
                value={"partner"}
                ></input>
              </Grid>
            </Grid>
            <Grid container p={2} pb={1}>
              <Grid item xs={4}
               sx={{
                fontFamily: "Poppins",
                color: "#000000",
                fontSize: "13px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              >Contact No.</Grid>
              <Grid item xs={6}>
                <input style={{
                  width: "220px",
                  height: "40px",
                  backgroundColor:"#ffffff",
                  borderRadius:"7.69px",
                  border:"1px solid #d0d5dd",
                  outline:"#d0d5dd",
                  color:"black",
                  padding:"15px",
                  fontFamily:"Poppins"
                }}></input>
              </Grid>
              <Grid item xs={2}
              container
              justifyContent="flex-end"
              alignItems="center"
              >
                <BootstrapTooltip
                    open={tooltipOpen}
                onClose={handleTooltipToggle}
                      title={
                        <span
                        style={{
                          fontFamily:"Poppins"
                        }}
                        >
                         No. should be within 10 digits
                        </span>
                      }
                      arrow
                    >
                <Button sx={{padding:"0px", minWidth:"0px"}}
                 onClick={handleTooltipToggle}
                >
                <img
                  alt="info"
                  src={infoGray}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px"
                  }}
                ></img>
                </Button>
                </BootstrapTooltip>

              </Grid>
            </Grid>
            <Grid container p={2} pb={1}>
              <Grid item xs={4}
               sx={{
                fontFamily: "Poppins",
                color: "#000000",
                fontSize: "13px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              >Deployment Date</Grid>
              <Grid item xs={8}>
                <input style={{
                  width: "240px",
                  height: "40px",
                  backgroundColor:"#f0f0f0",
                  borderRadius:"7.69px",
                  border:"1px solid #d0d5dd",
                  outline:"#d0d5dd",
                  color:"#c5c5c5",
                  padding:"15px",
                  fontFamily:"Poppins"
                }}></input>
              </Grid>
            </Grid>
            <Grid container p={2} pb={1}>
              <Grid item xs={4}
               sx={{
                fontFamily: "Poppins",
                color: "#000000",
                fontSize: "13px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              >Delivery contact no.</Grid>
              <Grid item xs={6}>
                <input 
                style={{
                  width: "220px",
                  height: "40px",
                  backgroundColor:"#ffffff",
                  borderRadius:"7.69px",
                  border: `${!isValidPhoneNumber?"1px solid red":"1px solid #d0d5dd"}`,
                  outline:"#d0d5dd",
                  color:"black",
                  padding:"15px",
                  fontFamily:"Poppins"
                }}
                onChange={(e)=>handleChange(e)}
                value={phoneNumber}
                ></input>
                {!isValidPhoneNumber && (
                <p style={{ color: 'red',fontSize:"9px",margin:"0px", fontFamily:"Poppins", width: "220px", }}>Please enter a valid phone number (10 digits).</p>
            )}
              </Grid>
              <Grid item xs={2}
              container
              justifyContent="flex-end"
              alignItems="center"
              >
                 <BootstrapTooltip
                    open={tooltip2Open}
                onClose={handleTooltip2Toggle}
                      title={
                        <span
                        style={{
                          fontFamily:"Poppins"
                        }}
                        >
                         No. should be within 10 digits
                        </span>
                      }
                      arrow
                    >
                <Button sx={{padding:"0px", minWidth:"0px"}}
                 onClick={handleTooltip2Toggle}
                >
                <img
                  alt="info"
                  src={infoGray}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px"
                  }}
                ></img>
                </Button>
                </BootstrapTooltip>

              </Grid>
            </Grid>
            <Grid container p={2} pb={1}
             justifyContent="center"
             alignItems="center"
            >
              <Button 
              variant="contained"
              sx={{
                textTransform:"capitalize",
                fontFamily:"Poppins",
                backgroundColor:"#065036",
                "&:hover": {
                  backgroundColor:"#065036",
                }
              }}
              >Complete Profile</Button>
            </Grid>
            
          </Box>
        </Modal>
      )} */}
    </>
  );
};

export default MainHeader;
