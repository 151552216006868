import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useAuth } from "../../Services/Auth";

const GoiBar = () => {
  const { selectedBtnSancGr, selectedYear } = useAuth();
  const [apiResponse, setApiResponse] = React.useState([]);
  const [apiResYear, setApiResYear] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
      const requestBody = {
        stateName: selectedBtnSancGr,
      };

      try {
        const response = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/state-goi",
          requestBody,
          config
        );

        if (response.status === 200) {
          setApiResponse(response.data);
          setApiResYear(response.data.financialYears);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedBtnSancGr]);

  console.log(apiResYear);

  const chartData = {
    series: [
      {
        name:
          selectedBtnSancGr === "Andaman and Nicobar Islands"
            ? "Andaman & Nicobar"
            : selectedBtnSancGr === "Jammu and Kashmir"
            ? "Jammu & Kashmir"
            : selectedBtnSancGr,
        data: [
          {
            x: "GoI Share Planned",
            y: Math.round(apiResponse.goiPlanned),
          },
          {
            x: selectedYear + " GoI Share",
            y: Math.round(apiResYear[selectedYear]) || 0,
          },
        ],
      },
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      colors: ["#01A76C", "#14B8A6"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        title: {
          text:
            selectedBtnSancGr === "Andaman and Nicobar Islands"
              ? "Andaman & Nicobar"
              : selectedBtnSancGr === "Jammu and Kashmir"
              ? "Jammu & Kashmir"
              : selectedBtnSancGr,
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
        title: {
          text: "Amount (in Crores)",
        },
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default GoiBar;
