import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "../../Services/Auth";

const Graph2 = () => {
  const [data, setData] = useState([]);
  const [mChange, setMChange] = useState(0);
  const [dChange, setDChange] = useState(0);
  const [bChange, setBChange] = useState(0);
  const [mChangeArrow, setMChangeArrow] = useState("");
  const [dChangeArrow, setDChangeArrow] = useState("");
  const [bChangeArrow, setBChangeArrow] = useState("");
  const[check, setCheck]=useState(false);
  const { state, setState, district, setDistrict } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        };

        let body = {};

        if (state && district) {
          body = {
            state: state,
            district: district,
          };
        } else if (state) {
          body = {
            state: state,
          };
        }

        const response = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/yoy-finance",
          body,
          config
        );

        if (response.status === 200) {
          setCheck(true);
          setData(response.data);

          if (response.data.length === 2) {
            const oldData = response.data[0];
            const newData = response.data[1];

            const mChangePercentage = (
              ((newData.averageLoanOutstanding -
                oldData.averageLoanOutstanding) /
                Math.abs(oldData.averageLoanOutstanding)) *
              100
            ).toFixed(2);
            const dChangePercentage = (
              ((newData.profit - oldData.profit) / Math.abs(oldData.profit)) *
              100
            ).toFixed(2);
            const bChangePercentage = (
              ((newData.reserves - oldData.reserves) /
                Math.abs(oldData.reserves)) *
              100
            ).toFixed(2);

            setMChange(mChangePercentage);
            setDChange(dChangePercentage);
            setBChange(bChangePercentage);

            const mChangeArrow =
              newData.averageLoanOutstanding < oldData.averageLoanOutstanding
                ? "↓"
                : "↑";
            const dChangeArrow = newData.profit < oldData.profit ? "↓" : "↑";
            const bChangeArrow =
              newData.reserves < oldData.reserves ? "↓" : "↑";

            setMChangeArrow(mChangeArrow);
            setDChangeArrow(dChangeArrow);
            setBChangeArrow(bChangeArrow);
          }
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchData();
  }, [state, district]);

  const series = [
    {
      name: "Average Loan Outstanding",
      data: [
        data[0]?.averageLoanOutstanding || 0,
        data[1]?.averageLoanOutstanding || 0,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: "Profit",
      data: [
        null,
        null,
        null,
        data[0]?.profit || 0,
        data[1]?.profit || 0,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: "Reserves",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        data[0]?.reserves || 0,
        data[1]?.reserves || 0,
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#57805c"],
    },
    colors: ["#57805c"],
    xaxis: {
      categories: ["FY22", "FY23", "", "FY22", "FY23", "", "FY22", "FY23"],
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Amount in crores",
      },
      labels: {
        formatter: function (val) {
          if(check){
            return val/10000000+"Cr";
          }
           return val+" Cr";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    annotations: {
      text: [
        {
          x: "FY22",
          y: data[0]?.averageLoanOutstanding || 0,
          text:
            "Average Loan Outstanding: " +
            (data[0]?.averageLoanOutstanding || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.averageLoanOutstanding || 0,
          text:
            "Average Loan Outstanding: " +
            (data[1]?.averageLoanOutstanding || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY22",
          y: data[0]?.profit || 0,
          text: "Profit: " + (data[0]?.profit || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.profit || 0,
          text: "Profit: " + (data[1]?.profit || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY22",
          y: data[0]?.reserves || 0,
          text: "Reserves: " + (data[0]?.reserves || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.reserves || 0,
          text: "Reserves: " + (data[1]?.reserves || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
      ],
    },
    legend: {
      show: false,
    },
  };

  return (
    <>
      <div className="widthCheck">
        <Stack position="relative">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={400}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "13%",
              fontSize: "14px",
              fontWeight: "bold",
              color: mChange < 0 ? "red" : "#E47D05",
            }}
          >
            {mChangeArrow} {Math.abs(mChange)}%
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "45%",
              fontSize: "14px",
              fontWeight: "bold",
              color: dChange < 0 ? "red" : "#E47D05",
            }}
          >
            {dChangeArrow} {Math.abs(dChange)}%
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "77%",
              fontSize: "14px",
              fontWeight: "bold",
              color: bChange < 0 ? "red" : "#E47D05",
            }}
          >
            {bChangeArrow} {Math.abs(bChange)}%
          </Typography>
          <Typography
            position="absolute"
            sx={{ fontSize: "13px", bottom:"0", marginLeft: "10%",fontFamily:"Poppins" }}
          >
            Average Loan Outstanding
          </Typography>
          <Typography
            position="absolute"
            sx={{ fontSize: "13px", bottom:"0", marginLeft: "45%",fontFamily:"Poppins" }}
          >
            Profit/Loss
          </Typography>
          <Typography
            position="absolute"
            sx={{ fontSize: "13px", bottom:"0", marginLeft: "77%",fontFamily:"Poppins" }}
          >
            Reserves
          </Typography>
        </Stack>
      </div>
    </>
  );
};

export default Graph2;
