import React, { useEffect } from "react";
import Header from "../Header/Header";
import searchIcon from "../../Assets/search 1.svg";
import dropdown from "../../Assets/Polygon 1.svg";
import { useState } from "react";
import "./PacsForm.css";
import down from "../../Assets/down1.svg";
import upload from "../../Assets/image10.png";
import { Typography, Box, Stack, IconButton } from "@mui/material";
import { json, useNavigate } from "react-router-dom";
import { config } from "react-spring";
import { getCookie } from "../../Services/Cookies";
import axios from "axios";
import DrawerComp from "../Drawer/drawer";
import MainHeader from "../Header/MainHeader";
import downloadcld from "../../Assets/download-cloud-02.png";
import uploadcld from "../../Assets/Icon.png";
import "./excel.css";

export default function PacsForm() {
  const [isStateSelected, setIsStateSelected] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("excel");
  const [stateData, setStateData] = useState();
  const [districtData, setDistrictData] = useState();
  const [district, setDistrict] = useState();
  const [state, setState] = useState();
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );
  const apiUrl =
    "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/";
  const [fileNameLabel, setFileNameLabel] = useState(
    "Drag or drop Excel sheet here"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const [stateData2, setStateData2] = useState({});

  const handlenavigate = () => {
    navigate("/DailyProgressReport");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileNameLabel(file ? file.name : "Drag or drop Excel sheet here");
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/upload/receive",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      alert("File uploaded successfully!");
      setSelectedFile(null);
      setFileNameLabel("Drag or drop Excel sheet here");
    } catch (error) {
      console.error("File upload failed:", error);

    if (error.response && error.response.status === 401) {
      alert("Unauthorized to perform this action.");
    } else {
      alert("File upload failed. Please try again.");
    }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          email: getCookie("email2"), // Get the email from the cookie
          "access-token": getCookie("token"), // Get the token from the cookie
          Authorization: `Bearer ${getCookie("token")}`, // Use the token for Authorization header
        },
      };

      try {
        const response = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getSummary",
          {},
          config
        );
        const response2 = await axios.post(
          // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/fetchStates"
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/fetchStates",
          {},
          config
        );
        if (response.status === 200) {
          const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
      const decodedData = JSON.parse(atob(dataWithoutKeys));
          setStateData2(decodedData);
        }

        if (response2.status === 200) {
          const dataWithoutKeys = response2.data.replace(key1, '').replace(key2, '');
      const decodedData = JSON.parse(atob(dataWithoutKeys));
          const stateNames = decodedData.map(
            (state) => state.stateName
          );
          setStateData(stateNames);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // const handletable = async () => {
  //     try {
  //         const response = await axios.get(
  //             // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/fetchStates"
  //             "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getSummary"
  //         );
  //         console.log("hi:"+response);
  //     } catch (error) {
  //     console.log("state call failed");
  //     }
  // };

  // handletable();

  const handlePacDownload = async () => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + mm + yyyy;
    let fileName = formattedToday + ".xlsx";
    let contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!state) {
      alert("Please select a state");
    }
    if (state) {
      if (state && district) {
        try {
          const response = await axios.post(
            // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/file/download/excel_pacsData?stateOrUt="
            "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/excel_pacsData",
            { "stateOrUt": state,
            "nameOfDistrict": district,},
            {
              responseType: "blob",
              headers: {
                email: getCookie("email2"),
                "access-token": getCookie("token"),
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          const contentDisposition = response.headers["content-disposition"];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }

          const blob = new Blob([response.data], { type: contentType });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            alert("The selected state has no data available");
          } else {
            alert("Call failed");
          }
        }
      } else {
        try {
          const response = await axios.post(
            // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/file/download/excel_pacsData?stateOrUt="
            "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/excel_pacsData",
            { "stateOrUt": state},
            {
              responseType: "blob",
              headers: {
                email: getCookie("email2"),
                "access-token": getCookie("token"),
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          const contentDisposition = response.headers["content-disposition"];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
          const blob = new Blob([response.data], { type: contentType });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            alert("The selected state has no data available");
          } else {
            alert("Call failed lolol" + error);
          }
        }
      }
    }
  };

  const [meow, setMeow] = useState("");

  const handleDistrict = async (e) => {
    setMeow("");
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    setState(e);
    try {
      const response = await axios.post(
        // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/pacs/getAllDistricts?state="+e
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/pacs/getAllDistricts",{
          "stateName":e,
        },
        config
      );
      const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
      const decodedData = JSON.parse(atob(dataWithoutKeys));
      console.log("ok"+response);
      setDistrictData(decodedData);
      // setDistrictData(response.data);
    } catch (error) {
      alert("Call failed a" +error);
    }
  };

  const districtSet = async (e) => {
    setMeow("meow");
    setDistrict(e);
  };

  const handleDownload = () => {
    const config = {
      headers: {
        email: getCookie("email2"), // Get the email from the cookie
        "access-token": getCookie("token"), // Get the token from the cookie
        Authorization: `Bearer ${getCookie("token")}`, // Use the token for Authorization header
      },
    };
    // get todays date
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + mm + yyyy;
    if (selectedFileType === "excel") {
      downloadFile(
        "excel_summaryProgress",
        "SummaryOfProgress_" + formattedToday + ".xlsx"
      );
    } else if (selectedFileType === "pdf") {
      downloadFile(
        "pdf_summaryProgress",
        "SummaryOfProgress_" + formattedToday + ".pdf"
      );
    }
  };

  const downloadFile = async (fileType, fileName) => {
    try {
      const response = await axios.post(
        `${apiUrl}${fileType}`,
        {},
        {
          responseType: "blob",
          headers: {
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      let contentType;
      if (selectedFileType === "excel") {
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (selectedFileType === "pdf") {
        contentType = "application/pdf";
      }
      const blob = new Blob([response.data], { type: contentType });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  console.log(stateData);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          marginLeft: "0%",
          marginTop: "1%",
          marginRight: "1%",
          backgroundColor: "#FFF",
          filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
          position: "relative",
          alignItems: "flex-start",
          marginBottom: "2%",
          overflowX: "hidden",
          borderRadius: "6px",
          width: { lg: "79vw", md: "auto", xs: "77vw" },
          height: { lg: "auto", md: "auto", xs: "72vh" },
          paddingRight: "1%",
        }}
      >
        <Stack
          direction="column"
          className="downloader"
          sx={{ marginLeft: "1%" }}
        >
          <Typography
            sx={{
              fontSize: { md: "24px", lg: "24px", xs: "20px" },
              marginLeft: "1%",
              color: "#00261C",
              fontWeight: "600",
              marginTop: "2%",
              fontFamily: "Poppins",
            }}
          >
            Download PACS form
          </Typography>
          <Stack
            direction={{ lg: "row", md: "column", xs: "column" }}
            spacing={2}
            sx={{
              marginTop: { md: "4%", xs: "6%" },
              marginLeft: "1%",
              display: "flex",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              height: "34px",
              position: "relative",
              padding: "10px",
            }}
          >
            <Stack direction={{ lg: "row", md: "column", xs: "column" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "100px",
                  marginTop: { lg: "-2%", md: "1%", xs: "0%" },
                  paddingRight: "1%",
                  fontFamily: "Poppins",
                }}
              >
                Select State
              </Typography>
              <Stack
                sx={{
                  marginTop: { xs: "6%", md: "5%", lg: "-1%" },
                  width: { md: "300px", xs: "240px" },
                }}
              >
                <select
                  id="select"
                  style={{
                    marginTop: "-4%",
                    width: "100%",
                    height: "38px",
                    top: "205px",
                    left: "461px",
                    padding: "9px 14px 9px 17px",
                    borderRadius: "7.69px",
                    border: "0.96px solid #D0D5DD",
                    backgroundImage: `url(${down})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "97%",
                    color: "grey",
                    appearance: "none",
                    gap: "7.69px",
                    fontFamily: "Poppins",
                  }}
                  onChange={(e) => {handleDistrict(e.target.value)
                    setIsStateSelected(true);}}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontFamily: "Poppins" }}
                  >
                    Select State
                  </option>
                  {stateData &&
                    stateData.map((state, index) => (
                      <option
                        key={index}
                        value={state}
                        style={{ fontFamily: "Poppins" }}
                      >
                        {state}
                      </option>
                    ))}
                </select>
              </Stack>
            </Stack>
            <Stack direction={{ lg: "row", md: "column", xs: "column" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "110px",
                  marginTop: { lg: "-2%", md: "-1%", xs: "0%" },
                  fontFamily: "Poppins",
                }}
              >
                Select District
              </Typography>
              <Stack
                sx={{
                  marginTop: { xs: "6%", md: "5%", lg: "-1%" },
                  width: { md: "300px", xs: "240px" },
                }}
              >
                <select
                  id="select"
                  disabled={!isStateSelected}
                  style={{
                    marginTop: "-4%",
                    width: "100%",
                    height: "38px",
                    top: "205px",
                    left: "461px",
                    padding: "9px 14px 9px 17px",
                    borderRadius: "7.69px",
                    border: "0.96px solid #D0D5DD",
                    backgroundImage: `url(${down})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "97%",
                    color: "grey",
                    appearance: "none",
                    gap: "7.69px",
                    fontFamily: "Poppins",
                  }}
                  onChange={(e) => districtSet(e.target.value)}
                  value={meow !== "" ? district : ""}
                >
                  <option
                    value=""
                    disabled
                    selected
                    style={{ fontFamily: "Poppins" }}
                  >
                    Select District
                  </option>
                  {districtData &&
                    districtData.map((state, index) => (
                      <option
                        key={index}
                        value={state}
                        style={{ fontFamily: "Poppins" }}
                      >
                        {state}
                      </option>
                    ))}
                </select>
              </Stack>
            </Stack>
            <Stack
              sx={{
                marginTop: { md: "8% !important", lg: "-1.6% !important" },
                width: { md: "124.7px", xs: "60%" },
              }}
            >
              <button
                style={{
                  width: "100%",
                  height: "39.23px",
                  padding: "9.62px 15.39px 9.62px 20%",
                  borderRadius: "7.69px",
                  border: "0.96px solid #D0D5DD",
                  background: "#065036",
                  color: "white",
                  marginLeft: "1.6%",
                  marginTop: "-1.4%",
                  marginBottom: "5%",
                  cursor: "pointer",
                  backgroundImage: `url(${downloadcld})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "6% center",
                  transition: "transform 0.3s ease, background-color 0.3s ease",
                  gap: "7.69px",
                  fontFamily: "Poppins",
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = "scale(0.95)";
                  e.target.style.backgroundColor = "#333";
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.backgroundColor = "#065036";
                }}
                onClick={handlePacDownload}
              >
                Download
              </button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          className="line-below-1"
          sx={{
            marginTop: { md: "25%", lg: "5%", xs: "64%" },
            paddingbottom: { md: "10%", xs: "10%" },
            width: "110% !important",
          }}
        />

        <Stack
          className="Uploader"
          sx={{ marginTop: "0%", marginLeft: "1%", paddingBottom: "6%" }}
        >
          <Typography
            sx={{
              fontSize: { md: "24px", xs: "20px" },
              marginLeft: "1%",
              color: "#00261C",
              fontWeight: "600",
              marginTop: { md: "2%", xs: "4%" },
              fontFamily: "Poppins",
            }}
          >
            Upload PACS form
          </Typography>
          <Stack
            direction={{ lg: "row", md: "column", xs: "column" }}
            spacing={2}
            className="rectangle-text-1"
            style={{ marginTop: "5%", marginLeft: "1%" }}
          >
            <Typography
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                width: "120px",
                marginLeft: "-1%",
                marginTop: "-2%",
                paddingRight: "1%",
                fontFamily: "Poppins",
              }}
            >
              Upload here
            </Typography>
            <Stack
              sx={{
                width: { md: "300px", xs: "240px" },
                marginLeft: { md: "-4%", xs: "4% !important" },
              }}
            >
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                style={{
                  width: "182px",
                  height: "45px",
                  flexShrink: 0,
                  borderRadius: "5px",
                  border: "1px dashed rgba(0, 0, 0, 0.46)",
                  display: "none",
                }}
                id="fileInput"
              />
              <label
                htmlFor="fileInput"
                className="upload-button"
                style={{
                  width: '100%', 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: "#A4A4A4",
                  fontSize: "14px",
                  fontWeight: "100",
                  display: "inline-block",
                  cursor: "pointer",
                  textAlign: "center",
                  marginLeft: "-4%",
                  marginTop: "-2%",
                  paddingLeft: "0px",
                  paddingTop: "-0.5%",
                  width: "100%",
                  height: "38px",
                  flexShrink: 0,
                  borderRadius: "7.69px",
                  border: "0.96px solid #D0D5DD",
                  lineHeight: "38px",
                  fontFamily: "Poppins",
                }}
              >
                {fileNameLabel}
              </label>
            </Stack>
            <Stack
              sx={{
                marginTop: {
                  lg: "0% !important",
                  md: "8% !important",
                  xs: "12% !important",
                },
                width: { md: "124.7px", xs: "60%" },
              }}
            >
              <button
                style={{
                  width: "100%",
                  height: "39.23px",
                  padding: "9.62px 15.39px 9.62px 14%",
                  borderRadius: "7.69px",
                  background: "#065036",
                  color: "white",
                  marginLeft: "2.4%",
                  marginTop: "-5%",
                  marginBottom: "5%",
                  cursor: "pointer",
                  border: "0.96px solid #D0D5DD",
                  backgroundImage: `url(${uploadcld})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "12% center",
                  transition: "transform 0.3s ease, background-color 0.3s ease",
                  gap: "7.69px",
                  fontFamily: "Poppins",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#333";
                  e.target.style.transform = "scale(0.95)";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "#065036";
                  e.target.style.transform = "scale(1)";
                }}
                onClick={(e) => {
                  e.target.style.transform = "scale(0.95)";
                  handleFileUpload();
                  setTimeout(() => {
                    e.target.style.transform = "scale(1)";
                  }, 100);
                }}
              >
                Upload
              </button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          marginLeft: "0%",
          marginTop: "1%",
          marginRight: "1%",
          backgroundColor: "#FFF",
          borderRadius: "0.2%",
          filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
          position: "relative",
          alignItems: "flex-start",
          marginBottom: "2%",
          overflowX: "hidden",
          width: { md: "79vw", xs: "77vw" },
        }}
      >
        <Stack className="Summary-table">
          <Typography
            sx={{
              fontSize: { md: "24px", xs: "20px" },
              marginLeft: { md: "2%", xs: "2%" },
              color: "#00261C",
              fontWeight: "600",
              marginTop: "2%",
              fontFamily: "Poppins",
            }}
          >
            Summary of progress
          </Typography>
          <Stack direction={{ lg: "row", md: "column", xs: "column" }}>
            <Stack
              direction={{ lg: "row", md: "column", xs: "column" }}
              spacing={2}
              sx={{
                marginLeft: { lg: "65%", md: "3%", xs: "5%" },
                marginTop: { lg: "-2%", md: "1%", xs: "-1%" },
                paddingRight: "1%",
              }}
            >
              <Stack direction="row">
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "19px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    marginTop: {
                      lg: "5.2% !important",
                      md: "4% !important",
                      xs: "4% !important",
                    },
                    width: "80px",
                    fontFamily: "Poppins",
                  }}
                >
                  Export to:
                </Typography>
                <Stack
                  sx={{
                    marginLeft: {
                      xs: "1% !important",
                      md: "-10.3% !important",
                      lg: "-1% !important",
                    },
                    marginTop: { xs: "3%", md: "8%", lg: "0%" },
                  }}
                >
                  <select
                    className="dropdown-m10k"
                    value={selectedFileType}
                    style={{ fontFamily: "Poppins" }}
                    onChange={(e) => setSelectedFileType(e.target.value)}
                  >
                    <option value="excel" style={{ fontFamily: "Poppins" }}>
                      Excel
                    </option>
                    <option value="pdf" style={{ fontFamily: "Poppins" }}>
                      PDF
                    </option>
                  </select>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  marginLeft: {
                    lg: "1% !important",
                    md: "-0.6% !important",
                    xs: "-0.6% !important",
                  },
                  width: { md: "115.77px", xs: "50%" },
                }}
              >
                <button
                  style={{
                    width: "100%",
                    height: "39.23px",
                    padding: "9.62px 15.39px 9.62px 16%",
                    borderRadius: "7.69px",
                    background: "#065036",
                    color: "white",
                    marginLeft: "2.4%",
                    marginTop: "-1%",
                    marginBottom: "5%",
                    cursor: "pointer",
                    border: "0.96px solid #D0D5DD",
                    backgroundImage: `url(${downloadcld})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "8% center",
                    transition:
                      "transform 0.3s ease, background-color 0.3s ease",
                    gap: "7.69px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#333";
                    e.target.style.transform = "scale(0.95)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#065036";
                    e.target.style.transform = "scale(1)";
                  }}
                  onClick={(e) => {
                    e.target.style.transform = "scale(0.95)";
                    handleDownload();
                    setTimeout(() => {
                      e.target.style.transform = "scale(1)";
                    }, 100);
                  }}
                  disabled={!selectedFileType}
                >
                  Download
                </button>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            className="line-below-1"
            style={{ marginTop: "2%", width: "120% !important" }}
          />
          <Stack
            direction="row"
            sx={{
              marginTop: "2%",
              fontFamily: "Poppins",
              marginLeft: "1%",
              marginRight: "1%",
              width: { lg: "auto", md: "70vw", xs: "75vw" },
            }}
          >
            <div className="table-container" style={{ height: "60vh" }}>
              <table>
                <thead>
                  <tr >
                    <th className="thead1 sticky-first-column fixed-width"></th>
                    <th className="thead1 sticky-second-column fixed-width"></th>
                    <th className="thead1 dct-target">A</th>
                    <th className="thead1 dct-target1">B</th>
                    <th className="thead1 dct-target1">C</th>
                    <th className="thead1 dct-target1">D</th>
                    <th className="thead1 dct-target1">E</th>
                    <th className="thead1 pre-mig1">F</th>
                    <th className="thead1 pre-mig1">G</th>
                    <th className="thead1 erp-trial1">H</th>
                  </tr>
                  <tr>
                    <th className="thead1 sticky-first-column fixed-width">
                      SL:NO
                    </th>
                    <th className="thead1 sticky-second-column ">State</th>
                    <th className="thead1 dct-target-1">Sanctioned PACS</th>
                    <th className="thead1 dct-target-2">ERP Onboarded</th>
                    <th className="thead1 dct-target-2">Hardware Delivered</th>
                    <th className="thead1 dct-target-2">Hardware installed</th>
                    <th className="thead1 dct-target-2">
                      PACS wise data for ERP
                    </th>
                    <th className="thead1 pre-mig-2">
                      PACS wise data for H/W Delivery
                    </th>
                    <th className="thead1 pre-mig-2">
                      PACS wise data for H/W Installation
                    </th>
                    <th className="thead1 erp-trial-2">
                      PACS wise data for SI
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(stateData2).map(([state, data], index) => (
                    <tr key={index}>
                      <td className="tdata1 sticky-first-column fixed-width">
                        {index + 1}
                      </td>
                      <td
                        className="tdata1 sticky-second-column fixed-width"
                        style={{ textAlign: "left" }}
                      >
                        {state}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.sanctionedPacs}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.erpTrialRun}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.hardwareDelivered}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.hardwareInstalled}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.pacsWiseDataForErp}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.pacsWiseDataForHardwareDelivery}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.pacsWiseDataForHardwareInstallation}
                      </td>
                      <td className="tdata1" style={{ textAlign: "left" }}>
                        {data.pacsWiseDataForSi}
                      </td>
                    </tr>
                  ))}
                  <tr>
          <td className="tdata1 sticky-first-column fixed-width"></td>
          <td className="tdata1 sticky-second-column fixed-width">
            Total
          </td>
          <td className="tdata1">
            {/* Calculate and display total for each column */}
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.sanctionedPacs, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.erpTrialRun, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.hardwareDelivered, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.hardwareInstalled, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.pacsWiseDataForErp, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.pacsWiseDataForHardwareDelivery, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.pacsWiseDataForHardwareInstallation, 0)}
          </td>
          <td className="tdata1">
            {Object.values(stateData2).reduce((acc, curr) => acc + curr.pacsWiseDataForSi, 0)}
          </td>
        </tr>
                </tbody>
              </table>
            </div>
          </Stack>
          <Stack
            sx={{
              paddingTop: "4%",
              marginLeft: {
                xs: "10% !important",
                md: "13.2% !important",
                lg: "75% !important",
              },
              width: { lg: "196.77px", md: "80%", xs: "80%" },
              paddingBottom: { md: "5%", xs: "5%" },
            }}
          >
            <button
              style={{
                width: "100%",
                height: "39.23px",
                padding: "9.62px 15.39px",
                borderRadius: "7.69px",
                background: "#065036",
                color: "white",
                cursor: "pointer",
                border: "0.96px solid #D0D5DD",
                fontFamily: "Poppins",
              }}
              onClick={handlenavigate}
            >
              Daily progress report
            </button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
