import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  Divider,
  Button,
  Modal,
  Grid,
  Tooltip,
  tooltipClasses,
  Popover,
  MenuItem,
} from "@mui/material";
import "./homepage.css";
import Icon from "../../Assets/nabard_icon_home.png";
import Main1 from "../HomepageNew/Homeone";
import First from "../../Assets/c_coops.svg";
import Second from "../../Assets/c_moc.svg";
import Third from "../../Assets/c_g2.svg";
import Fourth from "../../Assets/c_last.svg";
import FooterDiv from "../HomepageNew/Footer";
import TopDiv from "../HomepageNew/TopStates";
import Home2 from "../HomepageNew/HomeTwo";
import HomeThree from "../HomepageNew/HomeThree";
import { getCookie, setCookie } from "../../Services/Cookies";
import { AutoLogout } from "../../Services/AutoLogout";
import axios from "axios";
import { useAuth } from "../../Services/Auth";
import { useNavigate } from "react-router-dom";
import atteandanceI from "../../Assets/attendance.svg";
import logouttwo from "../../Assets/logouttwo.svg";
import close from "../../Assets/close-white.svg";
import emailI from "../../Assets/emailI.svg";
import eyeI from "../../Assets/eye.svg";
import info from "../../Assets/whiteInfo.svg";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "./captcha";
import styled from "@emotion/styled";
import leafone from "../../Assets/leafone.svg";
import leaftwo from "../../Assets/leaftwo.svg";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import eyevisible from "../../Assets/eyevisible.svg";
import Drawer from "@mui/material/Drawer";
import "./signin.css";

const MainHome = () => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "white",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "#065036",
    },
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isValidPass, setIsValidPass] = useState(true);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );
  const navigate = useNavigate();
  const {
    setAccessToken,
    ok,
    setOk,
    nav,
    setNav,
  } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(newEmail);
    setIsValid(isValidEmail);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    const isValidPassword = passwordPattern.test(newPassword);
    setIsValidPass(isValidPassword);
  };
  useEffect(() => {
    if (isModalOpen) {
      const timerId = setTimeout(() => {
        loadCaptchaEnginge(6);
      }, 100);

      return () => clearTimeout(timerId);
    }
  }, [isModalOpen]);

  const avatarStyle = {
    width: "50px",
    height: "45px",
    borderRadius: "6px",
    backgroundColor: "#065036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color:"white",
    fontFamily: "Poppins",
    fontWeight:"600",
  };

  const initials = getCookie("userName")
    .split(' ')
    .map(name => name[0])
    .join('');

  const handleLogin = () => {
    const user_captcha = document.getElementById("user_captcha_input").value;
    if (email === "") {
      alert("Please enter email id");
      return;
    }
    if (password === "") {
      alert("Please enter password");
      return;
    }
    if (user_captcha === "") {
      alert("Please enter captcha");
      return;
    }
    if (!validateCaptcha(user_captcha)) {
      alert("Please enter the correct captcha.");
      return; 
    }
    const encodedEmail = btoa(email);
    const encodedPassword = btoa(password);

    const finalEmail = key1 + encodedEmail + key2;
    const finalPassword = key1 + encodedPassword + key2;
    if (isValid && isValidPass) {
      axios
        .post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/authenticate/login",
          {
            email: finalEmail,
            password: finalPassword,
          }
        )
        .then((response) => {
          const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
          const decodedData = JSON.parse(atob(dataWithoutKeys));
          const { jwtToken, email, userName, loginStatus, userRole, state, resourceId } =
          decodedData;
            console.log(userName);
          setAccessToken(jwtToken);
          setCookie("email2", finalEmail);
          setCookie("token", jwtToken);
          setCookie("userName", userName);
          setCookie("loginStatus", loginStatus);
          setCookie("userRole", userRole);
          setCookie("state", state);
          setCookie("email", email);
          setCookie("resourceId", resourceId);
          if (response.status === 200) {
            if (nav !== "") {
              navigate(`/${nav}`);
              setNav("");
            } else {
              navigate("/dashboard");
            }
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("Login failed:", error);
          if (error.response.status === 400) {
            alert(error.response.data.errorMessage);
            loadCaptchaEnginge(6);
          } else if (error.response.status === 404) {
            alert(
              `${error.response.data.errorMessage}, Please enter valid Email Id.`
            );
            loadCaptchaEnginge(6);
          }
        });
    } else {
      return;
    }
  };


useEffect(()=>{
console.log("hi:"+getCookie("token"));
},[1500])

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmail("");
    setPassword("");
    setIsValid(true);
    setIsValidPass(true);
  };

  useEffect(() => {
    if (ok) {
      handleOpenModal();
      setOk(!ok);
    }
  }, [ok]);

  const handleDashboardClick = () => {
    if (getCookie("token")) {
      navigate("/dashboard");
    } else {
      handleOpenModal();
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false); // State to manage tooltip visibility

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen); // Toggle tooltip visibility
  };

  return (
    //check
    <div className="mainDiv">
      <style>
        {`
            .custom-input::placeholder {
              color: #81a799; 
            }
            .custom-input:-webkit-autofill {
              -webkit-box-shadow: 0 0 0 30px #38735e inset !important; /* Add !important to override browser defaults */
              -webkit-text-fill-color: #FFFFFF !important;
              caret-color: #FFFFFF !important;
            }
            
          `}
      </style>
      <Stack
        sx={{
          paddingLeft: { xs: "10px", md: "10px" },
          paddingRight: { xs: "30px", md: "50px" },
          paddingTop: "10px",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" spacing={{ xs: 0, md: 2 }}>
          <IconButton
            color="#065036"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "inline-flex", md: "none" }, padding: "0px" }}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction="row"
            spacing={{ xs: 0, md: 2 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "rgba(40, 100, 53, 1)",
                  height: { md: "50px", xs: "2.5rem" },
                  alignSelf: "auto",
                  display: { md: "block", xs: "none" },
                }}
              />
            }
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              sx={{ paddingLeft: { xs: "5px", md: "30px" } }}
            >
              <img
                src={Icon}
                alt=""
                style={{ width: "50px", height: "55px" }}
                className="img2H"
              />
              <Typography
                sx={{
                  color: "rgba(40, 100, 53, 1)",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                  fontSize: { xs: "0.8rem", md: "16px" },
                }}
              >
                NABARD
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: "rgba(40, 100, 53, 1)",
                width: { xs: "162px", md: "210px" },
                fontWeight: "500",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: { xs: "10px", md: "14px" },
                lineHeight: "1.2",
                display: { md: "block", xs: "none" },
              }}
            >
              NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT
            </Typography>
          </Stack>
        </Stack>

        {getCookie("token") ? (
          <>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  sx: {
                    // backgroundColor: '#065036',
                    borderRadius: "6px",
                  },
                }}
              >
                {/* <MenuItem
                  onClick={handlePopoverClose}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    paddingBottom: { xs: "0px", md: "6px" },
                    minHeight: { xs: "30px" },
                    // color:"white"
                  }}
                >
                  <img
                    alt=""
                    src={profileC}
                    style={{ paddingRight: "10px" }}
                  ></img>
                  Complete Your Profile
                </MenuItem> */}
                <MenuItem
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    minHeight: { xs: "30px" },
                  }}
                  onClick={() => {
                    navigate("/attendance");
                    setAnchorEl(null);
                  }}
                >
                  <img
                    alt=""
                    src={atteandanceI}
                    style={{ paddingRight: "10px" }}
                  ></img>
                  Attendance
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    minHeight: { xs: "30px" },
                  }}
                  onClick={() => {
                    AutoLogout();
                    setAnchorEl(null);
                  }}
                >
                  <img
                    alt=""
                    src={logouttwo}
                    style={{ paddingRight: "10px" }}
                  ></img>
                  Logout
                </MenuItem>
              </Popover>

              <Typography
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {" "}
                Home{" "}
              </Typography>
              <Typography
                onClick={handleDashboardClick}
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontFamily: "Poppins",
                }}
              >
                Dashboard
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                className="profileImgStackH"
              >
                <div style={avatarStyle} onClick={handlePopoverOpen}>
                  {initials}
                </div>
                <Typography
                  noWrap
                  style={{
                    marginLeft: "10px",
                    color: "#00261C",
                    fontSize: "16px",
                  }}
                >
                  {getCookie("userName")}
                </Typography>
              </Stack>
            </Stack>
            <Tooltip title={getCookie("userName")}>
              <Stack
                direction="row"
                alignItems="center"
                className="profileImg2StackH"
                sx={{ display: "none" }}
              >
                 <div style={avatarStyle} onClick={handlePopoverOpen}>
                  {initials}
                </div>
              </Stack>
            </Tooltip>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              // sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontFamily: "Poppins",
                  display: { xs: "none", md: "flex" },
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {" "}
                Home{" "}
              </Typography>
              <Typography
                onClick={handleDashboardClick}
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontFamily: "Poppins",
                  display: { xs: "none", md: "flex" },
                }}
              >
                Dashboard
              </Typography>
              <Button
                variant="contained"
                sx={{
                  marginBottom: { xs: "10px !important", md: "0px !important" },
                  backgroundColor: "#065036",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#065036",
                  },
                }}
                onClick={handleOpenModal}
              >
                Login
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={closeDrawer}
        sx={{ display: { xs: "block", md: "none" } }}
        PaperProps={{
          sx: {
            backgroundColor: "#065036",
            color: "white",
            padding: "20px 0px 0px 20px",
          },
        }}
      >
        {/* Drawer Content */}
        <Stack
          sx={{
            paddingLeft: "10px",
            paddingRight: "20px",
            paddingTop: "10px",
          }}
        >
          <Stack direction="row" spacing={12}>
            <Typography
              onClick={() => {
                navigate("/");
                closeDrawer();
              }}
              sx={{ paddingBottom: "10px", cursor: "pointer" }}
            >
              Home
            </Typography>
            <Typography
              onClick={() => {
                closeDrawer();
              }}
              sx={{ paddingBottom: "10px", cursor: "pointer" }}
            >
              x
            </Typography>
          </Stack>
          <Typography
            onClick={handleDashboardClick}
            sx={{ paddingBottom: "10px", cursor: "pointer" }}
          >
            Dashboard
          </Typography>
        </Stack>
      </Drawer>
      <Main1 />
      <HomeThree />
      <Home2 />
      <div className="topCont">
        <TopDiv />
      </div>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "white",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          paddingTop: { xs: "10px", md: "0" },
          paddingBottom: { xs: "10px", md: "0" },
        }}
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 18 }}
        className="imageStackH"
      >
        <img src={First} className="img1" alt="" style={{ width: "200px" }} />
        <img src={Second} alt="" className="img1" style={{ width: "120px" }} />
        <img src={Third} alt="" className="img1" style={{ width: "130px" }} />
        <img src={Fourth} alt="" className="img1" style={{ width: "130px" }} />
      </Stack>
      <FooterDiv />

      {/* Modal for Login */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="login-modal"
        aria-describedby="login-modal-description"
      >
        <div className="modal-main-div">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "80%", md: "450px" },
              height: "500px",
              bgcolor: "#065036",
              boxShadow: "24px",
              paddingBottom: 2,
              borderRadius: "6px",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                paddingTop: 2,
                px: 3,
                py: 2,
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Login
              </Grid>
              <Grid item xs={2}>
                <img alt="icon" src={leafone}></img>
              </Grid>
              <Grid
                item
                xs={4}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <img
                  alt="close"
                  src={close}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleCloseModal}
                ></img>
              </Grid>
            </Grid>
            <Divider
              sx={{
                borderColor: "#1b5f48",
              }}
            />
            <Box
              sx={{
                p: 3,

                marginTop: "-10px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <label
                    style={{
                      color: "white",
                    }}
                  >
                    Email ID
                  </label>
                </Grid>
                <Grid item xs={2}>
                  <img
                    alt="icon"
                    src={leaftwo}
                    style={{ height: "23px" }}
                  ></img>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <input
                      className="custom-input"
                      id="email"
                      placeholder="Enter your Email here"
                      value={email}
                      onChange={handleEmailChange}
                      type="text"
                      style={{
                        backgroundColor: "#38735e",
                        width: "100%",
                        height: "50px",
                        borderRadius: "7.69px",
                        borderColor: "#38735e",
                        outline: "none",
                        border: `${!isValid ? "2px solid red" : "none"}`,
                        paddingRight: "40px",
                        padding: "10px",
                        color: "#FFFFFF",
                        marginTop: "-10px",
                      }}
                    />
                    <img
                      src={emailI}
                      alt="icon"
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "10px",
                      }}
                    />
                  </div>
                </Grid>
                {!isValid && (
                  <p
                    style={{
                      color: "#ff0000",
                      margin: "2px 0px 0px 10px",
                      fontSize: "12px",
                    }}
                  >
                    Invalid email format.
                  </p>
                )}
              </Grid>
              <Grid container spacing={1} mt={0.5}>
                <Grid item xs={12}>
                  <label
                    style={{
                      color: "white",
                    }}
                  >
                    Password
                  </label>
                  {!isValidPass && (
                    <BootstrapTooltip
                      open={tooltipOpen}
                      onClose={handleTooltipToggle}
                      title={
                        <span>
                          1) Password should contain 8 to 12 characters.
                          <br></br>
                          2) It should contain at least one number, uppercase
                          and one special character.
                        </span>
                      }
                      arrow
                    >
                      <Button
                        sx={{ padding: "0px", minWidth: "0px" }}
                        onClick={handleTooltipToggle}
                      >
                        <img
                          alt="info"
                          src={info}
                          style={{
                            marginLeft: "5px",
                          }}
                        ></img>
                      </Button>
                    </BootstrapTooltip>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                      marginTop: "-5px",
                    }}
                  >
                    <input
                      id="password"
                      className="custom-input"
                      placeholder="Enter your password here"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      style={{
                        backgroundColor: "#38735e",
                        width: "100%",
                        height: "50px",
                        borderRadius: "7.69px",
                        borderColor: "#38735e",
                        outline: "none",
                        border: `${!isValidPass ? "2px solid red" : "none"}`,
                        paddingRight: "40px",
                        padding: "10px",
                        color: "#FFFFFF",
                      }}
                    />
                    {!showPassword ? (
                      <img
                        src={eyeI}
                        alt="icon"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "10px",
                          height: "auto",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        onClick={handlePasswordVisibility}
                      />
                    ) : (
                      <img
                        src={eyevisible}
                        alt="icon"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "10px",
                          height: "auto",
                          cursor: "pointer",
                          width: "25px",
                        }}
                        onClick={handlePasswordVisibility}
                      />
                    )}
                  </div>
                </Grid>
                {!isValidPass && (
                  <p
                    style={{
                      color: "#ff0000",
                      margin: "2px 0px 0px 10px",
                      fontSize: "12px",
                    }}
                  >
                    Invalid Format.Please check info icon.
                  </p>
                )}
              </Grid>
              <Grid container spacing={1.5} marginTop="0.5px">
                <Grid item xs={12}>
                  <label
                    style={{
                      color: "white",
                    }}
                  >
                    Enter Captcha
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                      marginTop: "-10px",
                    }}
                  >
                    <input
                      className="custom-input"
                      placeholder="Enter your Captcha here"
                      id="user_captcha_input"
                      name="user_captcha_input"
                      style={{
                        backgroundColor: "#38735e",
                        width: "100%",
                        height: "50px",
                        borderRadius: "7.69px",
                        borderColor: "#38735e",
                        outline: "none",
                        border: "none",
                        paddingRight: "40px",
                        padding: "10px",
                        color: "#FFFFFF",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <div style={{ marginTop: "15px" }}>
                <LoadCanvasTemplate />
              </div>
              <Grid container mt={2}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "white",
                    color: "#065036",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default MainHome;
