import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import {
  Divider,
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const CentralAnChart = () => {
  const [apiResponse, setApiResponse] = useState([]);
  const [metric, setMetric] = React.useState("erpTrailRun");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    setMetric(event.target.value);
  };

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/centralAnalytics",
        {},
        config
      )
      .then((response) => {
        if (response?.status === 200) {
          setApiResponse(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [metric]);

  const data = {};

  for (const state in apiResponse) {
    for (const serviceInt in apiResponse[state]) {
      if (!data[serviceInt]) {
        data[serviceInt] = [];
      }
      data[serviceInt].push(apiResponse[state][serviceInt][metric]);
    }
  }

  const series = Object.keys(data).map((serviceInt) => ({
    name: serviceInt,
    data: data[serviceInt],
  }));

  const categories = Object.keys(apiResponse).map((state, index) => {
    if (state === "Andaman and Nicobar Islands") {
      return "Andaman Nicobar";
    } else {
      return state;
    }
  });

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Count of PACS",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 40,
    },
    colors: [
      "#FF5733",
      "#FFC300",
      "#C70039",
      "#900C3F",
      "#00A1E4",
      "#A543FF",
      "#00FF11",
      "#FF00B5",
      "#FFC0CB",
      "#0A0A0A",
      "#7F8C8D",
      "#26A65B",
    ],
  };
  return (
    <>
      <style>
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            .apexcharts-grid-borders{
                display: none !important;
            }
            .apexcharts-toolbar{
                display:none !important;
            }
            .apexcharts-xaxis-tick{
                display:none !important;
            }
            .apexcharts-legend-text{
                margin-left:5px !important;
            }
            `}
      </style>
      <div className="stackCont">
        <Stack direction="column" sx={{ padding: "10px" }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={{ xs: "left", md: "center" }}
            sx={{ width: "100%" }}
            spacing={2}
            justifyContent="space-between"
          >
            <Typography
              sx={{
                paddingLeft: "10px",
                fontSize: "20px",
                fontFamily: "poppins",
              }}
            >
              System Integrators status across India{" "}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ marginLeft: { xs: "10px !important", md: "0px" } }}
            >
              <Typography sx={{ paddingRight: "10px" }}> Sort by </Typography>
              <FormControl
                size="small"
                sx={{ minHeight: "20px", paddingRight: "5px" }}
              >
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={metric}
                  onChange={handleChange}
                  sx={{ borderRadius: "10px" }}
                >
                  <MenuItem value={"erpTrailRun"}>ERP</MenuItem>
                  <MenuItem value={"goLive"}>Go Live</MenuItem>
                  <MenuItem value={"dctCompleted"}>DCT Completed</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <div className="widthCheck">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={400}
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default CentralAnChart;
