import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./stacked.css";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import { AutoLogout } from "../../Services/AutoLogout";

const StackedBarChart = () => {
  const [apiRes, setApiRes] = useState([]);
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );
  

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/state-wise-audit-classification?category=PACS",
        {},
        config
      )
      .then((response) => {
        const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        if(response?.status === 200){
          setApiRes(decodedData);
        }
        else if (response?.response?.status === 403) {
          AutoLogout();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const categories = apiRes.map((item, index) =>
    index === 0 ? "Andaman Nicobar" : item.stateName
  );
  const seriesData = [
    {
      name: "A",
      data: apiRes.map((item) =>
        ((item.countA / item.grandTotal) * 100).toFixed(2)
      ),
      color: "#0D7DA8",
    },
    {
      name: "B",
      data: apiRes.map((item) =>
        ((item.countB / item.grandTotal) * 100).toFixed(2)
      ),
      color: "#5CC8BE",
    },
    {
      name: "C",
      data: apiRes.map((item) =>
        ((item.countC / item.grandTotal) * 100).toFixed(2)
      ),
      color: "#F5C520",
    },
    {
      name: "D",
      data: apiRes.map((item) =>
        ((item.countD / item.grandTotal) * 100).toFixed(2)
      ),
      color: "#357AF6",
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        trim: false,
      },
    },
    yaxis: {
      max: 100,
      labels: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    grid: {
      show: false,
    },
    colors: seriesData.map((series) => series.color),
  };

  return (
    <div className="stackCont">
      <Typography
        sx={{
          paddingLeft: "10px",
          paddingTop: "10px",
          fontSize: "20px",
          fontFamily: "Poppins",
        }}
      >
        Audit Classification
      </Typography>
      <div className="widthCheck">
        <ReactApexChart
          options={options}
          series={seriesData}
          type="bar"
          height={400}
        />
      </div>
    </div>
  );
};

export default StackedBarChart;
