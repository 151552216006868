import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { AutoLogout } from "../../Services/AutoLogout";

const DumbbellChart = () => {
  const [apiRes, setApiRes] = useState([]);
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );


  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/year-of-registration-vs-pacs-count",
        {},
        config
      )
      .then((response) => {
        const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        if(response?.status === 200){
          setApiRes(decodedData);
        }
        else if (response?.response?.status === 403) {
          AutoLogout();
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const yaxisValues = apiRes.map((i) => i.countOfPACSUniqueCode);
  const xaxisValues = apiRes.map((i) => i.yearOfRegistrationRange);

  const customTickValue = (val) => {
    if (val < 1000) {
      return 1000;
    }
    return val;
  };
  const Series = [
    {
      name: "Counts of PACS Unique Code",
      data: yaxisValues,
    },
  ];
  const maxValue = Math.max(...Series[0].data);

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      zoom: { enabled: false },
    },
    plotOptions: {
      bar: {
        isDumbbell: true,
        columnWidth: "7",
        endingShape: "round",
        horizontal: false,
      },
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    xaxis: {
      categories: xaxisValues,
      title: {
        text: "Year of Registration",
      },
    },
    yaxis: {
      min: maxValue > 2000 ? 0 : undefined,
      forceNiceScale: true,
      stepSize: 3000,
      title: {
        text: "Counts of PACS Unique Code",
      },
      max: Math.ceil(maxValue / 1000) * 1000,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: ["#065036"],
  };

  options.annotations = {
    points: Series[0].data.map((value, index) => ({
      x: options.xaxis.categories[index],
      y: value,
      marker: {
        size: 6,
        strokeWidth: 0,
        fill: {
          colors: ["#FFA41B"],
        },
      },
      offsetY: 20,
    })),
  };

  return (
    <>
      <style>
        {`
                .apexcharts-gridlines-horizontal{
                    display: none !important;
                }
              .apexcharts-gridline{
                display: none !important;
              }
                .apexcharts-toolbar{
                    display:none !important;
                }
                .apexcharts-xaxis-tick{
                    display:none !important;
                }
                .apexcharts-point-annotation-marker  {
                    fill : #065036 !important;
                }
                .apexcharts-yaxis-title-text  {
                    font-size: 10px !important;
                }
                .apexcharts-yaxis-label {
                    font-size: 10px !important;
                }
                .apexcharts-xaxis-label {
                    font-size: 10px !important;
                }
                `}
      </style>
      <div sx={{width:"100%", height:"100%"}}>
        <ReactApexChart
          options={options}
          series={Series}
          type="bar"
          height={350}
          width="100%"
        />
      </div>
    </>
  );
};

export default DumbbellChart;
