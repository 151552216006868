import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "../../Services/Auth";

const Graph2 = () => {
  const [data, setData] = useState([]);
  const [mChange, setMChange] = useState(0);
  const [dChange, setDChange] = useState(0);
  const [bChange, setBChange] = useState(0);
  const [mChangeArrow, setMChangeArrow] = useState("");
  const [dChangeArrow, setDChangeArrow] = useState("");
  const [bChangeArrow, setBChangeArrow] = useState("");
  const[check, setCheck]=useState(false);
  const { state, setState, district, setDistrict } = useAuth();
  const fetchData = async () => {
    try {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };

      let body = {};

      if (state && district) {
        body = {
          state: state,
          district: district,
        };
      } else if (state) {
        body = {
          state: state,
        };
      }

      const response = await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/yoy-members",
        body,
        config
      );

      if (response.status === 200) {
        setCheck(true);
        setData(response.data);

        if (response.data.length === 2) {
          const oldData = response.data[0];
          const newData = response.data[1];

          const mChangePercentage = (
            ((newData.totalMembers - oldData.totalMembers) /
              Math.abs(oldData.totalMembers)) *
            100
          ).toFixed(2);
          const dChangePercentage = (
            ((newData.totalDepositors - oldData.totalDepositors) /
              Math.abs(oldData.totalDepositors)) *
            100
          ).toFixed(2);
          const bChangePercentage = (
            ((newData.totalBorrowingMembers - oldData.totalBorrowingMembers) /
              Math.abs(oldData.totalBorrowingMembers)) *
            100
          ).toFixed(2);

          setMChange(mChangePercentage);
          setDChange(dChangePercentage);
          setBChange(bChangePercentage);

          const mChangeArrow =
            newData.totalMembers < oldData.totalMembers ? "↓" : "↑";
          const dChangeArrow =
            newData.totalDepositors < oldData.totalDepositors ? "↓" : "↑";
          const bChangeArrow =
            newData.totalBorrowingMembers < oldData.totalBorrowingMembers
              ? "↓"
              : "↑";

          setMChangeArrow(mChangeArrow);
          setDChangeArrow(dChangeArrow);
          setBChangeArrow(bChangeArrow);
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [state, district]);

  const series = [
    {
      name: "Total Members",
      data: [
        data[0]?.totalMembers || 0,
        data[1]?.totalMembers || 0,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: "Total Depositors",
      data: [
        null,
        null,
        null,
        data[0]?.totalDepositors || 0,
        data[1]?.totalDepositors || 0,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: "Total Borrowing Members",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        data[0]?.totalBorrowingMembers || 0,
        data[1]?.totalBorrowingMembers || 0,
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#57805c"],
    },
    colors: ["#57805c"],
    xaxis: {
      categories: ["FY22", "FY23", "", "FY22", "FY23", "", "FY22", "FY23"],
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Headcount in crores",
      },
      labels: {
        formatter: function (val) {
          if(check){
            return val/10000000+"Cr";
          }
           return val+" Cr";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    annotations: {
      text: [
        {
          x: "FY22",
          y: data[0]?.totalMembers || 0,
          text: "Total Members: " + (data[0]?.totalMembers || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.totalMembers || 0,
          text: "Total Members: " + (data[1]?.totalMembers || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY22",
          y: data[0]?.totalDepositors || 0,
          text: "Total Depositors: " + (data[0]?.totalDepositors || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.totalDepositors || 0,
          text: "Total Depositors: " + (data[1]?.totalDepositors || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY22",
          y: data[0]?.totalBorrowingMembers || 0,
          text:
            "Total Borrowing Members: " + (data[0]?.totalBorrowingMembers || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
        {
          x: "FY23",
          y: data[1]?.totalBorrowingMembers || 0,
          text:
            "Total Borrowing Members: " + (data[1]?.totalBorrowingMembers || 0),
          offsetY: 40,
          style: {
            fontSize: "12px",
          },
          xAnchor: "middle",
        },
      ],
    },
    legend: {
      show: false,
    },
  };

  return (
    <>
      <div className="widthCheck">
        <Stack position="relative">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={400}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "65%",
              left: { md: "15%", xs: "12%" },
              fontSize: "12px",
              fontWeight: "bold",
              color: mChange < 0 ? "red" : "#E47D05",
            }}
          >
            {mChangeArrow} {Math.abs(mChange)}%
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "65%",
              left: { md: "40%", xs: "44%" },
              fontSize: "12px",
              fontWeight: "bold",
              color: dChange < 0 ? "red" : "#E47D05",
              // backgroundColor:"#FFEDD7",
              // padding:"3px",
              // borderRadius:"4px"
            }}
          >
            {dChangeArrow}{Math.abs(dChange)}%
          </Typography>
          <Typography
            sx={{
              position: "absolute",
              top: "65%",
              left: { md: "74%", xs: "77%" },
              fontSize: "12px",
              fontWeight: "bold",
              color: bChange < 0 ? "red" : "#E47D05",
            }}
          >
            {bChangeArrow} {Math.abs(bChange)}%
          </Typography>
          <Typography
            position="absolute"
            sx={{
              fontSize: "10px",
              left: { md: "13%", xs: "11%" },
              bottom:"0"
            }}
          >
            Total no. of members
          </Typography>
          <Typography
            position="absolute"
            sx={{ fontSize: "10px", left: { md: "41%", xs: "43%" }, bottom:"0" }}
          >
            Total no. of depositors
          </Typography>
          <Typography
            position="absolute"
            sx={{ fontSize: "10px", left: { md: "67%", xs: "70%" }, bottom:"0" }}
          >
            Total no. of borrowing members
          </Typography>
        </Stack>
      </div>
    </>
  );
};

export default Graph2;
