import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DrawerComp from "../../Drawer/drawer";
import Header from "../../Header/MainHeader";
import Flipcard from "./28Dashboard";

export default function StateDashboard() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        overflowX: "hidden",
        fontFamily: "Poppins",
        overflowY: "hidden",
      }}
    >
      <style>
        {`
            .css-10hburv-MuiTypography-root{
                font-size:11px !important;
                font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            `}
      </style>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <DrawerComp listName={"28 States Involved"} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: { xs: 0, md: 3 }, backgroundColor: "#f4faf8" }}
        >
          <Toolbar />
          <Flipcard />
        </Box>
      </Box>
    </div>
  );
}
