import axios from "axios";
import { getCookie } from "./Cookies";
import Cookies from "js-cookie";

export const AutoLogout = () => {
  const config = {
    headers: {
      "access-token": getCookie("token"),
      email: getCookie("email2"),
      Authorization: `Bearer ${getCookie("token")}`,
      "Content-Type": "application/json",
    },
  };
  const requestData = {
    email: getCookie("email2"),
  };
  axios
    .post(
      "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/authenticate/logoff",
      requestData,
      config
    )
    .then((response) => {
      Cookies.remove("email2");
      Cookies.remove("token");
      Cookies.remove("userName");
      Cookies.remove("loginStatus");
      Cookies.remove("userRole");
      Cookies.remove("state");
      Cookies.remove("email");
      window.location = "/";
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      Cookies.remove("email2");
      Cookies.remove("token");
      Cookies.remove("userName");
      Cookies.remove("loginStatus");
      Cookies.remove("userRole");
      Cookies.remove("state");
      Cookies.remove("email");
      window.location = "/";
    });
};
