import React, { useEffect } from "react";
import { Typography, Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./homepage.css";
import FlippingNumber from "./Test";
import { getCookie } from "../../Services/Cookies";
import Counter from "./newnew";
import Counter1 from "./newnew1";
import Counter2 from "./newnew2";
import Counter3 from "./newnew3";
import flowersBg from "../../Assets/flowers_bg.png";
import leaf from "../../Assets/Vector1.png"
import leaf2 from "../../Assets/images1.png"
import Group3 from "../../Assets/Group3.png"
import bit1 from "../../Assets/Group33.png"
import bit2 from "../../Assets/Group44.png"
import bit3 from "../../Assets/Group55.png"
import bit4 from "../../Assets/Group66.png"
import bit5 from "../../Assets/Group77.png"
import bit6 from "../../Assets/Group88.png"
import bit7 from "../../Assets/Group99.png"


const HomeThree = () => {
  const nav = useNavigate();

  const [goLiveCount, setGoLiveCount] = useState();
  const [dctCompletedCount, setDctCompletedCount] = useState();
  const [erpTrailRunCount, setErpTrailRunCount] = useState();
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );

  useEffect(() => {
    axios.post("https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/aggregatedLatestDataForDEG")
    .then((response) => {
      const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
      const decodedData = JSON.parse(atob(dataWithoutKeys));
      console.log("Hello:"+JSON.stringify(decodedData));
      const { "Go Live Completed": goLiveCountVal, "DCT Completed": dctCompletedCountVal, "ERP Trail Run": erpTrailRunCountVal } = decodedData;
        setGoLiveCount(goLiveCountVal);
        setDctCompletedCount(dctCompletedCountVal);
        setErpTrailRunCount(erpTrailRunCountVal);
    })
    .catch((error) => {
      console.log('Error fetching data:', error);
    });
  }, []);

  // useEffect(()=>{
  //   if(goLiveCount)
  //   {
  //     setGoLiveCount( String(goLiveCount).padStart(5, '0'));
  //   }
  // },[goLiveCount])

  const arrayConverter = (e) => {
    if (e === null || e === undefined) {
      return null;
    }
    const didgitArray = e
      .toString()
      .padStart(5, "0")
      .split("")
      .map(Number);
    const minLength = 5;
    return didgitArray;
  };

  const a = arrayConverter(dctCompletedCount);
  const b = arrayConverter(erpTrailRunCount);
  const c = arrayConverter(goLiveCount);


  const FlippingNumberDelayed = ({ value, index }) => {
    const [displayValue, setDisplayValue] = useState(null);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setDisplayValue(value);
      }, index * 700); // Adjust the delay between each digit flip
  
      return () => clearTimeout(timer);
    }, [value, index]);
  
    return <FlippingNumber value={displayValue} />;
  };
  
  
  return (
    <div>
      <div
        style={{
          // position: "absolute",
          // top: 0,
          // left: 0,
          // // width: "100%",
          padding: "10px 10px 0px 10px",
          background: "#F2F2F2",
        }}
      >

        <Stack sx={{ width: {md:"100%",xs:"100%",sm:"100%"} }} direction="column" spacing={8}>
          
          <Stack direction={"row"} sx={{position:"relative"}} justifyContent="center">
            
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: { xs: "36px", sm: "48px", md: "54px" },
                fontFamily: "'Poppins', sans-serif",
                alignItems: "center",
                color: "#000000",
                textAlign: "center",
              }}
            >
              Progress
            </Typography>
            {/* <img
              src={flowersBg}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "20%",
                height: "auto",
                marginBottom: "-10px",
                marginRight: "40px",
                zIndex:1,
              }}
            /> */}
          </Stack>
          {/* stack number 2 */}
          <Stack
            direction={{lg:"row",md:"column",xs:"column",sm:"column"}}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            spacing={8}
            sx={{ width: {xs:"100%",sm:"100%",md:"100%"}, overflowX: "hidden" }}
            style={{ marginTop: "3%",paddingBottom:"2%" ,paddingTop:"2%"}}
            position="relative"
             >
         <Card
            sx={{
              width: { xs: "70%", sm: "350px"},
              height: { md: 204, xs: 180 },
              flexShrink: 0,
              backgroundColor: "transparent", 
              boxShadow: "0px 8px 24px rgba(255, 255, 255, 1), 0px 4px 12px rgba(0, 0, 0, 0.4)", 
              border: "1px solid rgba(210, 210, 210, 0.5)", 
              borderRadius: "6px", 
              backdropFilter: "blur(10px)", 
              WebkitBackdropFilter: "blur(10px)" 
            }}
          >
              <CardContent>
                <Typography
                  sx={{
                    color: "#065036",
                    textAlign: "center",
                    fontSize: { xs: "24px", sm: "25px", md: "30px" },
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "550",
                  }}
                >
                  DCT Completed
                </Typography>
              
                <Stack direction="row" sx={{ marginTop: "6%",justifyContent:"center",marginLeft:{xs:"-5%",md:"-3.4%"}}} spacing={1}>
                {dctCompletedCount ? (
                        <Box
                          sx={{
                            width: { xs: "53px", md: "58px" },
                            height: 86,
                            flexShrink: 0,
                            borderRadius: 1,
                            position: "relative",
                          }}
                        >
                          <Counter counterValue={dctCompletedCount} />
                        </Box>
                    ) : (
                      <Typography sx={{ color: "#FFF", fontSize: "18px" }}>
                        No data available
                      </Typography>
                    )}
                </Stack>
              </CardContent>
            </Card>
            <Card
            sx={{
              width: { xs: "70%", sm: "350px" },
              height: { md: 204, xs: 180 },
              flexShrink: 0,
              backgroundColor: "transparent", 
              boxShadow: "0px 8px 24px rgba(255, 255, 255, 1), 0px 4px 12px rgba(0, 0, 0, 0.4)", 
              border: "1px solid rgba(210, 210, 210, 0.5)", 
              borderRadius: "6px", 
              backdropFilter: "blur(10px)", 
              WebkitBackdropFilter: "blur(10px)", 
              justifyContent:"center"
            }}
          >
              <CardContent>
                <Typography
                  sx={{
                    color: "#065036",
                    textAlign: "center",
                    fontSize: { xs: "24px", sm: "25px", md: "30px" },
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "550",
                  }}
                >
                  ERP Onboarded
                </Typography>
                <Stack direction="row" sx={{ marginTop: "6%",justifyContent:"center",marginLeft:{xs:"-5%",md:"-3%"}}} spacing={1}>
                {erpTrailRunCount ? (
                        <Box
                          sx={{
                            width: { xs: "53px", md: "58px" },
                            height: 86,
                            flexShrink: 0,
                            borderRadius: 1,
                            position: "relative",
                          }}
                        >
                          <Counter1 counterValue={erpTrailRunCount} />
                        </Box>
                    ) : (
                      <Typography sx={{ color: "#FFF", fontSize: "18px" }}>
                        No data available
                      </Typography>
                    )}
                </Stack>
              </CardContent>
            </Card>
            <Card
            sx={{
              width: { xs: "70%", sm: "350px" },
              height: { md: 204, xs: 180 },
              flexShrink: 0,
              backgroundColor: "transparent", 
              boxShadow: "0px 8px 24px rgba(255, 255, 255, 1), 0px 4px 12px rgba(0, 0, 0, 0.4)", 
              border: "1px solid rgba(210, 210, 210, 0.5)", 
              borderRadius: "6px", 
              backdropFilter: "blur(10px)", 
              WebkitBackdropFilter: "blur(10px)", 
              zIndex:2,
            }}
          >
              <CardContent>
                <Typography
                  sx={{
                    color: "#065036",
                    textAlign: "center",
                    fontSize: { xs: "24px", sm: "25px", md: "30px" },
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "550",
                  }}
                >
                  Go-Live
                </Typography>
                <Stack direction="row" sx={{ marginTop: "6%",marginLeft:{xs:"-5%",md:"-2%"}}} spacing={1} justifyContent="center">
                {goLiveCount? (
                        <Box
                          sx={{
                            width: { xs: "53px", md: "58px" },
                            height: 86,
                            flexShrink: 0,
                            borderRadius: 1,
                            position: "relative",
                          }}
                        >
                          <Stack direction="row" justifyContent="center">
                          <Stack sx={{marginRight:"20%"}}>
                          <Counter3 counterValue={"0"}/>
                          </Stack>
                          <Stack sx={{marginLeft:"10%"}}>
                          <Counter2 counterValue={goLiveCount} />
                          </Stack>
                          </Stack>
                        </Box>
                    ) : (
                      <Typography sx={{ color: "#FFF", fontSize: "18px" }}>
                        No data available
                      </Typography>
                    )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Stack position="relative">
          {/* <img
              src={leaf2}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                width: "10%",
                height:"500px",
                marginBottom: "0px",
                marginLeft:"0%",
                zIndex:-1,
              }}
            /> */}
            <img
              src={Group3}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "10%",
                height: "auto",
                marginBottom: "0px",
                marginRight: "6%",
                zIndex:1,
              }}
            />
              {/* <img
              src={bit2}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "2%",
                height: "25px",
                marginBottom: "310px",
                marginRight: "60%",
                zIndex:1,
              }}
            /> */}
            <img
              src={bit2}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "2%",
                height: "25px",
                marginBottom: "310px",
                marginRight: "20%",
                zIndex:1,
              }}
            />
              {/* <img
              src={bit3}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1%",
                height: "10px",
                marginBottom: "400px",
                marginRight: "70%",
                zIndex:1,
              }}
            />
            <img
              src={bit4}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1%",
                height: "10px",
                marginBottom: "400px",
                marginRight: "38%",
                zIndex:1,
              }}
            />
             <img
              src={bit5}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1%",
                height: "10px",
                marginBottom: "380px",
                marginRight: "34.5%",
                zIndex:1,
              }}
            />
           <img
              src={bit3}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1%",
                height: "10px",
                marginBottom: "410px",
                marginRight: "26%",
                zIndex:1,
              }}
            />
            <img
              src={bit1}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "1%",
                height: "10px",
                marginBottom: "350px",
                marginRight: "80%",
                zIndex:1,
              }}
            />*/}
          <img
              src={leaf}
              alt="Background Flower"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "10%",
                height: "auto",
                marginBottom: "0px",
                marginRight: "0%",
                zIndex:1,
              }}
            /> 
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default HomeThree;


