import React, { useState } from 'react';
import axios from 'axios';
import "./si.css";
import { Stack ,Typography,Button} from '@mui/material';
import { getCookie } from '../../Services/Cookies';
import downloadcld from "../../Assets/download-cloud-02.png";

const DownloadComponent = () => {
  const config = {
    headers: {

      'email': getCookie('email2'), 
      'access-token': getCookie('token'), 
      'Authorization': `Bearer ${getCookie('token')}` 
    }
  };
  const [selectedFileType, setSelectedFileType] = useState('excel');
  // const apiUrl = 'http://nabard-backend.eastus.cloudapp.azure.com:3000/api/v1/nabard/file/download/';
  const apiUrl = 'https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/';
  // const apiUrl = process.env.REACT_APP_BACKEND_URI+"api/v1/nabard/file/download/";


  const handleDownload = () => {
    // get todays date
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + mm + yyyy;
    if (selectedFileType === 'excel') {
      downloadFile('excel_mission10000Form', 'ProgressReport_'+formattedToday+'.xlsx');
    } else if (selectedFileType === 'pdf') {
      downloadFile('pdf_mission10000Form', 'ProgressReport_'+formattedToday+'.pdf');
    }
  };

  const downloadFile = async (fileType, fileName) => {
    try {
      const response = await axios.post(`${apiUrl}${fileType}`,{},{
        responseType: 'blob', 
        headers:{
          'email': getCookie('email2'), 
          'access-token': getCookie('token'), 
          'Authorization': `Bearer ${getCookie('token')}`
        }
      });

    let contentType;
    // if (fileType ==='pdf'){
    //   contentType = 'application/pdf';
    // } else if (fileType === 'excel'){
    //   contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // } else {
    //   console.log('Unsupported file type')
    //   return
    // }



      if (selectedFileType === 'excel') {
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      } else if (selectedFileType === 'pdf') {
        contentType = 'application/pdf';
      }
      // Create a blob from the response
      // const blob = new Blob([response.data], { type: response.headers['content-type'], });
      // const blob = new Blob([response.data], { type: 'application/pdf', });
      const blob = new Blob([response.data], { type: contentType });
      // Create a link element and click on it to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <Stack direction={{md:"row",xs:"column"}} spacing={2} sx={{marginLeft:{md:"0%",xs:"-95%"}}}> 
     <Stack direction={{ md: "row", xs: "column" }}>
  <Typography sx={{ fontSize: { md: "13px", xs: "16px" }, width: { md: "65px", xs: "100px" }, marginTop: { md: "6%", xs: "-55%" }, marginLeft: "0%", fontFamily: "Poppins" }}>Export to:</Typography>
  <select className="dropdown-m10k-excel" value={selectedFileType} style={{ marginTop: "3.2%", maxWidth: "100%", width: "auto" }} onChange={(e) => setSelectedFileType(e.target.value)}>
    <option value="excel" style={{ fontFamily: "Poppins" }}>Excel</option>
    <option value="pdf" style={{ fontFamily: "Poppins" }}>PDF</option>
  </select>
</Stack>

      <Stack direction="row" sx={{height:{md:"33px",xs:"37px"},marginLeft:{md:"1% !important",xs:"-2% !important"},marginTop:{sm:"0.5% !important"},width:{sm:"100px"}}}>
      <button
            onClick={handleDownload}
            disabled={!selectedFileType}
            style={{
              width: "105px",
              height: "100%",
              padding: "8px 15.39px 9.62px 26%",
              borderRadius: "7.69px",
              background: "#065036",
              color: "white",
              marginTop: "4.5%",
              marginBottom: "5%",
              cursor: "pointer",
              border: "0.96px solid #D0D5DD",
              backgroundImage: `url(${downloadcld})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "5% center",
              transition: "transform 0.3s ease, background-color 0.3s ease", 
              gap: "7.69px",
              fontFamily:"Poppins"
            }}
            onMouseOver={(e) => {
              e.target.style.transform = "scale(0.95)";
              e.target.style.backgroundColor = "#333"; 
            }}
            onMouseOut={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.backgroundColor = "#065036"; 
            }}
          >
        Download
      </button>
      </Stack>
    </Stack>
  );
};

export default DownloadComponent;
