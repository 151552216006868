import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../Assets/newLogo.svg";
import dashboard from "../../Assets/dashboard.svg";
import pacssanction from "../../Assets/pacs-sanction.svg";
import budget from "../../Assets/S.svg";
import buisenessI from "../../Assets/buisenessI.svg";
import centralanalysis from "../../Assets/central-analysis.svg";
import att from "../../Assets/att.svg";
import trend from "../../Assets/t.svg";
import reports from "../../Assets/reports.svg";
import datacollection from "../../Assets/data-collection.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import modules from "./drawer.module.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { Divider, Hidden, IconButton, MenuItem, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";

const drawerWidth = 220;
const drawerSmall = 50;

const DrawerComp = (props) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = React.useState(props.listName);
  const [menuItem, setMenuItem] = useState([
    "PACS Sanctioned",
    "Sanctioned Budget",
    "Business Intelligence",
    "Central Analytics",
    "Reports",
    "Data Collection",
  ]);

  const handleLogout = () => {
    const config = {
      headers: {
        "access-token": getCookie("token"),
        email: getCookie("email2"),
        Authorization: `Bearer ${getCookie("token")}`,
        "Content-Type": "application/json",
      },
    };
    //hiii-checkkkk
    const requestData = 
    {
      email: getCookie("email2"),
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/authenticate/logoff",
        requestData,
        config
      )
      .then((response) => {
        Cookies.remove("email2");
        Cookies.remove("token");
        Cookies.remove("userName");
        Cookies.remove("loginStatus");
        Cookies.remove("userRole");
        Cookies.remove("state");
        Cookies.remove("email");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        Cookies.remove("email2");
        Cookies.remove("token");
        Cookies.remove("userName");
        Cookies.remove("loginStatus");
        Cookies.remove("userRole");
        Cookies.remove("state");
        Cookies.remove("email");
        navigate("/");
      });
  };

  const handleData = () => {
    navigate("/datacollection");
  };
  const [option, setOption] = useState(null);
  const handleMenuClick = (event) => {
    setOption(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOption(null);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 200, backgroundColor: "#065036" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding sx={{ padding: 0 }}>
          <ListItemButton
            sx={{
              paddingBottom: 0,
            }}
            onClick={() => navigate("/")}
          >
            <ListItemText
              className={modules["sidebar-option"]}
              primary={"Home"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ padding: 0 }}>
          <ListItemButton onClick={() => navigate("/dashboard")}>
            <ListItemText
              className={modules["sidebar-option"]}
              primary={"Dashboard"}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider
        sx={{
          borderColor: "#125840",
        }}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/dashboard")}>
            <ListItemIcon>
              <img src={dashboard} alt="" style={{ marginLeft: "4px" }}></img>
            </ListItemIcon>
            <ListItemText
              sx={{
                minWidth: "200px",
              }}
              className={modules["sidebar-option"]}
            >
              PACS Live Dashboard
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className={
              selectedItem === "PACS Sanctioned"
                ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                : ""
            }
            onClick={() => navigate("/pacssanctioned")}
          >
            <ListItemIcon>
              <img alt="" src={pacssanction}></img>
            </ListItemIcon>
            <ListItemText sx={{}} className={modules["sidebar-option"]}>
              PACS Sanctioned
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={false}
            className={
              selectedItem === "Sanctioned Budget"
                ? modules["sidebar-menu"]
                : ""
            }
            onClick={() => navigate("/budgetSanc")}
          >
            <ListItemIcon>
              <img src={budget} alt="" style={{ marginLeft: "3px" }}></img>
            </ListItemIcon>
            <ListItemText
              sx={{ fontFamily: "poppins !important" }}
              className={modules["sidebar-option"]}
            >
              Sanctioned Budget
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <span className={modules["sidebar-benefit"]}>
          <b>BENEFITS</b>
          {/* <b></b> */}
        </span>
        <ListItem disablePadding>
          <ListItemButton 
           className={
            selectedItem === "Business Intelligence"
              ? modules["sidebar-menu"]
              : ""
          }
          onClick={() => navigate("/bi")}
          >
            <ListItemIcon>
              <img alt="" src={buisenessI}></img>
            </ListItemIcon>
            <ListItemText
              primary="Business Intelligence"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 9 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/trendAnalysis")}>
            <ListItemIcon>
              <img alt="" src={trend}></img>
            </ListItemIcon>
            <ListItemText
              primary="Trend Analysis"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 9 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/centralAnalysis")}>
            <ListItemIcon>
              <img alt="" src={centralanalysis}></img>
            </ListItemIcon>
            <ListItemText
              primary="Central Analytics"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 10 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/moReport")}>
            <ListItemIcon>
              <img alt="" src={reports}></img>
            </ListItemIcon>
            <ListItemText
              primary="Reports"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 10 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disabled={false} onClick={() => handleData()}>
            <ListItemIcon>
              <img alt="" src={datacollection}></img>
            </ListItemIcon>
            <ListItemText
              primary="Data Collection"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 10 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/attendance")}>
            <ListItemIcon>
              <img alt="" src={att}></img>
            </ListItemIcon>
            <ListItemText
              primary="Attendance"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 10 }}
            >
              Attendance
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleLogout()}
            className={selectedItem === "Logout" ? modules["sidebar-menu"] : ""}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              className={modules["sidebar-option"]}
              sx={{ fontSize: 10 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Hidden mdUp>
        <Drawer
          sx={{
            width: { xs: drawerSmall, md: drawerWidth },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { xs: drawerSmall, md: drawerWidth },
              boxSizing: "border-box",
              backgroundColor: "#065036",
              overflow: "hidden",
              padding: "10px",
              overflowY: "scroll",
              top: { xs: "66px", md: "80px" },
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ width: "3rem" }}
          >
            <MenuIcon sx={{ margin: 0, padding: 0, color: "white" }} />
          </IconButton>
          {/* <Popover
            open={Boolean(option)}
            anchorEl={option}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
             <MenuItem sx={{ fontSize: "12px",fontFamily:"Poppins",minHeight:{xs:"20px"} }} onClick={()=>{navigate('/'); handleCloseMenu();}}>
              Home
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px",fontFamily:"Poppins", minHeight:{xs:"20px"} }} onClick={()=>{navigate("/dashboard"); handleCloseMenu();}}>
            28 States Involved
            </MenuItem>
            {menuItem.map((item, index) => (
              <MenuItem
                sx={{ fontSize: "12px",fontFamily:"Poppins",minHeight:{xs:"20px"} }}
                key={index}
                onClick={() => {
                  navigate(`/${item.toLowerCase().replace(/ /g, "")}`);
                  handleCloseMenu();
                }}
              >
                {item}
              </MenuItem>
            ))}
            <MenuItem sx={{ fontSize: "12px",fontFamily:"Poppins",minHeight:{xs:"20px"} }} onClick={handleLogout}>
              Log Out
            </MenuItem>
          </Popover> */}
          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                backgroundColor: "#065036",
                overflow: "hidden",
                padding: "10px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              },
            }}
          >
            {DrawerList}
          </Drawer>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          sx={{
            width: { xs: drawerSmall, md: drawerWidth },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { xs: drawerSmall, md: drawerWidth },
              boxSizing: "border-box",
              backgroundColor: "#065036",
              overflow: "hidden",
              padding: "10px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              top: { xs: "66px", md: "80px" },
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "28 States Involved"
                    ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                    : ""
                }
                onClick={() => navigate("/dashboard")}
              >
                <ListItemIcon>
                  <img
                    src={dashboard}
                    alt=""
                    style={{ marginLeft: "4px" }}
                  ></img>
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: { xs: "none", md: "block" },
                    minWidth: "200px",
                  }}
                  className={modules["sidebar-option"]}
                >
                  PACS Live Dashboard
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "PACS Sanctioned"
                    ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                    : ""
                }
                onClick={() => navigate("/pacssanctioned")}
              >
                <ListItemIcon>
                  <img alt="" src={pacssanction}></img>
                </ListItemIcon>
                <ListItemText
                  sx={{ display: { xs: "none", md: "block" } }}
                  className={modules["sidebar-option"]}
                >
                  PACS Sanctioned
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "Sanctioned Budget"
                  ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
                onClick={() => navigate("/budgetSanc")}
              >
                <ListItemIcon>
                  <img src={budget} alt="" style={{ marginLeft: "3px" }}></img>
                </ListItemIcon>
                <ListItemText
                  sx={{ display: { xs: "none", md: "block" },
                  minWidth: "200px",
                }}
                  className={modules["sidebar-option"]}
                >
                  Sanctioned Budget
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <span className={modules["sidebar-benefit"]}>
              <b>BENEFITS</b>
            </span>
            <ListItem disablePadding>
              <ListItemButton
               className={
                selectedItem === "Business Intelligence"
                ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                : ""
              }
               onClick={() => navigate("/bi")}>
                <ListItemIcon>
                  <img alt="" src={buisenessI}></img>
                </ListItemIcon>
                <ListItemText
                  primary="Business Intelligence"
                  className={modules["sidebar-option"]}
                  sx={{ fontSize: 10, display: { xs: "none", md: "block" } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "Trend Analysis"
                  ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
                onClick={() => navigate("/trendAnalysis")}
              >
                <ListItemIcon>
                  <img alt="" src={trend}></img>
                </ListItemIcon>
                <ListItemText
                  sx={{ display: { xs: "none", md: "block" }, fontSize: 10 }}
                  className={modules["sidebar-option"]}
                >
                  Trend Analysis
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "Central Analytics"
                  ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
                onClick={() => navigate("/centralAnalysis")}
              >
                <ListItemIcon>
                  <img alt="" src={centralanalysis}></img>
                </ListItemIcon>
                <ListItemText
                  primary="Central Analytics"
                  className={modules["sidebar-option"]}
                  sx={{ fontSize: 10, display: { xs: "none", md: "block" } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "Reports" ? 
                  `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
                onClick={() => navigate("/moReport")}
              >
                <ListItemIcon>
                  <img alt="" src={reports}></img>
                </ListItemIcon>
                <ListItemText
                  primary="Reports"
                  className={modules["sidebar-option"]}
                  sx={{ fontSize: 10, display: { xs: "none", md: "block" } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                disabled={false}
                onClick={() => handleData()}
                className={
                  selectedItem === "Data Collection"
                  ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
              >
                <ListItemIcon>
                  <img alt="" src={datacollection}></img>
                </ListItemIcon>
                <ListItemText
                  primary="Data Collection"
                  className={modules["sidebar-option"]}
                  sx={{ fontSize: 10, display: { xs: "none", md: "block" } }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                className={
                  selectedItem === "Attendance"
                    ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                    : ""
                }
                onClick={() => navigate("/attendance")}
              >
                <ListItemIcon>
                  <img alt="" src={att}></img>
                </ListItemIcon>
                <ListItemText
                  className={modules["sidebar-option"]}
                  sx={{
                    fontSize: 10,
                    display: { xs: "none", md: "block" },
                    minWidth: "200px",
                  }}
                >
                  Attendance
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleLogout()}
                className={
                  selectedItem === "Logout" 
                  ? `{modules["sidebar-menu"]} ${modules["selected-item"]}`
                  : ""
                }
              >
                <ListItemIcon>
                  <LogoutIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  className={modules["sidebar-option"]}
                  sx={{ fontSize: 10 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default DrawerComp;
