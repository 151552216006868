import React from "react";
import Tb from "../../Assets/topB.svg";
import trophy from "../../Assets/trophy.svg";
import silver from "../../Assets/silver.svg";
import gold from "../../Assets/gold.svg";
import { Box, Typography } from "@mui/material";
export function Bar1({ height, statename }) {
  const maxHeight = 100;
  const dynamicHeight = maxHeight * (height / 100);
  return (
    <div style={{position:"relative"}}>
      <img src={trophy} alt="" style={{height:"50px", marginLeft:"10px",
      marginBottom: `-${maxHeight-dynamicHeight+5}px`, 
      }} />
      <div style={{  }}>
        <img src={Tb} alt="" style={{
          marginLeft: "-19px",
          marginBottom: `-${maxHeight - dynamicHeight + 5}px`,
        }}
        />
         <span 
      style={{
        marginLeft: "-70px",
        top: `${maxHeight - dynamicHeight-30}px`,
        zIndex:1000,
        position:"absolute",
        fontFamily:"Poppins",
        fontSize:"14px",
        fontWeight:600
      }}
      >{height}%</span>
      </div>

      <Box
        sx={{
          width: "70px",
          background: "linear-gradient(180deg, #047442 0%, #14B47C 100%)",
          borderRadius: "6px",
          zIndex: "-1",
          height: dynamicHeight + "px", marginTop: maxHeight - dynamicHeight + "px",
        }}
      >
      </Box>
      <Typography sx={{
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "12px",
        textAlign: "justify",
        width: "80px",
      }}>
        {statename}
      </Typography>
    </div>

  )
};

export function Bar2({ height, statename }) {
  const maxHeight = 100;
  const dynamicHeight = maxHeight * (height / 100);
  return (
    <div style={{}}>
      <img src={silver} alt="" style={{marginLeft:"15px", height:"50px",
      marginBottom: `-${maxHeight-dynamicHeight}px`, 
      }} />
      <div style={{ marginTop: "-10px" }}>
        <img src={Tb} alt="" style={{
          marginLeft: "-19px",
          marginBottom: `-${maxHeight - dynamicHeight + 5}px`,
        }}
        />
         <span 
      style={{
        marginLeft: "-70px",
        top: `${maxHeight - dynamicHeight-60}px`,
        zIndex:1000,
        position:"absolute",
        fontFamily:"Poppins",
        fontSize:"14px",
        fontWeight:600
      }}
      >{height}%</span>
      </div>

      <Box
        sx={{
          width: "70px",
          background: "linear-gradient(180deg, #047442 0%, #14B47C 100%)",
          borderRadius: "6px",
          zIndex: "-1",
          height: dynamicHeight + "px", marginTop: maxHeight - dynamicHeight + "px",
        }}
      >
      </Box>
      <Typography sx={{
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "12px",
        textAlign: "justify",
        width: "80px",
      }}>
        {statename}
      </Typography>
    </div>

  )
}

export function Bar3({ height, statename }) {
  const maxHeight = 100;
  const dynamicHeight = maxHeight * (height / 100);
  return (
    <>

      <img src={gold} alt="" style={{
        marginLeft: "15px", height: "50px",
        marginBottom: `-${maxHeight - dynamicHeight}px`,
      }} />
      <div style={{ marginTop: "-10px" }}>
        <img src={Tb} alt="" style={{
          marginLeft: "-19px",
          marginBottom: `-${maxHeight - dynamicHeight + 5}px`,
        }}
        />
         <span 
      style={{
        marginLeft: "-70px",
        top: `${maxHeight - dynamicHeight-60}px`,
        zIndex:1000,
        position:"absolute",
        fontFamily:"Poppins",
        fontSize:"14px",
        fontWeight:600
      }}
      >{height}%</span>
      </div>

      <Box
        sx={{
          width: "70px",
          background: "linear-gradient(180deg, #047442 0%, #14B47C 100%)",
          borderRadius: "6px",
          zIndex: "-1",
          height: dynamicHeight + "px", marginTop: maxHeight - dynamicHeight + "px",
        }}
      >
      </Box>
      <Typography sx={{
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "12px",
        textAlign: "justify",
        width: "80px",
      }}>
        {statename}
      </Typography>
    </>

  )
}

export function Bar({ height, statename,img, num, ml }) {
  let s2;
  if(statename.split(' ').length>1 )
  {
    const initial=statename
    .split(' ')
      .map(name => name[0])
      .join('');
      if(initial=== "AaNI")
      {
        s2= "Andaman"

      }
      else if(initial === "JaK"){
        s2="J&K"
      }
      else{
        s2=initial;
      }
      
  }
  else{
    s2=statename;
  }
  
  const maxHeight = 100;
  const dynamicHeight = maxHeight * (height / 100);
  return (
    <>
      <div style={{marginTop:"0px"}}>
      <img src={img} alt="" style={{
        marginLeft: "-19px",
        marginBottom: `-${maxHeight - dynamicHeight + 7}px`,
        content:"hi"
      }}
      />
      <span 
      style={{
        marginLeft: ml,
        // top: `${maxHeight - dynamicHeight-num}px`,
        zIndex:1000,
        position:"absolute",
        fontFamily:"Poppins",
        fontSize:"14px",
        fontWeight:600,
        bottom:`${dynamicHeight+num}px`
      }}
      >{height}%</span>
      </div>

      <Box
        sx={{
          width: "70px",
          background: "linear-gradient(180deg, #047442 0%, #14B47C 100%)",
          borderRadius: "6px",
          zIndex: "-1",
          height: dynamicHeight + "px",
          marginTop: maxHeight - dynamicHeight + "px",
        }}
      >
      </Box>
      <span style={{
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "12px",
        textAlign: "center",
        marginLeft:"-8px",
        width: "80px",
        position:"absolute",
        bottom:-20,
        zIndex:1000,
      }}>
        {s2}
      </span>
    </>

  )
}