import { Component } from 'react';
import parse from 'html-react-parser';
import "./captcha.css";

let captcha_value = '';
let captcha_number = '';
let backgroundColor_value = '';
let borderRadius_value='';
let fontColor_value = '';
let charMap_value = '';
let LoadCanvasTemplateNoReload_HTML = "<div><canvas id=\"canv\"></canvas><div><a id=\"reload_href\"  style=\"cursor: pointer; color: red; margin-left: 10px\"></a></div></div>";;


export const loadCaptchaEnginge = (numberOfCharacters, backgroundColor = "#abd5d6", fontColor = 'blue', borderRadius='10px' , charMap = '') => {

    backgroundColor_value = "#abd5d6";
    borderRadius_value ="10px";
    fontColor_value = fontColor;
    charMap_value = charMap;
    captcha_number = numberOfCharacters;
    let retVal = "";
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    if (charMap === "upper") {
        charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    } else if (charMap === "lower") {
        charset = "abcdefghijklmnopqrstuvwxyz0123456789";
    }
    else if (charMap === "numbers") {
        charset = "0123456789";
    }
    else if (charMap === "special_char") {
        charset = "~`!@#$%^&*()_+-=[]{}\|:'<>,.?/";
    }

    let length = parseInt(numberOfCharacters);



    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    let captcha = retVal;

    captcha_value = captcha;


    let canvas = document.getElementById('canv'),
        ctx = canvas.getContext('2d');
    let text = captcha;
    let lineheight = 30;

    let lines = text.split('\n');
    ctx.canvas.width = parseInt(length) * 25;
    ctx.canvas.height = (lines.length * lineheight);

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    ctx.textBaseline = "middle";
    ctx.font = "italic 20px Arial";
    ctx.fillStyle = fontColor;




    let num = 0;
    for (let i = 0; i < parseInt(length); i++) {
        num = parseInt(num) + 1;
        let heigt_num = 20 * num;
        ctx.fillText(retVal[i], heigt_num, Math.round(Math.random() * (15 - 12) + 12));
    }

    document.getElementById("reload_href").onclick = function () {
        loadCaptchaEnginge(captcha_number, backgroundColor, fontColor, charMap);
    }

};

export const validateCaptcha = (userValue, reload = true) => {
    if (userValue !== captcha_value) {
        if (reload === true) {
            loadCaptchaEnginge(captcha_number, backgroundColor_value, fontColor_value, borderRadius_value, charMap_value);
        }

        return false;
    }

    else {
        return true;
    }
};

export class LoadCanvasTemplate extends Component {
    render() {
        let reload_text = "";
        let reload_color = "white";
        let reload_image_src = "";

        if (this.props.reloadText) {
            reload_text = this.props.reloadText;
        }

        if (this.props.reloadColor) {
            reload_color = this.props.reloadColor;
        }
        if (this.props.reloadImageSrc) {
            reload_image_src = this.props.reloadImageSrc;
        }

        if (reload_text === "") {
            reload_text = "Reload Captcha";
        }

        if (reload_color === "") {
            reload_color = "blue";
        }

        const LoadCanvasTemplate_HTML = `
            <div style="display: flex; justify-content: space-between;">
                <div style="background-color: #abd5d6; border-radius: 7px; padding: 10px; width:50%; display: flex; justify-content: center; align-items: center;">
                    <canvas id="canv" style=" border-radius: 7px;text-align: center;"></canvas>
                </div>
                <div style="background-color: #38735e;font-size:18px; border-radius: 7px; padding: 10px; width:45%; display: flex; justify-content: center; align-items: center;">
                    <img alt="" src="${reload_image_src}" style="margin-right: 5px;">
                    <a id="reload_href" style="cursor: pointer; color: ${reload_color}; text-align: center; font-size: 14px">${reload_text}</a>
                </div>
            </div>
        `;

        return (parse(LoadCanvasTemplate_HTML));
    }
}




export class LoadCanvasTemplateNoReload extends Component {

    render() {
        return (parse(LoadCanvasTemplateNoReload_HTML));
    }

};
