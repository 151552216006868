import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DrawerComp from "../Drawer/drawer";
import MainHeader from "../Header/MainHeader";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { AutoLogout } from "../../Services/AutoLogout";
import Chart from "react-apexcharts";
import { Divider, Stack, Typography } from "@mui/material";

const getCurrentYearAndMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  return { year, month };
};

const PredictAnaly = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [apiRes, setApiRes] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("weekly");

  useEffect(() => {
    const { year, month } = getCurrentYearAndMonth();
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    const apiUrl =
      selectedInterval === "weekly"
        ? `https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getWeeklyRunRate?year=${year}&month=${month}`
        : "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getRunRateMonthly";

    axios
      .post(apiUrl, {}, config)
      .then((response) => {
        if (response?.status === 200) {
          setApiRes(response.data);
        } else if (response?.response?.status === 403) {
          AutoLogout();
        }
      })
      .catch((err) => {
        if(err.code === "ERR_NETWORK"){
          AutoLogout();
        }
        console.error("Error fetching data:", err);
      });
  }, [selectedInterval]);

  const filteredData = selectedInterval === "weekly"? Object.keys(apiRes).filter((week, index) => index !== 0) : Object.keys(apiRes);

  const formattedData = selectedInterval === "weekly"
    ? Object.keys(apiRes)
        .filter((week, index) => index !== 0)
        .map((week) => ({
          x: week,
          DCT_COMPLETED: apiRes[week].DCT_COMPLETED,
          ERP_TRIAL_RUN: apiRes[week].ERP_TRIAL_RUN,
          GO_LIVE_ACHIEVED: apiRes[week].GO_LIVE_ACHIEVED,
        }))
    : Object.keys(apiRes).map((week) => ({
          x: week,
          DCT_COMPLETED: apiRes[week].DCT_COMPLETED,
          ERP_TRIAL_RUN: apiRes[week].ERP_TRIAL_RUN,
          GO_LIVE_ACHIEVED: apiRes[week].GO_LIVE_ACHIEVED,
        }));

  const options = {
    chart:{
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: false
      },

    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      markers: {
        radius: 0,
        width: 39,
        height: 10,
      },
      itemMargin: {
        vertical: 20,
      },
    },
    xaxis: {
      categories: filteredData,
    },
    yaxis: {
      title: {
        text: "Change in Status",
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    colors: ["#FA9218", "#A155B9", "#B1E740"],
  };

  const series = [
    {
      name: "△ DCT Completed",
      data: formattedData.map((data) => data.DCT_COMPLETED),
    },
    {
      name: "△ ERP Onboarded",
      data: formattedData.map((data) => data.ERP_TRIAL_RUN),
    },
    {
      name: "△ Go Live Achieved",
      data: formattedData.map((data) => data.GO_LIVE_ACHIEVED),
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }}>
      <style>
        {/* check */}
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            .apexcharts-grid-borders{
                display: none !important;
            }
            .apexcharts-toolbar{
                display:none !important;
            }
            .apexcharts-xaxis-tick{
                display:none !important;
            }
            .apexcharts-legend-text{
                margin-left:5px !important;
            }
            `}
      </style>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MainHeader />
        <DrawerComp listName={"Trend Analysis"} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: "#fff",
            height: "100vh",
          }}
        >
          <Toolbar />
          <div style={{ backgroundColor: "#f4faf8", borderRadius: "8px" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", padding: "20px" }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontFamily: "Poppins" }}>Run Rate</Typography>
                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color: selectedInterval === "weekly" ? "#065036" : "grey",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedInterval("weekly")}
                  >
                    Weekly
                  </Typography>
                  <Divider
                    sx={{
                      borderColor: "grey",
                      borderWidth: "1px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color:
                        selectedInterval === "monthly" ? "#065036" : "grey",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedInterval("monthly")}
                  >
                    Monthly
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Chart options={options} series={series} type="line" height={350} />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default PredictAnaly;
