import React from "react";
import Graph from "./graph";
import { Typography } from "@mui/material";
import leafI from "../../Assets/leaf.svg";
const GraphParent = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#EEF4ED",
          padding: "10px 10px 10px 10px",
          borderRadius: "15px",
          position: "relative",
        }}
        className="checkWidth"
      >
        <img
          src={leafI}
          alt="deco1"
          style={{
            position: "absolute",
            width: "90%",
            paddingLeft: "13px",
          }}
        />
        <Typography
          sx={{
            paddingLeft: "20px",
            paddingTop: "10px",
            fontSize: "20px",
            fontWeight: 400,
            color: "#333333",
            fontFamily: "Poppins",
          }}
        >
          Total Graphs
        </Typography>
        <Graph />
      </div>
    </>
  );
};
export default GraphParent;
