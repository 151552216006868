// src/components/Cardsite.js
import React, { useState } from 'react';
import './Card.css'; 
import Dashboard from '../Dashboard/Dashboard';

const Cardsite = () => {

 const[goLive,setGoLive]=useState("38");
 const[audit,setAudit]=useState("23");
 const[status3,setStatus3]=useState("92");
 const[status4,setStatus4]=useState("18");

  return (
    <div>
      <Dashboard/>
      <div className="card-container">
        <div className="card">
          <div className="card-content">
            {goLive}<span className='percentage'>%</span>
            </div>
            <div className='textUnder1'>PAC's in Go-Live</div>
        </div>
        <div className="card">
          <div className="card-content">{audit}<span className='percentage'>%</span></div>
          <div className='textUnder2'>PAC's Current Audit Status</div>
        </div>
        <div className="card">
          <div className="card-content">{status3}<span className='percentage'>%</span></div>
          <div className='textUnder3'>Lorem Ipsum Status</div>
        </div>
        <div className="card">
          <div className="card-content">{status4}<span className='percentage'>%</span></div>
          <div className='textUnder3'>Lorem Ipsum Status</div>
        </div>
      </div>
    </div>
  );
}

export default Cardsite;
