import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerComp from "../Drawer/drawer";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import StackedBarChart from "../StackedBarChart/stackedBar";
import StackedBarChart1 from "../StackedBarChart/stackedBar1";
import DumbbellChart from "../LineChart/DumbbellChart";
import DonutChart from "../DonutChart/donutChart";
import Header from "../Header/MainHeader";
import leafI from "../../Assets/leaf.svg";
import "../DonutChart/donut.css";

const PacsSanc = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <style>
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            `}
      </style>
      <Box>
        <CssBaseline />
        <Header />
        <DrawerComp listName={"PACS Sanctioned"} />
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2.5}
          sx={{
            marginLeft: { xs: "70px", md: "240px" },
            marginTop: "90px",
            marginRight: { xs: "20px", md: "30px" },
          }}
        >
          <div
            style={{
              backgroundColor: "#EEF4ED",
              padding: "10px 10px 10px 10px",
              borderRadius: "15px",
              position: "relative",
            }}
            className="checkWidth"
          >
            <img
              src={leafI}
              alt="deco1"
              style={{
                position: "absolute",
                width: "90%",
                paddingLeft: "13px",
              }}
            />
            <Typography
              sx={{
                paddingLeft: "20px",
                paddingTop: "10px",
                fontSize: "20px",
                fontWeight: 400,
                color: "#333333",
                fontFamily: "Poppins",
              }}
            >
              Year of Registration
            </Typography>
            <Typography
              sx={{
                paddingLeft: "20px",
                paddingTop: "2px",
                fontSize: "30px",
                fontWeight: 700,
                color: "#333333",
                fontFamily: "Poppins",
              }}
            >
              2021-2022
            </Typography>
            <DumbbellChart />
          </div>
          <DonutChart />
        </Stack>
        <Box
          sx={{
            marginLeft: { xs: "70px", md: "240px" },
            marginTop: "15px",
            marginRight: "30px",
          }}
        >
          <StackedBarChart1 />
        </Box>
        <Box
          sx={{
            marginLeft: { xs: "70px", md: "240px" },
            marginTop: "15px",
            marginBottom: "15px",
            marginRight: "30px",
          }}
        >
          <StackedBarChart />

          <Typography sx={{ fontSize: "13px", marginTop: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Note: </span>
            All Co-operative Societies are awarded a classification letter, A,
            B, C, or D according to degrees of its financial stability and its
            operational efficiency.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default PacsSanc;
