import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { useAuth } from "../../Services/Auth";
import Icon from "../../Assets/files_icon.svg";
import Donut from "./donut";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";

const Row2comp = () => {
  const { setSelectedBtnSanc, selectedBtnSanc } = useAuth();
  const [apiRes, setApiRes] = useState([]);
  const [apiRes2, setApiRes2] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
      const requestBody = {
        stateName: selectedBtnSanc,
      };

      try {
        const hardwareResponse = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/hardware",
          requestBody,
          config
        );
        const siResponse = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/si",
          requestBody,
          config
        );

        if (hardwareResponse.status === 200) {
          setApiRes(hardwareResponse.data[0]);
        }

        if (siResponse.status === 200) {
          setApiRes2(siResponse.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedBtnSanc]);


  return (
    <>
      <Box
      sx={{
        backgroundColor: "#EEF4ED",
        padding: "2%",
        borderRadius: "4px",
      }}
      >
      <Stack direction="column">
        <Typography sx={{ fontFamily: "poppins" }}>
        {selectedBtnSanc}
        </Typography>
        <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: "bold",
          fontSize: "26px",
        }}
        >
        Hardware
        </Typography>
        <Stack
        sx={{ paddingTop: "10px" }}
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        >
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="column"
        >
          <Typography
          sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
          }}
          >
          Workorder no. of Pacs
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "10px" }} spacing={1}>
          <img src={Icon} alt="" style={{ width: "50px" }} />
          <Stack direction="column">
            <Typography
            sx={{
              fontWeight: "bold",
              color: "grey",
              fontSize: "12px",
            }}
            >
            PACS
            </Typography>
            <Typography
            sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "28px",
            }}
            >
            {apiRes.workOrderNumberOfPacs || 0}
            </Typography>
          </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="column"
        >
          <Typography
          sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
          }}
          >
          Hardware Cost
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "20px" }} >
          <img src={Icon} alt="" style={{ width: "50px" }} />
          <Stack direction="column">
            <Typography
            sx={{
              fontWeight: "bold",
              color: "grey",
              fontSize: "12px",
            }}
            >
            Hardware Cost
            </Typography>
            <Typography
            sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "26px",
            }}
            >
            ₹&nbsp;{apiRes.hardwareCost || 0} 
            </Typography>
          </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="row"
        >
          <Stack direction="column">
          <Typography
            sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
            }}
          >
            Total Cost
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "20px" }} >
            <img src={Icon} alt="" style={{ width: "50px" }} />
            <Stack direction="column">
            <Typography
              sx={{
              fontWeight: "bold",
              color: "grey",
              fontSize: "12px",
              }}
            >
              Total Cost
            </Typography>
            <Typography
              sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "28px",
              }}
            >
               {apiRes.totalCostOfHardware !== undefined ? apiRes.totalCostOfHardware.toFixed(2) : 0}&nbsp;Cr.
            </Typography>
            </Stack>
          </Stack>
          </Stack>
          <Donut stateSharePercentage={apiRes.stateSharePercentage} goiSharePercentage={apiRes.goiSharePercentage} />

        </Stack>
        </Stack>
        <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: "bold",
          fontSize: "26px",
          paddingTop: "5%",
        }}
        >
        SI Status
        </Typography>
        <Stack
        sx={{ paddingTop: "10px" }}
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        >
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="column"
        >
          <Typography
          sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
          }}
          >
          No. of Sanctioned PACS
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "10px" }} spacing={1}>
          <img src={Icon} alt="" style={{ width: "50px" }} />
          <Stack direction="column">
            <Typography
            sx={{ fontWeight: "bold", color: "grey", fontSize: "12px" }}
            >
            PACS
            </Typography>
            <Typography
            sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "28px",
            }}
            >
            {apiRes2.numberOfSanctionedPacs || 0}
            </Typography>
          </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="column"
        >
          <Typography
          sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
          }}
          >
          SI cost per PACS
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "20px" }} spacing={1}>
          <img src={Icon} alt="" style={{ width: "50px" }} />
          <Stack direction="column">
            <Typography
            sx={{ fontWeight: "bold", color: "grey", fontSize: "12px" }}
            >
            Hardware Cost
            </Typography>
            <Typography
            sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "28px",
            }}
            >
             ₹&nbsp;{apiRes2.siCost || 0}
            </Typography>
          </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
          backgroundColor: "#F7FCF7",
          padding: "10px",
          borderRadius: "6px",
          }}
          direction="row"
        >
          <Stack direction="column">
          <Typography
            sx={{
            color: "#333333",
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: "600",
            }}
          >
            Total Cost
          </Typography>
          <Stack direction="row" sx={{ paddingTop: "20px" }} spacing={1}>
            <img src={Icon} alt="" style={{ width: "50px" }} />
            <Stack direction="column">
            <Typography
              sx={{
              fontWeight: "bold",
              color: "grey",
              fontSize: "12px",
              }}
            >
              Total Cost
            </Typography>
            <Typography
              sx={{
              color: "#065036",
              fontWeight: "bold",
              fontSize: "28px",
              }}
            >
              {apiRes2.totalCostOfSi !== undefined ? apiRes2.totalCostOfSi.toFixed(2) : 0}&nbsp;Cr.
            </Typography>
            </Stack>
          </Stack>
          </Stack>
          <Donut stateSharePercentage={apiRes2.stateSharePercentage} goiSharePercentage={apiRes2.goiSharePercentage} />

        </Stack>
        </Stack>
      </Stack>
      </Box>
    </>
    );
};

export default Row2comp;
