import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import searchIcon from "../../Assets/search 1.svg";
import dropdown from "../../Assets/Polygon 1.svg";
// import './si.css'
// import '../Dashboard/dashboard.css'
export default function SystemIntegration() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <div className="background-image-container">
        <div className="sub-container">
          <div className="welcome-text1">Welcome to your</div>
          <div className="dashboard-text">System Integration</div>
          <div className="random-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
            <div>elit, sed do eiusmod tempor</div>
          </div>

          <div className="search-menu">
            <div className="search-container">
              <div className="search-icon-container">
                <img src={searchIcon} alt="Search" className="search-icon" />
              </div>
              <div className="search-input-container">
                <input
                  type="text"
                  placeholder="Search your PACs/States here"
                  className="search-field"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
