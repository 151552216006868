import React, { useState, useEffect } from "react";
import "./homepage.css";
import axios from "axios";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import First from "../../Assets/p1.png";
import Second from "../../Assets/p2.png";
import podium from "../../Assets/podium.png";
import TP from "../../Assets/Bronze.png";
import SP from "../../Assets/Silver.png";
import FP from "../../Assets/Gold.png";
import stars from "../../Assets/stars.png";
import flowersBg from "../../Assets/flowers_bg.png";
import LeaderBoard from "./Leaderboard";

const TabComponent = () => {
  const [selectedTab, setSelectedTab] = useState("DCT");
  const [nameF, setNameF] = useState("");
  const [countF, setCountF] = useState(null);
  const [nameS, setNameS] = useState("");
  const [countS, setCountS] = useState(null);
  const [nameT, setNameT] = useState("");
  const [countT, setCountT] = useState(null);
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  const res ={
    "erpData": {
        "Andaman and Nicobar Islands": 100.0,
        "Arunachal Pradesh": 100.0,
        "Ladakh": 100.0,
        "Sikkim": 100.0,
        "Uttarakhand": 100.0,
        "Assam": 98.97,
        "Jammu and Kashmir": 97.95,
        "Madhya Pradesh": 96.16,
        "Mizoram": 96.0,
        "Himachal Pradesh": 95.4,
        "Jharkhand": 85.0,
        "Puducherry": 82.22,
        "Tripura": 76.49,
        "Bihar": 66.65,
        "Gujarat": 50.9,
        "Rajasthan": 41.19,
        "Maharashtra": 40.97,
        "Chhattisgarh": 37.18,
        "Meghalaya": 36.61,
        "Uttar Pradesh": 27.07,
        "Goa": 20.69,
        "Haryana": 14.93,
        "Nagaland": 14.29,
        "Punjab": 3.56,
        "Tamil Nadu": 1.32,
        "Andhra Pradesh": 0.59,
        "West Bengal": 0.46,
        "Karnataka": 0.24,
        "DNH  DD": 0.0,
        "Manipur": 0.0
    },
    "goLiveData": {
        "Arunachal Pradesh": 100.0,
        "Ladakh": 100.0,
        "Mizoram": 96.0,
        "Assam": 80.1,
        "Sikkim": 75.7,
        "Tripura": 72.01,
        "Jharkhand": 68.87,
        "Uttarakhand": 64.48,
        "Andaman and Nicobar Islands": 50.0,
        "Meghalaya": 36.61,
        "Uttar Pradesh": 18.52,
        "Nagaland": 14.29,
        "Goa": 12.07,
        "Himachal Pradesh": 10.11,
        "Puducherry": 8.89,
        "Maharashtra": 4.19,
        "Haryana": 2.68,
        "Madhya Pradesh": 2.45,
        "Punjab": 2.18,
        "Bihar": 1.25,
        "Rajasthan": 0.97,
        "Chhattisgarh": 0.64,
        "Andhra Pradesh": 0.59,
        "Tamil Nadu": 0.18,
        "Karnataka": 0.11,
        "West Bengal": 0.05,
        "DNH  DD": 0.0,
        "Gujarat": 0.0,
        "Jammu and Kashmir": 0.0,
        "Manipur": 0.0
    },
    "dctData": {
        "Andaman and Nicobar Islands": 100.0,
        "Arunachal Pradesh": 60.0,
        "Jharkhand": 30.0,
        "Ladakh": 100.0,
        "Sikkim": 100.0,
        "Uttarakhand": 100.0,
        "Madhya Pradesh": 99.58,
        "Assam": 99.49,
        "Jammu and Kashmir": 98.88,
        "Himachal Pradesh": 98.16,
        "Mizoram": 96.0,
        "Puducherry": 93.33,
        "Gujarat": 86.72,
        "Tripura": 83.58,
        "Bihar": 74.77,
        "Chhattisgarh": 66.91,
        "Maharashtra": 60.58,
        "Rajasthan": 58.9,
        "Goa": 53.45,
        "Meghalaya": 47.32,
        "Haryana": 36.9,
        "Uttar Pradesh": 28.63,
        "Nagaland": 14.29,
        "Tamil Nadu": 9.31,
        "Punjab": 5.17,
        "West Bengal": 4.2,
        "Andhra Pradesh": 0.59,
        "Karnataka": 0.4,
        "DNH  DD": 0.0,
        "Manipur": 0.0
    }
};

const[data, setData]=useState();

  useEffect(() => {
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getTop3States"
      )
      .then((response) => {
        const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        const data = decodedData[selectedTab.toLowerCase() + "Data"];
        const keys = Object.keys(data);
        setData(decodedData);

        // const data = response.data[selectedTab.toLowerCase() + "Data"];
        // const keys = Object.keys(data);

        // setNameF(keys[0]);
        // setCountF(data[keys[0]]);
        // setNameS(keys[1]);
        // setCountS(data[keys[1]]);
        // setNameT(keys[2]);
        // setCountT(data[keys[2]]);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, [selectedTab]);

  return (
    <div className="topDiv">
      <img
        src={flowersBg}
        alt="Background Flower"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "20%",
          height: "auto",
          zIndex: "-1",
          marginRight: "20px",
        }}
      />
      <Stack spacing={0.2} direction="column" alignItems="center">
        <Typography
              sx={{
                fontWeight: "700",
                fontSize: { xs: "30px", sm: "48px", md: "54px" },
                fontFamily: "'Poppins', sans-serif",
                alignItems:"center",
                color:"#333333",
                textAlign:"center"
              }}
            >
            Leaderboard
            </Typography>
        <div style={{ paddingTop: "30px" }} className="buttonsClassH">
          <Button
            onClick={() => handleTabClick("DCT")}
            sx={{
              backgroundColor: selectedTab === "DCT" ? "#065036" : "#9BA19F3B",
              color: selectedTab === "DCT" ? "white" : "black",
              borderRadius: "10px 0px 0px 10px",
              width: { xs: "150px", md: "170px" },
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              "&:hover": {
                backgroundColor:
                  selectedTab === "DCT" ? "#065036" : "#9BA19F3B",
                color: selectedTab === "DCT" ? "white" : "black",
              },
            }}
            className="btn1H"
          >
            Data Captured Tool
          </Button>
          <Button
            onClick={() => handleTabClick("ERP")}
            sx={{
              backgroundColor: selectedTab === "ERP" ? "#065036" : "#9BA19F3B",
              color: selectedTab === "ERP" ? "white" : "black",
              borderRadius: "0px",
              width: { xs: "150px", md: "170px" },
              fontSize: { xs: "14px", md: "16px" },
              textTransform: "none",
              "&:hover": {
                backgroundColor:
                  selectedTab === "ERP" ? "#065036" : "#9BA19F3B",
                color: selectedTab === "ERP" ? "white" : "black",
              },
            }}
            className="btn1H"
          >
            ERP Onboarded
          </Button>
          <Button
            onClick={() => handleTabClick("goLive")}
            sx={{
              backgroundColor:
                selectedTab === "goLive" ? "#065036" : "#9BA19F3B",
              color: selectedTab === "goLive" ? "white" : "black",
              borderRadius: "0px 10px 10px 0px",
              textTransform: "none",
              width: { xs: "150px", md: "170px" },
              fontSize: { xs: "14px", md: "16px" },
              "&:hover": {
                backgroundColor:
                  selectedTab === "goLive" ? "#065036" : "#9BA19F3B",
                color: selectedTab === "goLive" ? "white" : "black",
              },
            }}
            className="btn1H"
          >
            Go-Live
          </Button>
        </div>
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={{ xs: 2, md: 4 }}
          // sx={{ paddingTop: "50px" }}
          className="topStateDivH"
        >
          {data && (
            <LeaderBoard type={selectedTab === "DCT"? data.dctData:
        selectedTab === "ERP"? data.erpData:
        data.goLiveData
        }/>
          )}
          
          {/* third position */}
          {/* <Stack direction="column" alignItems="center" spacing={4}>
            {countT === countF && countF === countS ? (
              <>
                <img src={FP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background:
                      "linear-gradient(180deg, #88C637 0%, #71C206 100%)",
                    marginTop: "-1px !important",
                    zIndex: "-1",
                    height: "150px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameT}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countT}
                </Typography>
              </>
            ) : countS === countT ? (
              <>
                <img src={stars} alt="" style={{ width: "80px" }} />
                <img src={SP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background: "rgba(228, 83, 18, 1)",
                    marginTop: "-20px !important",
                    zIndex: "-1",
                    height: "130px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameT}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countT}
                </Typography>
              </>
            ) : (
              <>
                <img src={stars} alt="" style={{ width: "80px" }} />
                <img src={TP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background:
                      "linear-gradient(180deg, #3C4B62 0%, rgba(34, 74, 136, 0.81) 100%)",
                    marginTop: "-20px !important",
                    zIndex: "-1",
                    height: "100px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameT}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countT}
                </Typography>
              </>
            )}
          </Stack> */}

          {/* first position */}
          {/* <Stack direction="column" alignItems="center" spacing={4} className="stackTop1h">
            <img src={FP} alt="" style={{ width: "120px" }} />
            <Box
              sx={{
                width: "100px",
                background: "linear-gradient(180deg, #88C637 0%, #71C206 100%)",
                marginTop: "-1px !important",
                zIndex: "-1",
                height: "150px",
              }}
              className="box1TopH"
            />
            <Typography
              sx={{
                marginTop: "5px !important",
                fontWeight: "bold",
                fontSize: { xs: "14px", md: "16px" },
                textAlign: "center",
              }}
            >
              {nameF}
            </Typography>
            <Typography
              sx={{
                marginTop: "5px !important",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {countF}
            </Typography>
          </Stack> */}

          {/* second position */}
          {/* <Stack direction="column" alignItems="center" spacing={4} className="stackTop2h">
            {countT === countF && countF === countS ? (
              <>
                <img src={FP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background:
                      "linear-gradient(180deg, #88C637 0%, #71C206 100%)",
                    marginTop: "-1px !important",
                    zIndex: "-1",
                    height: "150px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameS}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countS}
                </Typography>
              </>
            ) : countF === countS ? (
              <>
                <img src={FP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background:
                      "linear-gradient(180deg, #88C637 0%, #71C206 100%)",
                    marginTop: "-1px !important",
                    zIndex: "-1",
                    height: "150px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameS}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countS}
                </Typography>
              </>
            ) : (
              <>
                <img src={stars} alt="" style={{ width: "80px" }} />
                <img src={SP} alt="" style={{ width: "120px" }} />
                <Box
                  sx={{
                    width: "100px",
                    background: "rgba(228, 83, 18, 1)",
                    marginTop: "-20px !important",
                    zIndex: "-1",
                    height:"130px",
                  }}
                  className="box1TopH"
                />
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", md: "16px" },
                    textAlign: "center",
                  }}
                >
                  {nameS}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px !important",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {countS}
                </Typography>
              </>
            )}
          </Stack> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default TabComponent;
