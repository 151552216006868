import { Grid, Typography, Button, Box, InputLabel, FormControl, MenuItem, Select, FormControlLabel, RadioGroup, Radio, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import one from "../../Assets/one.svg";
import two from "../../Assets/two.svg";
import CustomCalendar from "./Calendar";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
export default function Attendance() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [attendanceType, setAttendanceType] = useState('');
    const [filledDates, setFilledDates] = useState([]);
    const [check, setCheck] = useState(false);

    const handleAttendanceChange = (event) => {
        setAttendanceType(event.target.value);
    };

    // Function to handle the selected date
    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };
    useEffect(() => {
        var data = JSON.stringify({
            "resourceId": getCookie("resourceId"),
        });

        var config = {
            method: 'post',
            url: 'https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/getAttendance',
            headers: {
                email: getCookie("email2"),
                "access-token": getCookie("token"),
                Authorization: `Bearer ${getCookie("token")}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    const dates = res?.data?.map(item => parseDateString(item.date));
                    setFilledDates(dates);
                    setCheck(false)
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }, [getCookie("resourceId"), check])
    console.log(filledDates)
    const parseDateString = (dateString) => {
        // Split the date string into day, month, and year
        const [day, month, year] = dateString.split('/').map(Number);
        // Return the formatted date string "dd/mm/yyyy"
        return `${day}/${month}/${year}`;
    };
    const handleDowloadSummary = () => {
        let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        let fileName = "summary.xlsx"; // Default filename
    
        var config = {
            method: 'post',
            url: 'https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/attendanceSummaryProgress',
            headers: {
                email: getCookie("email2"),
                "access-token": getCookie("token"),
                Authorization: `Bearer ${getCookie("token")}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        };
    
        axios(config)
            .then((res) => {
                if (res.status === 200) {
                    // Check if Content-Disposition header exists
                    const contentDisposition = res.headers['content-disposition'];
                    if (contentDisposition) {
                        // Extract filename from Content-Disposition header
                        const matches = contentDisposition.match(/filename="(.+?)"/);
                        if (matches) {
                            fileName = matches[1]; // Set filename dynamically
                        }
                    }
    
                    // Create a URL for the blob
                    const blob = new Blob([res.data], { type: contentType });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
    
                    alert("Summary downloaded successfully.");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    

    const handleMarkAttendance = () => {
        let formattedDate = selectedDate ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}` : null;
        // console.log('Selected Date:', formattedDate);
        console.log('Selected attendance:', attendanceType, formattedDate);
        if (selectedDate && attendanceType) {
            var data = JSON.stringify([
                {
                    "resourceId": getCookie("resourceId"),
                    "date": formattedDate,
                    "status": attendanceType.toUpperCase()
                }
            ]);

            var config = {
                method: 'post',
                url: 'https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/markAttendance',
                headers: {
                    email: getCookie("email2"),
                    "access-token": getCookie("token"),
                    Authorization: `Bearer ${getCookie("token")}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                        alert(`Attendance marked for ${formattedDate}`)
                        setCheck(true);
                        formattedDate=null;
                        setSelectedDate('');
                        setAttendanceType("");
                    }
                    
                })
                .catch((err) => {
                    console.log(err);
                    // if(err.response.data.status === 400){
                    //     alert(err.message + "\nError Message: " + err.response.data.errorMessage);
                    //     setCheck(true);
                    // }
                    // response.data.errorMessage
                })
        }
        else if (!selectedDate) {
            alert("Please select date.");
            setAttendanceType("");
        }
    };
    return (
        <>
            <style>
                {
                    `
            .react-calendar{
                width:100% !important;
                border:none !important;
                padding: 10px !important;
                border-radius: 8px;
            }
            @media screen and (max-width: 606px) and (min-width: 400px) {
                .react-calendar{
                    padding: 0px !important;
                }
            }
            @media only screen and (width: 390px){
                .react-calendar{
                    padding: 0px !important;
                }
            }
            .MuiFormControlLabel-label{
                font-family: Poppins !important;
                font-size: 14px !important;
                font-weight: 500 !important;
            }
            `
                }
            </style>
            <div style={{
                width: "100%",
                backgroundColor: "white",
                margin: "auto",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column"
            }}
            >
                <Grid container
                    sx={{
                        padding: "12px"
                    }}
                >
                    <Grid item xs={6} sm={6}>
                        <Typography
                            sx={{
                                // paddingTop: "10px",
                                fontSize: { xs: "14px", md: "18px" },
                                fontWeight: "500",
                                fontFamily: "Poppins"
                            }}>Mark Attendance</Typography>
                    </Grid>
                    {getCookie("userRole") === "HO" ?
                        (
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontSize: "11px",
                                        padding: { xs: "6px ", md: "8px 5px 8px 5px" },
                                        textTransform: "capitalize",
                                        backgroundColor: "#065036",
                                        borderRadius: "7.96px",
                                        '&:hover': {
                                            backgroundColor: "#065036",
                                        }

                                    }}
                                    onClick={handleDowloadSummary}
                                ><span style={{ fontFamily: "Poppins" }}>Download Summary</span></Button>
                            </Grid>
                        ) :
                        ""}

                </Grid>
                <Grid container spacing={2}
                    sx={{
                        padding: "10px"
                    }}
                >
                    <Grid item xs={12} md={7}>
                        {/* <Box sx={{
                            width:{xs:"65%", md:"100%"},
                            backgroundColor: "#F5F5F5",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "10px",
                            paddingRight: {xs:"0px", md:"20px"},
                            paddingBottom: "10px",
                            paddingLeft: { xs: "0px", md: "20px", sm: "0px" }
                        }}> */}
                            <CustomCalendar date={selectedDate} onDateSelect={handleDateSelect} filledDates={filledDates} />
                        {/* </Box> */}
                    </Grid>
                    <Grid item xs={5}>

                        <Box sx={{
                            backgroundColor: "#F5F5F5",
                            borderRadius: "8px",
                            height: "100%",
                            position: "relative",
                            width: {xs:"77vw", md:"100%"},
                        }}>
                            <Grid container
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    borderRadius:"8px"
                                }}
                            >
                                <Grid item xs={12} md={7} py={3}
                                    paddingLeft={2}>
                                    <Typography fontSize={14} fontWeight={500} fontFamily="Poppins" >Choose Attendance Type</Typography>
                                    <form style={{ marginTop: "10px", paddingLeft: "15px" }}
                                    className="form-p"
                                    >
                                        <FormControl component="fieldset">
                                            <RadioGroup name="attendance"
                                                value={attendanceType}
                                                onChange={handleAttendanceChange}
                                            >
                                                <FormControlLabel sx={{ fontSize: "10px !important" }} value="Present" control={<Radio size="small" />} label="Present" />
                                                <FormControlLabel value="Absent" control={<Radio size="small" />} label="Absent" />
                                                <FormControlLabel value="Visit" control={<Radio size="small" />} label="Visit" />
                                                <FormControlLabel value="Holiday" control={<Radio size="small" />} label="Holiday" />
                                            </RadioGroup>
                                        </FormControl>
                                    </form>

                                    <Button
                                        onClick={handleMarkAttendance}
                                        variant="contained"
                                        disabled={!attendanceType}
                                        style={{
                                            fontSize: "11px",
                                            padding: "8px 5px 8px 5px",
                                            textTransform: "capitalize",
                                            backgroundColor: attendanceType ? "#065036" : "#98b5ab",
                                            borderRadius: "7.96px",
                                            marginTop: "25%",
                                            marginLeft: "15px",
                                            color: "white"
                                        }}
                                        className="mark-P"
                                    ><span style={{ fontFamily: "Poppins" }}>Mark Attendance</span></Button>
                                </Grid>
                                <Grid item xs={12} md={5} 
                                display={{ xs: 'none', md: 'block' }}
                                >
                                    <Grid container >
                                        <Grid item xs={6}>
                                            <Box width="100%" height="100%">
                                                <img alt="one" src={one}
                                                className="image-P"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    marginTop:"50%",
                                                    marginLeft:"25%"
                                                }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box width="100%" height="100%">
                                                <img alt="two" src={two} 
                                                 className="image-P2"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    marginLeft:"-30%"
                                                }} />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}