import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { styled } from "@mui/material/styles";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "./28Dashboard.css";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { getCookie } from "../../../Services/Cookies";
import Info from "../../../Assets/info.svg";
import axios from "axios";
import axiosInstance from "../../../Services/AxiosInstance";
import ShimmerUi from "./ShimmerLine";
const Flipcard = () => {
  const stateArray = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Ladakh",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Uttar Pradesh",
    "West Bengal",
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    paddingLeft: "20px",
    textAlign: "left",
    color: "black",
    borderRadius: "10px",
    boxShadow: "none",
    height: "27px",
    cursor: "default",
    display: "flex",
    alignItems: "center",
    fontSize: "11px",
  }));
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );
  const SharedButton = styled(Button)(({ theme }) => ({
    fontSize: "12px",
    padding: "5px",
    width: "2.3cm",
    height: "35px",
    lineHeight: "1",
    textTransform: "capitalize",
    borderRadius: "6px",
    border: "2px solid #065036",
    cursor: "context-menu",
    "&:hover": {
      boxShadow: "none",
    },
    "@media only screen and (max-width: 375px)": {
      width: "1.8cm", // Change the width for screens narrower than 768px
    },
  }));
 
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#065036",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#065036",
    },
  }));
 
  const [isFlipped, setIsFlipped] = useState(
    stateArray.map((i) => {
      return false;
    })
  );
  const [hardwarevalues, setHardwrevalues] = useState([]);
  const [erpvalues, setErpvalues] = useState([]);
  const [golivevalues, setGolivevalues] = useState([]);
  const [pacsvalues, setPacsvalues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [districtData, setDistrictData] = useState({
    s1: {},
    s2: {},
    s3: {},
    s4: {},
    s5: {},
    s6: {},
    s7: {},
    s8: {},
    s9: {},
    s10: {},
    s11: {},
    s12: {},
    s13: {},
    s14: {},
    s15: {},
    s16: {},
    s17: {},
    s18: {},
    s19: {},
    s20: {},
    s21: {},
    s22: {},
    s23: {},
    s24: {},
    s25: {},
    s26: {},
    s27: {},
    s28: {},
  });
 
  // const handleFlip = (index, buttonName) => {
  //     // If the clicked card is already flipped, flip it back to the front
  //     if (isFlipped[index]) {
  //         setIsFlipped((prevState) => {
  //             const newState = [...prevState];
  //             newState[index] = !newState[index];
  //             return newState;
  //         });
  //         return;
  //     }
 
  //     // Flip the clicked card to the back and all other cards to the front
  //     setIsFlipped((prevState) => {
  //         const newState = prevState.map((value, i) => (i === index ? !value : false));
  //         return newState;
  //     });
  // }
  const handleFlip = (index, buttonName, isHovering) => {
    // If the card is being hovered over
    if (isHovering) {
      // Flip the card to the back
      setIsFlipped((prevState) => {
        const newState = prevState.map((value, i) =>
          i === index ? true : false
        );
        return newState;
      });
    } else {
      // If the card is not being hovered over, flip it to the front
      setIsFlipped((prevState) => {
        const newState = prevState.map((value, i) =>
          i === index ? false : value
        );
        return newState;
      });
    }
  };
 
  useEffect(() => {
    let completedApiCalls = 0;
    const totalApiCalls = stateArray.length;

    const handleApiCallCompletion = () => {
      completedApiCalls++;
      if (completedApiCalls === totalApiCalls) {
        setLoading(false);
      }
    };
    // const config = {
    //     headers: {
    //         'email': getCookie('email2'),
    //         'access-token': getCookie('token'),
    //         'Authorization': `Bearer ${getCookie('token')}`
    //     }
    // };
    // axios.post('https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/stateWiseLatestDataDetailed', {}, config)
    //     .then((res) => {
    //         const dataWithoutKeys = res.data.replace(key1, '').replace(key2, '');
    //         const decodedData = JSON.parse(atob(dataWithoutKeys));
    //         setPacsvalues(Object.values(decodedData).map(i => i.pacs));
    //         setHardwrevalues(Object.values(decodedData).map(i => i.hardwareDelivered));
    //         setErpvalues(Object.values(decodedData).map(i => i.erpTrailRun));
    //         setGolivevalues(Object.values(decodedData).map(i => i.goLive));
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
    axiosInstance
      .post("stateWiseLatestDataDetailed", {})
      .then((res) => {
        setPacsvalues(Object.values(res.data).map((i) => i.pacs));
        setHardwrevalues(
          Object.values(res.data).map((i) => i.hardwareDelivered)
        );
        setErpvalues(Object.values(res.data).map((i) => i.erpTrailRun));
        setGolivevalues(Object.values(res.data).map((i) => i.goLive));
      })
      .catch((err) => {
        console.log(err);
      });

      stateArray.map((statename, index) => {
        setLoading(true);
        const data = {
          state: statename,
        };
      
        axiosInstance
          .post("top5Districts", data)
          .then((res) => {
            // const dataWithoutKeys = res.data.replace(key1, "").replace(key2, "");
            // const decodedData = JSON.parse(atob(dataWithoutKeys));
            setDistrictData((prev) => ({
              ...prev,
              [`s${index + 1}`]: res.data,
            }));
            handleApiCallCompletion();
          })
          .catch((err) => {
            console.log(err);
            handleApiCallCompletion();
          });
      });
  }, []);

 
  const calculatePerc = (value, pacs) => {
    let valuePerc = (value * 100) / pacs;
    valuePerc = valuePerc.toFixed(2);
    return valuePerc;
  };
  return (
    <div className="card-container">
      {stateArray.map((i, index) => (
        <ReactCardFlip
          key={index}
          flipDirection="horizontal"
          isFlipped={isFlipped[index]}
          style={{
            cursor: "pointer",
          }}
        >
          <div
            className={`card-front ${isFlipped[index] ? "flipped-card" : ""}`}
            // onClick={() => handleFlip(index, null)}
            onMouseEnter={() => handleFlip(index, null, true)}
          >
            <div
              style={{
                paddingTop: "6px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252525",
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                {i}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#065036",
                  fontSize: "23px",
                  fontWeight: "700",
                  marginTop: "9px",
                  fontFamily: "Poppins",
                }}
              >
                <strong>Total PACS: {pacsvalues[index]}</strong>
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              paddingLeft={2}
              paddingTop={1}
              paddingRight={2}
            >
              <Grid item xs={4}>
                <CircularProgressbar
                  strokeWidth={15}
                  value={calculatePerc(golivevalues[index], pacsvalues[index])}
                  text={golivevalues[index] === 0 ? "0" : golivevalues[index]}
                  styles={buildStyles({
                    textColor: "black",
                    trailColor: "#bad3cb",
                    pathColor: "#08a450",
                    strokeLinecap: "butt",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <CircularProgressbar
                  strokeWidth={15}
                  value={calculatePerc(erpvalues[index], pacsvalues[index])}
                  text={erpvalues[index] === 0 ? "0" : erpvalues[index]}
                  styles={buildStyles({
                    textColor: "black",
                    trailColor: "#bad3cb",
                    pathColor: "#f3bc00",
                    strokeLinecap: "butt",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <CircularProgressbar
                  strokeWidth={15}
                  value={calculatePerc(
                    hardwarevalues[index],
                    pacsvalues[index]
                  )}
                  text={
                    hardwarevalues[index] === 0 ? "0" : hardwarevalues[index]
                  }
                  styles={buildStyles({
                    textColor: "black",
                    trailColor: "#bad3cb",
                    pathColor: "#0d7da8",
                    strokeLinecap: "butt",
                  })}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              marginLeft="6px"
              marginTop="10px"
              marginBottom="5px"
            >
              <Grid container xs={12}>
                <Grid item xs={4}>
                  <SharedButton
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      color: "#065036",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Go-Live
                  </SharedButton>
                </Grid>
                <Grid item xs={4}>
                  <SharedButton
                    variant="contained"
                    style={{
                      backgroundColor: "#065036",
                      color: "white",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    ERP Trail Run
                  </SharedButton>
                </Grid>
                <Grid item xs={4}>
                  <SharedButton
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      color: "#065036",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Hardware Delivered
                  </SharedButton>
                </Grid>
              </Grid>
            </Grid>
 
            <Grid
              container
              rowSpacing={0.2}
              sx={{
                paddingTop: "0px",
                padding: "10px",
              }}
              onMouseEnter={() => handleFlip(index, null, false)}
            >
              <Grid item xs={12}>
                <Item>
                  <Grid container>
                    <Grid item xs={6}>
                      <strong style={{ fontFamily: "Poppins" }}>
                        District Name
                      </strong>
                    </Grid>
                    <Grid item xs={6}>
                      <strong style={{ fontFamily: "Poppins" }}>
                        ERP Onboarded
                      </strong>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              {districtData && (
                <>
                  {Object.keys(districtData[`s${index + 1}`]).length === 0 ? (
                    <>
                      <Grid item xs={12}>
                        {loading ? (
                          <ShimmerUi />
                        ) : (
                          <div
                            style={{
                              margin: "20px",
                              textAlign: "center",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Data is not available
                          </div>
                        )}
                      </Grid>
                    </>
                  ) : (
                    Object.keys(districtData[`s${index + 1}`]).map(
                      (district, districtIndex) => (
                        <Grid key={districtIndex} item xs={12}>
                          <Item>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                style={{ fontFamily: "Poppins" }}
                              >
                                {district.length > 11 ? (
                                  <BootstrapTooltip title={district}>
                                    {district.slice(0, 11) + ".."}
                                  </BootstrapTooltip>
                                ) : (
                                  district
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{ fontFamily: "Poppins" }}
                              >
                                {
                                  districtData[`s${index + 1}`][district]
                                    ?.erpTrialRun
                                }
                              </Grid>
                            </Grid>
                          </Item>
                        </Grid>
                      )
                    )
                  )}
                </>
              )}
            </Grid>
          </div>
          <div
            className={`card-front card-back ${
              isFlipped[index] ? "flipped-card" : ""
            }`}
            // onClick={() => handleFlip(index, null)}
            onMouseLeave={() => handleFlip(index, null, false)}
          >
            <div
              style={{
                paddingTop: "6px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#252525",
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >
                {i}
              </Typography>
            </div>
            <Grid
              container
              rowSpacing={0.2}
              sx={{
                padding: "6px",
              }}
            >
              <Grid item xs={12}>
                <Item>
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ fontFamily: "Poppins" }}>
                        District Name
                      </strong>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <strong style={{ fontFamily: "Poppins" }}>GL </strong>
                      <BootstrapTooltip title="Go-Live" arrow>
                        <img
                          alt="info"
                          style={{
                            marginLeft: "4px",
                          }}
                          src={Info}
                        ></img>
                      </BootstrapTooltip>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <strong style={{ fontFamily: "Poppins" }}>HD </strong>
                      <BootstrapTooltip title="Hardware Delivered" arrow>
                        <img
                          alt="info"
                          style={{
                            marginLeft: "4px",
                          }}
                          src={Info}
                        ></img>
                      </BootstrapTooltip>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              {districtData && (
                <>
                  {Object.keys(districtData[`s${index + 1}`]).length === 0 ? (
                    <>
                      <Grid item xs={12}>
                        {loading ? (
                          <ShimmerUi />
                        ) : (
                          <div
                            style={{
                              margin: "20px",
                              textAlign: "center",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Data is not available
                          </div>
                        )}
                      </Grid>
                    </>
                  ) : (
                    Object.keys(districtData[`s${index + 1}`]).map(
                      (district, districtIndex) => (
                        <Grid key={districtIndex} item xs={12}>
                          <Item>
                            <Grid container>
                              <Grid
                                item
                                xs={4}
                                style={{ fontFamily: "Poppins" }}
                              >
                                {district.length > 11 ? (
                                  <BootstrapTooltip title={district}>
                                    {district.slice(0, 11) + ".."}
                                  </BootstrapTooltip>
                                ) : (
                                  district
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {
                                  districtData[`s${index + 1}`][district]
                                    ?.goLive
                                }
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {
                                  districtData[`s${index + 1}`][district]
                                    ?.hardwareDelivered
                                }
                              </Grid>
                            </Grid>
                          </Item>
                        </Grid>
                      )
                    )
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              rowSpacing={0.2}
              sx={{
                padding: "6px",
                paddingTop: "10px",
              }}
            >
              <Grid item xs={12}>
                <Item>
                  <Grid container>
                    <Grid item xs={6}>
                      <strong style={{ fontFamily: "Poppins" }}>
                        District Name
                      </strong>
                    </Grid>
                    <Grid item xs={6}>
                      <strong style={{ fontFamily: "Poppins" }}>PACS</strong>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              {districtData && (
                <>
                  {Object.keys(districtData[`s${index + 1}`]).length === 0 ? (
                    <>
                      <>
                        <Grid item xs={12}>
                          {loading ? (
                            <ShimmerUi />
                          ) : (
                            <div
                              style={{
                                margin: "20px",
                                textAlign: "center",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              Data is not available
                            </div>
                          )}
                        </Grid>
                      </>
                    </>
                  ) : (
                    Object.keys(districtData[`s${index + 1}`]).map(
                      (district, districtIndex) => (
                        <Grid key={districtIndex} item xs={12}>
                          <Item>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                style={{ fontFamily: "Poppins" }}
                              >
                                {district.length > 11 ? (
                                  <BootstrapTooltip title={district}>
                                    {district.slice(0, 11) + ".."}
                                  </BootstrapTooltip>
                                ) : (
                                  district
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{ fontFamily: "Poppins" }}
                              >
                                {districtData[`s${index + 1}`][district]?.pacs}
                              </Grid>
                            </Grid>
                          </Item>
                        </Grid>
                      )
                    )
                  )}
                </>
              )}
            </Grid>
          </div>
        </ReactCardFlip>
      ))}
    </div>
  );
};
 
export default Flipcard;
 