import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Bar } from "./Bar.js";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import trophy from "../../Assets/trophy-N.svg";
import silver from "../../Assets/silver-N.svg";
import gold from "../../Assets/gold-N.svg";
import Tb from "../../Assets/topB.svg";

export default function LeaderBoard({ type }) {

  const res = {
    "erpData": {
      "Andaman and Nicobar Islands": 100.0,
      "Arunachal Pradesh": 100.0,
      "Ladakh": 100.0,
      "Sikkim": 100.0,
      "Uttarakhand": 98.97,
      "Assam": 98.97,
      "Jammu and Kashmir": 98.97,
      "Madhya Pradesh": 96.16,
      "Mizoram": 96.16,
      "Himachal Pradesh": 95.4,
      "Jharkhand": 85.0,
      "Puducherry": 82.22,
      "Tripura": 76.49,
      "Bihar": 66.65,
      "Gujarat": 50.9,
      "Rajasthan": 41.19,
      "Maharashtra": 40.97,
      "Chhattisgarh": 37.18,
      "Meghalaya": 36.61,
      "Uttar Pradesh": 27.07,
      "Goa": 20.69,
      "Haryana": 14.93,
      "Nagaland": 14.29,
      "Punjab": 3.56,
      "Tamil Nadu": 1.32,
      "Andhra Pradesh": 0.59,
      "West Bengal": 0.46,
      "Karnataka": 0.24,
      "DNH  DD": 0.0,
      "Manipur": 0.0
    },
    "goLiveData": {
      "Arunachal Pradesh": 100.0,
      "Ladakh": 100.0,
      "Mizoram": 96.0,
      "Assam": 80.1,
      "Sikkim": 75.7,
      "Tripura": 72.01,
      "Jharkhand": 68.87,
      "Uttarakhand": 64.48,
      "Andaman and Nicobar Islands": 50.0,
      "Meghalaya": 36.61,
      "Uttar Pradesh": 18.52,
      "Nagaland": 14.29,
      "Goa": 12.07,
      "Himachal Pradesh": 10.11,
      "Puducherry": 8.89,
      "Maharashtra": 4.19,
      "Haryana": 2.68,
      "Madhya Pradesh": 2.45,
      "Punjab": 2.18,
      "Bihar": 1.25,
      "Rajasthan": 0.97,
      "Chhattisgarh": 0.64,
      "Andhra Pradesh": 0.59,
      "Tamil Nadu": 0.18,
      "Karnataka": 0.11,
      "West Bengal": 0.05,
      "DNH  DD": 0.0,
      "Gujarat": 0.0,
      "Jammu and Kashmir": 0.0,
      "Manipur": 0.0
    },
    "dctData": {
      "Andaman and Nicobar Islands": 100.0,
      "Arunachal Pradesh": 60.0,
      "Jharkhand": 30.0,
      "Ladakh": 100.0,
      "Sikkim": 100.0,
      "Uttarakhand": 100.0,
      "Madhya Pradesh": 99.58,
      "Assam": 99.49,
      "Jammu and Kashmir": 98.88,
      "Himachal Pradesh": 98.16,
      "Mizoram": 96.0,
      "Puducherry": 93.33,
      "Gujarat": 86.72,
      "Tripura": 83.58,
      "Bihar": 74.77,
      "Chhattisgarh": 66.91,
      "Maharashtra": 60.58,
      "Rajasthan": 58.9,
      "Goa": 53.45,
      "Meghalaya": 47.32,
      "Haryana": 36.9,
      "Uttar Pradesh": 28.63,
      "Nagaland": 14.29,
      "Tamil Nadu": 9.31,
      "Punjab": 5.17,
      "West Bengal": 4.2,
      "Andhra Pradesh": 0.59,
      "Karnataka": 0.4,
      "DNH  DD": 0.0,
      "Manipur": 0.0
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 9, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 5,
      slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    const isPreviousDisabled = currentSlide === 0;
    const isNextDisabled = currentSlide === 20;
    { console.log(currentSlide); }

    return (
      <div className="carousel-button-group" style={{ position: 'absolute', top: '8cm', left: '8px', paddingTop: "10px", display: "flex", justifyContent: "space-between", width: "99%" }}>
        <IconButton disabled={currentSlide === 0} onClick={() => previous()} style={{ backgroundColor: '#083C2A', color: 'white', width: "30px", height: "30px", marginRight: "15px", opacity: isPreviousDisabled ? 0.33 : 1 }}>
          <ArrowBackIosNewIcon sx={{ width: "0.8rem" }} />
        </IconButton>
        <IconButton disabled={currentSlide === 20} onClick={() => next()} style={{ backgroundColor: '#083C2A', color: 'white', width: "30px", height: "30px", opacity: isNextDisabled ? 0.33 : 1 }}>
          <ArrowForwardIosIcon sx={{ width: "0.8rem" }} />
        </IconButton>
      </div>
    );
  };
  // Count how many states have the maximum value
  const maxValuesCount = Object.values(type).filter(value => value === Math.max(...Object.values(type))).length;
  return (
    <>
      <style>
        {
          `
      .react-multi-carousel-list{
        padding-left: 27px !important;
        height:50vh;
        align-items: flex-end;
        padding-bottom:50px !important
      }
      .react-multi-carousel-track{
        align-items: flex-end !important;
      }
      .react-multiple-carousel__arrow--left{
        left:1px;
      }
      .react-multiple-carousel__arrow{
        min-width: 25px;
    min-height: 25px;
      }
      .react-multiple-carousel__arrow--right{
        right:1px;
      }
      .react-multi-carousel-item  {
        width:120px !important
      }
      `
        }
      </style>
      <div style={{ width: "97vw", marginTop: "10px", paddingLeft: "20px", paddingRight: "20px" }}>
        <Carousel
          responsive={responsive}
          // autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={false}
          partialVisible={false}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          dotListClass="custom-dot-list-style"
        >


          {Object.entries(type).map(([stateName, value], index) => {
            if (value === Math.max(...Object.values(type))) {
              return (
                <div key={index}>
                  <Bar height={value} statename={stateName} img={trophy} num={90} ml={-73} />
                </div>
              );
            } else if (value === Math.max(...Object.values(type).filter(v => v !== Math.max(...Object.values(type))))) {
              return (
                <div key={index}>
                  <Bar height={value} statename={stateName} img={silver} num={66} ml={-70} />
                </div>
              );
            } else if (value === Math.max(...Object.values(type).filter(v => v !== Math.max(...Object.values(type)) && v !== Math.max(...Object.values(type).filter(v => v !== Math.max(...Object.values(type))))))) {
              return (
                <div key={index}>
                  <Bar height={value} statename={stateName} img={gold} num={67} ml={-73} />
                </div>
              );
            } else {
              return <Bar key={index} height={value} statename={stateName} img={Tb} num={20} ml={-73} />;
            }
          })}

        </Carousel>
      </div>
    </>
  );
}
