import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowForward from "../../Assets/arrow_polygon.svg";
import "./stated.css";
import { useAuth } from "../../Services/Auth";

function ButtonSelector() {
  const { setSelectedBtnSanc, selectedBtnSanc } = useAuth();

  const [selectedButton, setSelectedButton] = useState("Andaman & Nicobar");

  const handleButtonClick = (buttonVal) => {
    if (buttonVal === "Andaman & Nicobar") {
      setSelectedButton(buttonVal);
      setSelectedBtnSanc("Andaman and Nicobar Islands");
    } else if (buttonVal === "Jammu & Kashmir") {
      setSelectedButton(buttonVal);
      setSelectedBtnSanc("Jammu and Kashmir");
    } else {
      setSelectedButton(buttonVal);
      setSelectedBtnSanc(buttonVal);
    }
  };

  const indianStateNames = [
    "Andaman & Nicobar",
    "Andhra Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu & Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  return (
    <div
      style={{
        position: "relative",
        width: "20%",
        backgroundColor: "#EEF4ED",
        padding: "12px",
        // height: "400px",
        borderRadius: "4px",
        overflowX: "hidden",
        overflowY: "auto",
      }}
      className="checkSD"
    >
      <Stack direction="column">
        {indianStateNames.map((buttonIndex) => (
          <Stack
            direction="row"
            key={buttonIndex}
            alignItems="center"
            sx={{
              marginBottom: "8px",
              position: "relative",
              zIndex: selectedButton === buttonIndex ? "1" : "auto",
              // marginLeft: selectedButton !== buttonIndex ? "0px" : "20px",
            }}
          >
            <Button
              onClick={() => handleButtonClick(buttonIndex)}
              sx={{
                minWidth: selectedButton === buttonIndex ? "90%" : "100%",
                justifyContent: "flex-start",
                textTransform: "capitalize",
                backgroundColor:
                  selectedButton === buttonIndex ? "#054B0C" : "transparent",
                boxShadow:
                  selectedButton !== buttonIndex
                    ? "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                    : "none",
                color: selectedButton === buttonIndex ? "#FFFFFF" : "#000000",
                "&:hover": {
                  backgroundColor: "#054B0C",
                  color: "white",
                },
              }}
            >
              {buttonIndex}
            </Button>
            {selectedButton === buttonIndex && (
              <img
                src={ArrowForward}
                alt=""
                style={{ width: "15px", height: "27px", marginLeft: "2px" }}
              />
            )}
          </Stack>
        ))}
      </Stack>
    </div>
  );
}

export default ButtonSelector;
