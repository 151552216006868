import React, { useEffect, useState } from "react";
import SystemIntegration from "../SystemIntegration/SystemIntegration";
import { Box, Typography } from "@mui/material";
import searchIcon from "../../Assets/search 1.svg";
import modules from "./cardpoc.module.css";
import CardStates from "./CardPoc";

const Main28 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className={modules["background-image-container"]}>
        <div className="sub-container">
          <div className="welcome-text1">Welcome to your</div>
          <div className="dashboard-text">Dashboard</div>
          <div className="random-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
            <div>elit, sed do eiusmod tempor</div>
          </div>

          <div className="search-menu">
            <div className="search-container">
              <div className="search-icon-container">
                <img
                  src={searchIcon}
                  alt="Search"
                  className={modules["search-icon"]}
                />
              </div>
              <div className="search-input-container">
                <input
                  type="text"
                  placeholder="Search your PACs/States here"
                  className="search-field"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardStates />
    </>
  );
};

export default Main28;
