import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography, Box, Divider } from "@mui/material";
import PcChild from "./PcChild";
import { getCookie } from "../../Services/Cookies";
import axios from "axios";
export default function Project() {

    const [guidelines, setGuidelines] = useState([]);
    const [totalcost, setTotalCost] = useState([]);
    useEffect(() => {

        var config = {
            method: 'post',
            url: 'https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/component-share',
            headers: {
                email: getCookie("email2"),
                "access-token": getCookie("token"),
                Authorization: `Bearer ${getCookie("token")}`,
            }
        };

        axios(config)
            .then((res) => {
                console.log(res);
                const costPerUnitValues = res?.data?.map(item => item.costPerUnitGuideline);
                const totalCostValues = res?.data?.map(item => item.totalCost);

                setGuidelines(costPerUnitValues);
                setTotalCost(totalCostValues);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])
    console.log(guidelines);
    return (
        <>
            <Stack sx={{ marginTop: "3%", marginBottom: "3%" }}>
                <Typography variant="h6" sx={{ color: "#333333", fontWeight: "600", fontSize: "16px", fontFamily: "Poppins" }}>
                    Project Component
                </Typography>
                <Typography variant="h6" sx={{ color: "#333333", fontWeight: "700", fontSize: "26px", fontFamily: "Poppins" }}>
                    2516 cr
                </Typography>
                <Stack direction="row">
                    <Stack direction="column" padding="10px" paddingLeft="0px">
                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins", textAlign: "end", marginTop: "0px", fontWeight: 600, marginRight:"10px" }}>Hardware</Typography>
                        {/* <Typography>122, 158</Typography>
                        <Typography>Cost per unit guidelines</Typography> */}
                        <PcChild left={false} paddingT={2} align="end" name="Cost per unit guidelines" value={guidelines[0]}/>
                        <PcChild left={false} paddingT={2} align="end" name="Total Cost" value={totalcost[0]}/>

                        <Divider orientation="horizontal" flexItem sx={{
                            width: "119px",
                            marginLeft: "60px",
                            borderWidth: "1.2px"
                        }} />
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{
                        height: "86px",
                        marginTop: "37px",
                        borderWidth: "1.2px"
                    }} />
                    <Stack direction="column" padding="10px" paddingLeft="0px">
                        <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", textAlign: "left", marginTop: "0px", fontWeight: 600,marginLeft:"10px"  }}>Software</Typography>
                        <PcChild left={true} paddingT={2} name="Cost per unit guidelines" value={guidelines[1]}/>
                        <PcChild left={true} paddingT={2} name="Total Cost" value={totalcost[1]}/>
                        <Divider orientation="horizontal" flexItem sx={{
                            width: "119px",
                            marginLeft: "60px",
                            borderWidth: "1.2px"
                        }} />
                    </Stack>

                </Stack>
                <Stack direction="row">
                    <Stack direction="column" padding="10px" paddingLeft="0px">
                        <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", textAlign: "end", marginTop: "0px", fontWeight: 600,marginRight:"10px"  }}>System Integrators</Typography>
                        <PcChild left={false} paddingT={2} align="end" name="Cost per unit guidelines" value={guidelines[2]}/>
                        <PcChild left={false} paddingT={2} align="end" name="Total Cost" value={totalcost[2]}/>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{
                        height: "86px",
                        marginTop: "37px",
                        borderWidth: "1.2px"
                    }} />
                    <Stack direction="column" padding="10px" paddingLeft="0px">
                        <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", textAlign: "left", marginTop: "0px", fontWeight: 600,marginLeft:"10px"  }}>Training & Capacity Building</Typography>
                        <PcChild left={true} paddingT={2} name="Cost per unit guidelines" value={guidelines[3]} />
                        <PcChild left={true} paddingT={2} name="Total Cost" value={totalcost[3]}/>
                    </Stack>

                </Stack>

            </Stack>
        </>
    )
}