import "./si.css";
import "./excelColor.css";
import Header from "../Header/Header";
import searchIcon from "../../Assets/search 1.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import save from "../../Assets/save.svg";
import edit from "../../Assets/edit.svg";
import { createRoutesFromChildren } from "react-router-dom";
import search from "../../Assets/search.svg";
import DownloadComponent from "./DownloadComponent";
import { getCookie } from "../../Services/Cookies";
import { Stack, Typography, Box, Button, radioClasses } from "@mui/material";
import uploadcld from "../../Assets/Icon.png"



const MissionTable = () => {
  const [editableRow, setEditableRow] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [fileType, setFileType] = useState("PDF");
  const rowsPerPageOptions = [10, 20, "all"];
  const fileTypes = ["PDF", "Excel"];
  const [stateData, setStateData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [fileNameLabel, setFileNameLabel] = useState(
    "upload excel files here"
  );
  const [selectedFile, setSelectedFile] = useState(null);

  const [goLiveCount, setGoLiveCount] = useState();
  const [dctCompletedCount, setDctCompletedCount] = useState();
  const [erpTrailRunCount, setErpTrailRunCount] = useState();
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );
  

  // const handleCounter = async () => {
  //   const config = {
  //     headers: {
  //       email: getCookie("email2"), // Get the email from the cookie
  //       "access-token": getCookie("token"), // Get the token from the cookie
  //       Authorization: `Bearer ${getCookie("token")}`, // Use the token for Authorization header
  //     },
  //   };
  //   try {
  //     const response = await axios.post(
  //       "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/aggregatedLatestDataForDEG",{},
  //       config
  //     );
  //     const {
  //       "Go Live Completed": goLiveCountVal,
  //       "DCT Completed": b,
  //       "ERP Trail Run": c,
  //     } = response.data;
  //     console.log("hi:" + goLiveCountVal);
  //     setGoLiveCount(goLiveCountVal);
  //     setDctCompletedCount(b);
  //     setErpTrailRunCount(c);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // handleCounter();
  const opacityValue = getCookie("userRole") === "RO_CLIENT" || getCookie("userRole") === "CLIENT"|| getCookie("userRole") === "HO" ? "0.2" : "1";

  const arrayConverter = (e) => {
    if (e === null || e === undefined) {
      return null;
    }
    const didgitArray = e.toString().padStart(5, "0").split("").map(Number);
    const minLength = 5;
    //  return Array.from({length:minLength},(_,index)=>didgitArray[index]||0);
    return didgitArray;
  };

  const a = arrayConverter(goLiveCount);
  const b = arrayConverter(dctCompletedCount);
  const c = arrayConverter(erpTrailRunCount);

  console.log("hi:" + getCookie("email"));

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileNameLabel(file ? file.name : "Drag or drop Excel sheet here");
  };

  const handleFileUpload = async () => {
    const config = {
      headers: {
        email: getCookie("email2"), 
        "access-token": getCookie("token"), 
        Authorization: `Bearer ${getCookie("token")}`, 
      },
    };
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      await axios.post(
          // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/upload/receive",
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/bulkUploadTargets",
          formData,
          {
          headers: {
            "Content-Type": "multipart/form-data",
            email: getCookie("email2"), 
            "access-token": getCookie("token"), 
            Authorization: `Bearer ${getCookie("token")}`, 
          },
        }
      );

      alert("File uploaded successfully!");
      setSelectedFile(null);
      setFileNameLabel("upload excel files here");
    } catch (error) {
      console.error("File upload failed:", error);
      alert(
        "File upload failed. please upload the appropriate target file or please reach out to the admin for correct file structure."
      );
    }
  };

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        email: getCookie("email2"), 
        "access-token": getCookie("token"), 
        Authorization: `Bearer ${getCookie("token")}`, 
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/mission10000Form",{},
        config
      )
      .then((res) => {
        const dataWithoutKeys = res.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        setStateData(decodedData);
        setEditedValues(decodedData);
        console.log(decodedData);
      });
  }, []);

  const handleNextPage = () => {
    if (
      currentPage !==
      Math.ceil(Object.keys(stateData).length / parseInt(rowsPerPage))
    )
      setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const visibleData = Object.entries(stateData).slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const sortedStateData = Object.entries(stateData).sort((a, b) => a[1].stateId - b[1].stateId);

  const totalRows = sortedStateData.length;
  const totalPages = Math.ceil(totalRows / parseInt(rowsPerPage, 10));
  
  const getPageData = () => {
    if (rowsPerPage === "all") {
      return sortedStateData;
    }
  
    const startIndex = (currentPage - 1) * parseInt(rowsPerPage, 10);
    const endIndex = startIndex + parseInt(rowsPerPage, 10);
    const slicedData = sortedStateData.slice(startIndex, endIndex);
    return slicedData;
  };
  
  const visibleDataFiltered = searchQuery !== "" ? 
  sortedStateData.filter(([state, missionData]) =>
    state.toLowerCase().includes(searchQuery.toLowerCase())
  ) :
  getPageData();
  // const visibleDataFiltered = getPageData().filter(([state, missionData]) =>
  //   state.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  const handleFileTypeChange = (value) => {
    setFileType(value);
  };

  const handleMission10000Edit = (state, e) => {
    if(getCookie("userRole")==="RO_CLIENT" || getCookie("userRole")==="HO" || getCookie("userRole")==="CLIENT"){
      alert("You are not authorized to make edits to those fields.")
      return;
    }
    console.log("edit" + state);
    setEditableRow((prev) => ({
      ...prev,
      [state]: true,
    }));

    console.log(editableRow);
  };

  const getColumnTotal = (columnName) => {
    // Calculate the total for the specified column
    return Object.values(editedValues).reduce(
      (total, missionData) => total + parseInt(missionData[columnName]),
      0
    );
    //   const totalOriginal = Object.values(stateData).reduce((total, missionData) => total + missionData[columnName], 0);
    // const totalEdited = Object.values(editedValues).reduce((total, missionData) => total + parseInt(missionData[columnName] || 0), 0);

    // return  totalOriginal + totalEdited;
  };

  const handleSearchIconClick = () => {
    console.log("Search icon clicked!");
    // Add your desired logic here
  };

  const handleInputChange = (state, name, event) => {
    const { value } = event.target;
    console.log("......." + JSON.stringify(editedValues));
    setEditedValues((prev) => ({
      ...prev,
      [state]: value,
    }));
  };
  const handleMission10000Save = async (state, e) => {
    if(getCookie("userRole")==="RO_CLIENT" || getCookie("userRole")==="HO" || getCookie("userRole")==="CLIENT"){
      alert("You are not authorized to make edits to those fields.")
      return;
    }
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'email': getCookie('email2'), // Get the email from the cookie
    //     'access-token': getCookie('token'), // Get the token from the cookie
    //     'Authorization': `Bearer ${getCookie('token')}` // Use the token for Authorization header
    //   },
    //   data: editedValues[state]
    // };

    try {
      // const response = await axios.post(
      //   `https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/updateMission10000Form?state=${encodeURIComponent(state)}`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'email': getCookie('email2'), // Get the email from the cookie
      //       'access-token': getCookie('token'), // Get the token from the cookie
      //       'Authorization': `Bearer ${getCookie('token')}` // Use the token for Authorization header
      //     },
      //     data: editedValues[state]
      //   }
      // );
      var config = {
        method: "post",
        url: `https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/updateMission10000Form?state=${state}`,
        headers: {
          "access-token": getCookie("token"),
          email: getCookie("email2"),
          Authorization: `Bearer ${getCookie("token")}`,
          "Content-Type": "application/json",
        },
        data: editedValues[state],
      };
      axios(config);

      setEditableRow((prev) => ({
        ...prev,
        [state]: false,
      }));
      console.log(editableRow);
    } catch (error) {
      console.error("Save failed:", error.response.data.errorMessage);
    }
  };

  const onChangeHandler = (e, state) => {
    // debugger;
    const { name, value } = e.target;
    console.log(e.target);
    // const list = { ...editedValues };
    // console.log(list)
    // list[state][name] = value;
    // setEditedValues(list);

    setEditedValues((preEditValues) => {
      // debugger;
      const updatedList = {
        ...preEditValues,
        [state]: { ...preEditValues[state], [name]: value },
      };
      const pacsValue = parseFloat(updatedList[state]?.pacs);

      //1) I = G - J
      if (name === "erpLoginPending") {
        const erpLoginPending = parseFloat(value);
        const preMigrationCompleted = parseFloat(
          updatedList[state]?.preMigrationCompleted
        );
        const erpTrialRun = parseFloat(updatedList[state]?.erpTrialRun);
        if (
          erpLoginPending < 0 ||
          erpLoginPending != preMigrationCompleted - erpTrialRun
        ) {
          alert(
            "entered value should be greater than 0 and should be equal to the difference of premigration completed and erptrailRun"
          );
          return preEditValues;
        }
      }

      // 2) L <= PACS and <= J
      if (name === "goLiveAchieved") {
        const goLiveAchieved = parseFloat(value);
        const erpTrialRun = parseFloat(updatedList[state]?.erpTrialRun);
        if (
          goLiveAchieved > pacsValue ||
          goLiveAchieved < 0 ||
          goLiveAchieved > erpTrialRun
        ) {
          // alert("please enter the value between 0 to " +Math.min(pacsValue,erpTrialRun))
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than erpTrailRun"
          );
          return preEditValues;
        }
      }

      //3)
      if (name === "erpTrialRun") {
        const erpTrialRun = parseFloat(value);
        const preMigrationCompleted = parseFloat(
          updatedList[state]?.preMigrationCompleted
        );
        if (
          erpTrialRun > pacsValue ||
          erpTrialRun < 0 ||
          erpTrialRun > preMigrationCompleted
        ) {
          // alert("please enter the value between 0 to " +Math.min(pacsValue,preMigrationCompleted1))
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than preMigrationCompleted"
          );
          return preEditValues;
        }
      }
      // 4)
      if (name === "preMigrationInProgress") {
        const preMigrationInProgress = parseFloat(value);
        const analyserCompletionWithT8Certification = parseFloat(
          updatedList[state]?.analyserCompletionWithT8Certification
        );
        const preMigrationCompleted = parseFloat(
          updatedList[state]?.preMigrationCompleted
        );
        if (
          preMigrationInProgress > pacsValue ||
          preMigrationInProgress < 0 ||
          preMigrationInProgress >
            analyserCompletionWithT8Certification - preMigrationCompleted
        ) {
          // alert("please enter the value between 0 to " +pacsValue)
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than difference of analyserCompletedWithT8Certification and preMigrationCompleted"
          );
          return preEditValues;
        }
      }

      //5)
      if (name === "analyserCompletionWithT8Certification") {
        const analyserCompletionWithT8Certification = parseFloat(value);
        const dctCompleted = parseFloat(updatedList[state]?.dctCompleted);
        if (
          analyserCompletionWithT8Certification > pacsValue ||
          analyserCompletionWithT8Certification > dctCompleted ||
          analyserCompletionWithT8Certification < 0
        ) {
          // alert("please enter the value between 0 to " + Math.min(pacsValue,dctCompleted))
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than todctCompleted "
          );
          return preEditValues;
        }
      }

      //6)
      if (name === "dctCompleted") {
        const dctCompleted = parseFloat(value);
        const dctLoginIdCreated = parseFloat(
          updatedList[state]?.dctLoginIdCreated
        );
        if (
          dctCompleted > pacsValue ||
          dctCompleted > dctLoginIdCreated ||
          dctCompleted < 0
        ) {
          // alert("please enter the value between 0 to " + Math.min(pacsValue,dctLoginIdCreated))
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than to dctLoginIdCreated "
          );
          return preEditValues;
        }
      }

      // 7)
      if (name === "dctInProgress") {
        const dctInProgress = parseFloat(value);
        const dctLoginIdCreated = parseFloat(
          updatedList[state]?.dctLoginIdCreated
        );
        const dctCompleted = parseFloat(updatedList[state]?.dctCompleted);
        if (
          dctInProgress > pacsValue ||
          dctInProgress < 0 ||
          dctInProgress > dctLoginIdCreated - dctCompleted
        ) {
          // alert("please enter the value between 0 to " +pacsValue)
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than of difference of dctLoginIdcreated and dctComleted"
          );
          return preEditValues;
        }
      }

      //8)
      if (name === "dctLoginIdCreated") {
        const pacsValue = parseFloat(updatedList[state]?.pacs);
        const dctLoginIdCreated = parseFloat(value);
        if (dctLoginIdCreated > pacsValue || dctLoginIdCreated < 0) {
          // alert("please enter the value between 0 to " +pacsValue)
          alert(
            "entered value should be greater than 0 and should not be greater than pacs"
          );
          return preEditValues;
        }
      }

      //9)
      if (name === "preMigrationCompleted") {
        const preMigrationCompleted = parseFloat(value);
        const analyserCompletionWithT8Certification = parseFloat(
          updatedList[state]?.analyserCompletionWithT8Certification
        );
        if (
          preMigrationCompleted > pacsValue ||
          preMigrationCompleted > analyserCompletionWithT8Certification ||
          preMigrationCompleted < 0
        ) {
          // alert("please enter value between 0 to "+Math.min(pacsValue,analyserCompletionWithT8Certification))
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than analyserCompletedWithT8Certification"
          );
          return preEditValues;
        }
      }
      // 10) goLiveAchieved
      if (name === "goLiveAchieved") {
        const goLiveAchieved = parseFloat(value);
        const erpTrialRun = parseFloat(updatedList[state]?.erpTrialRun);
        if (
          goLiveAchieved > pacsValue ||
          goLiveAchieved > erpTrialRun ||
          goLiveAchieved < 0
        ) {
          alert(
            "entered value should be greater than 0 and should not be more than pacs and should not be more than erpTrialRun"
          );
          return preEditValues;
        }
      }

      if (name === "preMigrationCompleted" || name === "erpTrialRun") {
        // Recalculate 'erpLoginPending' based on the new values
        const preMigrationCompleted = parseFloat(
          updatedList[state]?.preMigrationCompleted
        );
        const erpTrialRun = parseFloat(updatedList[state]?.erpTrialRun);
        const erpLoginPending = preMigrationCompleted - erpTrialRun;

        // Update the 'erpLoginPending' value in the editedValues state
        updatedList[state].erpLoginPending = erpLoginPending;
      }

      return updatedList;
    });
  };

  const getFormattedDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd} ${getMonthName(mm)}, ${yyyy}`;
  };

  const getMonthName = (month) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month, 10) - 1];
  };

  return (
    <>
      <Typography 
        sx={{
          fontSize: {xs:"20px", md:"30px"},
          fontWeight: "500",
          lineHeight: "48px",
          letterSpacing: "0.016em",
          textAlign: "left",
          color:"#00261C",
          fontFamily:"Poppins"
        }}
      >
        Daily Progress Report
      </Typography>
      <Stack 
       sx={{
              marginLeft: "0%",
              marginTop: "1%",
              marginRight: "1%",
              backgroundColor: "#FFF",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              position:"relative",
              alignItems:"flex-start",
              overflowX: "hidden",
              borderTopLeftRadius: "6px",  
              borderTopRightRadius: "6px", 
              width:{md:"79vw",sm:"79vw",xs:"79vw"},
              height:{md:"10%",sm:"20%",xs:"27%"},
              paddingBottom:{xs:"10%",sm:"20%",md:"10%"},
              overflow:"hidden",
              '@media (min-width:900px) and (max-width:1228px)': {
                paddingBottom:"25%"
               },
              '@media (min-width:769px) and (max-width:899px)': {
               paddingBottom:"60%",
              },
              '@media (min-width:391px) and (max-width:412px)': {
                paddingBottom:"50%",
              },
              '@media (min-width:600px) and (max-width:769px)': {
                paddingBottom:"95%",
              },
            }}>
        <Stack style={{ margin: "32px",marginTop:"1.5%" }} className="sub-container">
          <Stack direction="column">
            <Stack direction={{md:"row",sm:"column",xs:"column"}} spacing={1}>
              <Stack direction={{md:"row",sm:"column",xs:"column"}} spacing={1} className="mission-10k-text" 
              sx={{
                paddingBottom:{xs:"10%",sm:"10%",md:"0%"}
                }}>
                <Stack direction="row" className="searchbar-div" 
                sx={{
                  marginLeft:{md:"-3.35% !important",sm:"-1.5% !important",xs:"-3.35% !important"},
                  marginTop:{md:"-0.5% !important",xs:"-8% !important"},
                  '@media (min-width:769px) and (max-width:899px)': {
                    marginLeft: "-15% !important",
                    marginTop: "-4% !important",
                  },
                  '@media (min-width:391px) and (max-width:412px)': {
                    marginLeft: "-15% !important",
                    marginTop: "-4% !important",
                  },
                  }}>
                <input
                    style={{
                      width: "130px",
                      height: "30px",
                      borderRadius: "7.69px",
                      border: "0.96px solid #D0D5DD",
                      marginTop: "11.5%",
                      marginLeft: "0%",
                      fontFamily:"Poppins",
                      fontSize:"12px"
                    }}
                    type="text"
                    placeholder="search by states"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <img
                    src={searchIcon}
                    style={{ marginTop: "7%" }}
                    alt="Search Icon"
                    className="search-icon"
                    onClick={handleSearchIconClick}
                  />
                </Stack>

                <Stack
                  direction={{md:"row",sm:"column",xs:"column"}}
                  className="Uploader"
                  spacing={0}
                  sx={{ paddingLeft: "2px",marginTop:{md:"-1%"} }}
                >
                  <Typography
                    sx={{
                      fontSize: {md:"13px",xs:"15px"},
                      width: {md:"85px",xs:"100px"},
                      marginTop: {md:"4.2%",xs:"2%"},
                      marginLeft: {md:"1%",sm:"-1.6%",xs:"-3.5%"},
                      fontFamily:"Poppins",
                      '@media (min-width:769px) and (max-width:899px)': {
                        marginLeft: "-15% !important",
                        marginTop: "5% !important",
                        marginBottom:"15% !important"
                      },
                      '@media (min-width:391px) and (max-width:412px)': {
                        marginLeft: "-15% !important",
                        marginTop: "4% !important",
                        marginBottom:"15% !important"
                      },
                    }}
                  >
                    Upload files
                  </Typography>
                  <Stack
                    direction={{md:"row",xs:"column",sm:"column"}}
                    spacing={1}
                    sx={{
                       marginTop: {md:"2.5%",xs:"1.5%"}, 
                       marginLeft: {md:"-4%",sm:"-6%",xs:"-8%"} ,
                       '@media (min-width:769px) and (max-width:899px)': {
                        marginLeft: "-25% !important",
                        marginTop: "-4% !important",
                      },
                      '@media (min-width:391px) and (max-width:412px)': {
                        marginLeft: "-17% !important",
                        marginTop: "4% !important",
                      },
                    }}
                  >
                    <input
                      type="file"
                      accept=".xlsx"
                      onChange={handleFileChange}
                      style={{
                        display: "none",
                      }}
                      id="fileInput"
                    />
                      <label
                        htmlFor="fileInput"
                        className="upload-button"
                        style={{
                          color: "#A4A4A4",
                          fontFamily: "Open Sans",
                          fontSize: "12px",
                          fontWeight: "100",
                          display: "inline-block",
                          cursor: "pointer",
                          textAlign: "center",
                          marginLeft: "4%", 
                          marginTop: "1%", 
                          width: "200px",
                          height: "30px",
                          borderRadius: "7.69px",
                          border: "0.96px solid #D0D5DD",
                          padding: "0px 0px 0px 0px",
                          lineHeight: "30px",
                          fontFamily:"Poppins"
                        }}
                      >
                        {fileNameLabel}
                      </label>
                      <Stack sx={{
                      width:{md:"100px",sm:"100px",xs:"100px"},
                      marginLeft:{md:"0% !important",xs:"3.5% !important"},
                      marginTop:{md:"0% !important",sm:"20px !important",xs:"2% !important"},
                      '@media (min-width:391px) and (max-width:412px)': {
                        marginLeft:"1% !important",
                        marginTop: "10% !important",
                      },
                      }}>
                    <button
                              style={{
                                width: "100%",
                                height: "32.23px",
                                padding: "8px 15.39px 9.62px 25%",
                                borderRadius: "7.69px",
                                background: "#065036",
                                color: "white",
                                marginLeft: "2.4%",
                                marginTop: "0.5%",
                                marginBottom: "5%",
                                cursor: "pointer",
                                border: "0.96px solid #D0D5DD",
                                backgroundImage: `url(${uploadcld})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "12% center",
                                transition: "transform 0.3s ease, background-color 0.3s ease", 
                                gap: "7.69px",
                                fontFamily:"Poppins"
                              }}
                              onMouseOver={(e) => {
                                e.target.style.backgroundColor = "#333"; 
                                e.target.style.transform = "scale(0.95)"; 
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundColor = "#065036";
                                e.target.style.transform = "scale(1)"; 
                              }}
                      onClick={handleFileUpload}
                    >
                      Upload
                    </button>
                    </Stack>
                    {/* <label className="rectangle-text" style={{marginTop:"180px",marginLeft:"-95%"}}>Inbid</label> */}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={{md:"row",sm:"column",xs:"column"}}
                className="dropdown-div"
                alignContent="end"
                sx={{ 
                  paddingLeft: {md:"4.5%",sm:"3.4%",xs:"3.5%"},
                  marginLeft:{xs:"-100%"}, 
                  paddingBottom: {sm:"0%",md:"0%",xs:"0%"} ,
                  '@media (min-width:769px) and (max-width:899px)': {
                    marginLeft: "15% !important",
                    marginTop: "22% !important",
                  },
                  '@media (min-width:900px) and (max-width:1228px)': {
                    marginLeft: "-55.5% !important",
                    marginTop: "10% !important",
                  },
                  '@media (min-width:391px) and (max-width:412px)': {
                    marginLeft:"17% !important",
                    marginTop: "22% !important",
                  },
                }}
              >
                <DownloadComponent />
                <Stack direction={{md:"row",sm:"column",xs:"column"}} sx={{
                  marginLeft:{md:"4%",sm:"-100%",xs:"-97%"},
                  marginTop:{md:"4%",xs:"2%"},
                  paddingBottom:{sm:"20%",xs:"10%",md:"0%"},
                  '@media (min-width:769px) and (max-width:899px)': {
                    marginLeft: "-100% !important",
                    marginTop: "18% !important",
                  },
                  '@media (min-width:900px) and (max-width:1228px)': {
                    marginLeft: "10% !important",
                  },
                  '@media (min-width:391px) and (max-width:412px)': {
                    marginLeft:"-170% !important",
                    marginTop: "22% !important",
                  },
                  }}>
                  <Typography sx={{ marginTop: "5%", marginRight: "2%",fontSize:{md:"13px",xs:"16px"},fontFamily:"Poppins",marginLeft:{md:"1%",xs:"1%"}}}>
                    Show
                  </Typography>
                  <select
                    className="dropdown-m10k"
                    onChange={(e) => handleRowsPerPageChange(e.target.value)}
                    style={{height:"33px"}}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <option key={option} value={option} >
                        {option}
                      </option>
                    ))}
                  </select>
                  <Typography sx={{ marginTop: "5%", marginLeft: {md:"2%",xs:"2.5%"},fontSize:{md:"13px",xs:"16px"},fontFamily:"Poppins"}}>
                    {" "}
                    entries
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          </Stack>
          </Stack>
          <Stack 
            sx={{
                    marginLeft:"0%",
                    backgroundColor: "#FFF",
                    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
                    position:{md:"absolute",sm:"relative",sx:"relative"},
                    alignItems:"flex-start",
                    marginBottom: "2%",
                    overflowX: "hidden",
                    width:"79vw",
                    borderBottomLeftRadius:"6px",
                    borderBottomRightRadius:"6px",
                    borderTop: "1.5px solid #D0D5DD80",   
            }}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th className="thead2 sticky-first-column fixed-width"></th>
                  <th className="thead2 sticky-second-column fixed-width"></th>
                  <th className="thead2"></th>
                  <th className="thead2 dct-target">A</th>
                  <th className="thead2 dct-target1">B</th>
                  <th className="thead2 dct-target1">C</th>
                  <th className="thead2 dct-target1">D</th>
                  <th className="thead2 dct-target1">E</th>
                  <th className="thead2 pre-mig1">F</th>
                  <th className="thead2 pre-mig1">G</th>
                  <th className="thead2 erp-trial1">H</th>
                  <th className="thead2 erp-trial1">I</th>
                  <th className="thead2 erp-trial1">J</th>
                  <th className="thead2 go-live1">K</th>
                  <th className="thead2 go-live1">L</th>
                  <th className="thead2 hardware-status1" colSpan="3">
                    Hardware Status
                  </th>
                  <th className="thead2 fixed-last-column"></th>
                </tr>
                <tr>
                  <th className="thead2 sticky-first-column fixed-width">
                    Sr.
                  </th>
                  <th className="thead2 sticky-second-column fixed-width">
                    State
                  </th>
                  <th className="thead2">PACS</th>
                  <th className="thead2 dct-target">DCT Target</th>
                  <th className="thead2 dct-target2">DCT Login id created</th>
                  <th className="thead2 dct-target2">DCT In Progress</th>
                  <th className="thead2 dct-target2">DCT Completed</th>
                  <th className="thead2 dct-target2">
                    Analyser Completion with T8 certification(of D)
                  </th>
                  <th className="thead2 pre-mig2">
                    Pre Migration In Progress (of E)
                  </th>
                  <th className="thead2 pre-mig2">
                    Pre Migration Completed (of E)
                  </th>
                  <th className="thead2 erp-trial2">ERP Onboarded Target </th>
                  <th className="thead2 erp-trial2">
                    ERP Login Pending (of G)
                  </th>
                  <th className="thead2 erp-trial2">ERP Onboarded (of G)</th>
                  <th className="thead2 go-live2">Go Live Target</th>
                  <th className="thead2 go-live2">Go-Live Achieved (of J)</th>
                  <th className="thead2 hardware-status2">
                    Number of PACS for which work order is issued
                  </th>
                  <th className="thead2 hardware-status2">
                    Number of PACS where Hardware is delivered
                  </th>
                  <th className="thead2 hardware-status2">
                    Number of PACS where Hardware is installed
                  </th>
                  <th className="thead2 fixed-last-column">Action</th>
                </tr>
              </thead>
              <tbody>
                {visibleDataFiltered.map(([state, missionData]) => (
                  <tr key={state}>
                    <td className="tdata2 sticky-first-column fixed-width">
                      {missionData?.stateId}
                    </td>
                    <td
                      className="tdata2 sticky-second-column fixed-width"
                      style={{ textAlign: "left" }}

                    >
                      {state}
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="pacs"
                        value={editedValues[state]?.pacs}
                        onChange={(e) => onChangeHandler(e, state)}
                        autoFocus
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={true}
                        type="number"
                        name="dctTarget"
                        value={editedValues[state]?.dctTarget}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="dctLoginIdCreated"
                        // value={missionData?.dctLoginIdCreated}
                        value={editedValues[state]?.dctLoginIdCreated}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>

                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="dctInProgress"
                        value={editedValues[state]?.dctInProgress}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2 total-col-color">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="dctCompleted"
                        value={editedValues[state]?.dctCompleted}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="analyserCompletionWithT8Certification"
                        value={
                          editedValues[state]
                            ?.analyserCompletionWithT8Certification
                        }
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="preMigrationInProgress"
                        value={editedValues[state]?.preMigrationInProgress}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="preMigrationCompleted"
                        value={editedValues[state]?.preMigrationCompleted}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={true}
                        type="number"
                        name="erpTrialRunTarget"
                        value={editedValues[state]?.erpTrialRunTarget}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={true}
                        type="number"
                        name="erpLoginPending"
                        value={editedValues[state]?.erpLoginPending}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2 total-col-color">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="erpTrialRun"
                        value={editedValues[state]?.erpTrialRun}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={true}
                        type="number"
                        name="goLiveTarget"
                        value={editedValues[state]?.goLiveTarget}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2 total-col-color">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="goLiveAchieved"
                        value={editedValues[state]?.goLiveAchieved}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="numberOfPacsWorkOrderIssued"
                        value={editedValues[state]?.numberOfPacsWorkOrderIssued}
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="numberOfPacsHardwareDelivered"
                        value={
                          editedValues[state]?.numberOfPacsHardwareDelivered
                        }
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td className="tdata2">
                      <input
                        disabled={!editableRow[state]}
                        type="number"
                        name="numberOfPacsHardwareInstalled"
                        value={
                          editedValues[state]?.numberOfPacsHardwareInstalled
                        }
                        onChange={(e) => onChangeHandler(e, state)}
                      />
                    </td>
                    <td
                      className="tdata2 fixed-last-column"
                      style={{ display: "flex", boxSizing: "content-box",height:"9.5vh"}}
                    >
                      {!editableRow[state] ? (
                        <>
                          <img
                            onClick={(e) => handleMission10000Edit(state, e)}
                            src={edit}
                            alt="Edit"
                            style={{
                              marginRight: "5px",
                              cursor: "pointer",
                              width: "25px",
                              padding: "5px",
                              opacity:opacityValue
                            }}
                          />
                          <img
                            // onClick={(e) => handleMission10000Save(state, e)}
                            src={save}
                            alt="Save"
                            style={{
                              marginRight: "5px",
                              cursor: "context-menu",
                              width: "25px",
                              padding: "5px",
                              opacity: "0.2",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            // onClick={(e) => handleMission10000Edit(state, e)}
                            src={edit}
                            alt="Edit"
                            style={{
                              marginRight: "5px",
                              cursor: "pointer",
                              width: "25px",
                              padding: "2px",
                              opacity: "0.2",
                            }}
                          />
                          <img
                            onClick={(e) => handleMission10000Save(state, e)}
                            src={save}
                            alt="Save"
                            style={{
                              marginRight: "5px",
                              cursor: "pointer",
                              width: "25px",
                              padding: "5px",
                              opacity:opacityValue
                            }}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                <tr style={{ background: "#DFECF7" }}>
                  <td
                    className="tdata2 sticky-first-column fixed-width"
                    style={{ border: "solid 0px" }}
                  ></td>
                  <td
                    className="tdata2 sticky-second-column fixed-width"
                    style={{ fontWeight: "bold" }}
                  >
                    Total
                  </td>
                  <td className="tdata2">{getColumnTotal("pacs")}</td>
                  <td className="tdata2">{getColumnTotal("dctTarget")}</td>
                  <td className="tdata2">
                    {getColumnTotal("dctLoginIdCreated")}
                  </td>
                  <td className="tdata2">{getColumnTotal("dctInProgress")}</td>
                  <td className="tdata2">{getColumnTotal("dctCompleted")}</td>
                  <td className="tdata2">
                    {getColumnTotal("analyserCompletionWithT8Certification")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("preMigrationInProgress")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("preMigrationCompleted")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("erpTrialRunTarget")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("erpLoginPending")}
                  </td>
                  <td className="tdata2">{getColumnTotal("erpTrialRun")}</td>
                  <td className="tdata2">{getColumnTotal("goLiveTarget")}</td>
                  <td className="tdata2">{getColumnTotal("goLiveAchieved")}</td>
                  <td className="tdata2">
                    {getColumnTotal("numberOfPacsWorkOrderIssued")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("numberOfPacsHardwareDelivered")}
                  </td>
                  <td className="tdata2">
                    {getColumnTotal("numberOfPacsHardwareInstalled")}
                  </td>
                  <td className="tdata2 fixed-last-column"></td>
                </tr>
              </tbody>
            </table>
          </div>
          {rowsPerPage !== "all" && (
        <Stack direction="row" className="pagination-container" sx={{marginLeft:{md:"70%",xs:"3.2%"},marginTop:{md:"1%",xs:"2%"}}}>
            <button
              style={{
              width: "70px",
              height: "30px",
              borderRadius: "7.69px",
              background: "#065036",
               color: "white",
               marginLeft: "2.4%",
               marginTop: "0.5%",
               marginBottom: "5%",
               cursor: "pointer",
               border: "0.96px solid #D0D5DD",
               transition: "transform 0.3s ease, background-color 0.3s ease", 
             }}
                onMouseOver={(e) => {
                e.target.style.backgroundColor = "#333"; 
                e.target.style.transform = "scale(0.95)"; 
             }}
                onMouseOut={(e) => {
                e.target.style.backgroundColor = "#065036";
                e.target.style.transform = "scale(1)"; 
             }}
           onClick={handlePrevPage}
            >
            Previous
          </button>
          {Array.from(
            {
              length: Math.ceil(
                Object.keys(stateData).length / parseInt(rowsPerPage)
              ),
            },
            (_, i) => i + 1
          ).map((page) => (
            <div
              key={page}
              className={`page-button ${page === currentPage ? "active" : ""}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </div>
          ))}
           <button
              style={{
              width: "60px",
              height: "30px",
              borderRadius: "7.69px",
              background: "#065036",
               color: "white",
               marginLeft: "2.4%",
               marginTop: "0.5%",
               marginBottom: "5%",
               cursor: "pointer",
               border: "0.96px solid #D0D5DD",
               transition: "transform 0.3s ease, background-color 0.3s ease", 
             }}
                onMouseOver={(e) => {
                e.target.style.backgroundColor = "#333"; 
                e.target.style.transform = "scale(0.95)"; 
             }}
                onMouseOut={(e) => {
                e.target.style.backgroundColor = "#065036";
                e.target.style.transform = "scale(1)"; 
             }}
           onClick={handleNextPage}
            >
            Next
          </button>
        </Stack>
      )}
      </Stack>
    </>
  );
};

export default MissionTable;
