import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import credit from "../../Assets/credit-card1.png";
import down from "../../Assets/down1.svg";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useEffect, useState } from "react";
import Graph from "../BuisnessIntelligence/graph";
import Graph2 from "../BuisnessIntelligence/newgraph";
import { useAuth } from "../../Services/Auth";
import "./bi.css";

export default function Bi() {
  const [statedata, setStateData] = useState();
  const [districtData, setDistrictData] = useState();
  const [meow, setMeow] = useState("");
  const { state, setState, district, setDistrict } = useAuth();
  const [KccData, setKccData] = useState([]);
  const [Change, setChange] = useState(0);

  const handleSubmit = async () => {
    try {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };

      let body = {};

      if (state && district) {
        body = {
          state: state,
          district: district,
        };
      } else if (state) {
        body = {
          state: state,
        };
      }

      const response = await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/yoy-kcc-card-holders",
        body,
        config
      );
      setKccData(response.data);
      console.log(
        "state:" +
          state +
          ", district:" +
          district +
          ", body:" +
          JSON.stringify(body)
      );
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handlestatecall = async () => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    try {
      const response = await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/fetch-states-bo",
        {},
        config
      );
      const stateNames = response.data;
      setStateData(stateNames);
      console.log("meow a:" + JSON.stringify(statedata));
    } catch (error) {
      console.log("state call failed");
    }
  };

  const handleDistrict = async (e) => {
    setMeow("");
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    setState(e);
    try {
      const response = await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/fetch-districts-bo",
        {
          stateName: e,
        },
        config
      );
      setDistrictData(response.data);
      console.log("namaste:" + response.data);
    } catch (error) {
      alert("Call failed");
    }
  };

  const districtSet = async (e) => {
    setMeow("meow");
    setDistrict(e);
  };

  useEffect(() => {
    const fetchData = async () => {
      await handlestatecall();
      await handleSubmit();
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      const handleSubmit = async () => {
        try {
          const config = {
            headers: {
              email: getCookie("email2"),
              "access-token": getCookie("token"),
              Authorization: `Bearer ${getCookie("token")}`,
            },
          };
    
          let body = {};
    
          if (state && district) {
            body = {
              state: state,
              district: district,
            };
          } else if (state) {
            body = {
              state: state,
            };
          }
    
          const response = await axios.post(
            "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/yoy-kcc-card-holders",
            body,
            config
          );
          setKccData(response.data);
          console.log(
            "state:" +
              state +
              ", district:" +
              district +
              ", body:" +
              JSON.stringify(body)
          );
        } catch (error) {
          console.log("Error:", error);
        }
      };
      handleSubmit();
    }, 1000);
  }, [state, district]);
  
  useEffect(() => {
    setTimeout(() => {
      const oldData = KccData[0].kccIssued;
      const newData = KccData[1].kccIssued;
      const ChangePercentage = ((newData - oldData) / oldData) * 100;
      setChange(ChangePercentage.toFixed(2));
    }, 1500);
  }, [KccData]);
  

  return (
    <>
      <Stack direction="column">
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "30px" },
            fontWeight: "500",
            lineHeight: "48px",
            letterSpacing: "0.016em",
            textAlign: "left",
            color: "#00261C",
            fontFamily: "Poppins",
          }}
        >
          Business Intelligence Dashboard
        </Typography>
        <Stack direction="row" sx={{ marginLeft: "49%" }} spacing={2}>
          <Stack direction={{ lg: "row", md: "column", xs: "column" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                width: "100px",
                marginTop: { lg: "2%", md: "1%", xs: "0%" },
                paddingRight: "1%",
                fontFamily: "Poppins",
              }}
            >
              Select State
            </Typography>
            <Stack
              sx={{
                marginTop: { xs: "6%", md: "5%", lg: "1%" },
                width: { md: "140px", xs: "240px" },
              }}
            >
              <select
                id="select"
                style={{
                  marginTop: "-4%",
                  width: "100%",
                  height: "38px",
                  padding: "9px 14px 9px 17px",
                  borderRadius: "7.69px",
                  border: "0.96px solid #D0D5DD",
                  backgroundImage: `url(${down})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97%",
                  color: "grey",
                  appearance: "none",
                  gap: "7.69px",
                  fontFamily: "Poppins",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onChange={(e) => handleDistrict(e.target.value)}
              >
                <option
                  value=""
                  disabled
                  selected
                  style={{ fontFamily: "Poppins" }}
                >
                  Select State
                </option>
                {statedata &&
                  statedata.map((state, index) => (
                    <option
                      key={index}
                      value={state}
                      style={{ fontFamily: "Poppins" }}
                    >
                      {state}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
          <Stack direction={{ lg: "row", md: "column", xs: "column" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "600",
                width: "110px",
                marginTop: { lg: "2%", md: "-1%", xs: "0%" },
                fontFamily: "Poppins",
              }}
            >
              Select District
            </Typography>
            <Stack
              sx={{
                marginTop: { xs: "6%", md: "5%", lg: "1%" },
                width: { md: "140px", xs: "240px" },
              }}
            >
              <select
                id="select"
                style={{
                  marginTop: "-4%",
                  width: "100%",
                  height: "38px",
                  padding: "9px 14px 9px 17px",
                  borderRadius: "7.69px",
                  border: "0.96px solid #D0D5DD",
                  backgroundImage: `url(${down})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97%",
                  color: "grey",
                  appearance: "none",
                  gap: "7.69px",
                  fontFamily: "Poppins",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onChange={(e) => districtSet(e.target.value)}
                value={meow !== "" ? district : ""}
              >
                <option
                  value=""
                  disabled
                  selected
                  style={{ fontFamily: "Poppins" }}
                >
                  Select District
                </option>
                {districtData &&
                  districtData.map((state, index) => (
                    <option
                      key={index}
                      value={state}
                      style={{ fontFamily: "Poppins" }}
                    >
                      {state}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={{md:"row", xs:"column"}} spacing={2} sx={{ marginTop: "1%", width:"100" }}>
        <Stack
          sx={{
            background: "#EEF4ED",
            borderRadius: "6px",
            marginTop: "2%",
            width:"100%",
          }}
          className="stackContBI"
        >
          <Typography
            sx={{
              color: "#333333",
              paddingLeft: "2%",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "54px",
              textAlign: "left",
            }}
          >
            Membership
          </Typography>
          <Graph />
        </Stack>
        <Stack
          sx={{
            background: "#EEF4ED",
            borderRadius: "6px",
            marginTop: "2%",
            width:"100%"
          }}
        >
          <Stack sx={{ marginLeft: "2%" }}>
            <Typography
              sx={{
                color: "#333333",
                paddingLeft: "2%",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "54px",
                textAlign: "left",
              }}
            >
              KCC Card Holders
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                marginTop: "5%",
                justifyContent: "center",
                paddingBottom: "5%",
              }}
            >
              <Stack
                sx={{
                  width: "auto",
                  height: "auto",
                  position: "relative",
                  borderWidth: "0px",
                  borderStyle: "solid",
                  borderColor: "#83AA5E63",
                  background: "white",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "20px",
                  overflow: "hidden",
                }}
              >
                <Stack sx={{ marginTop: "-14%" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    FY22
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "350",
                      color: "grey",
                      marginBottom: "10px",
                    }}
                  >
                    PREVIOUS YEAR
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ marginTop: "15%", width: "100%", overflowX: "auto" }}
                  >
                    <Stack
                      sx={{
                        width: "40%",
                        height: "55px",
                        background: "#EEF4ED",
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={credit}
                        alt="Credit Card"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          height: "auto",
                        }}
                      />
                    </Stack>
                    <Stack>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "10px",
                        }}
                      >
                        KCC Holders
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "800",
                          fontSize: "22px",
                          color: "#065036",
                        }}
                      >
                        {KccData.length > 0 && KccData[0].kccIssued}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "auto",
                  height: "auto",
                  position: "relative",
                  borderWidth: "0px",
                  borderStyle: "solid",
                  borderColor: "#83AA5E63",
                  background: "white",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "20px",
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  FY23
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "350",
                    color: "grey",
                    marginBottom: "10px",
                  }}
                >
                  CURRENT YEAR
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ marginTop: "15%", width: "100%", overflowX: "auto" }}
                >
                  <Stack
                    sx={{
                      width: "40%",
                      height: "55px",
                      background: "#EEF4ED",
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={credit}
                      alt="Credit Card"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "auto",
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "10px",
                      }}
                    >
                      KCC Holders
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "800",
                        fontSize: "22px",
                        color: "#065036",
                      }}
                    >
                      {KccData.length > 0 && KccData[1].kccIssued}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ marginLeft: "50%", marginTop: "0%" }}>
                  {Change !== null && (
                    <Typography
                      sx={{
                        fontSize: "10 px",
                        color: Change < 0 ? "red" : "#E47D05",
                        fontWeight: "bold",
                        position: "relative",
                      }}
                    >
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {Change < 0 ? <b>↓</b> : <b>↑</b>}
                      </span>
                      {Change}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{ background: "#EEF4ED", borderRadius: "6px", marginTop: "2%" }}
        className="stackContBI"
      >
        <Typography
          sx={{
            color: "#333333",
            paddingLeft: "2%",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "54px",
            textAlign: "left",
          }}
        >
          Financials
        </Typography>
        <Graph2 />
      </Stack>
    </>
  );
}
