import React from "react";
import ReactApexChart from "react-apexcharts";

const Donut = ({ stateSharePercentage = 0, goiSharePercentage = 0 }) => {

  const series = [stateSharePercentage, goiSharePercentage];

  const options = {
    labels: ["State Share", "GOI Share"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        radius: 4,
      },
      // itemMargin: {
      //   horizontal: -5,
      // },
    },
    stroke: {
      show: false,
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          background: "#EEF4ED",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: 500,
              color: "#737373",
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: 700,
              color: "black",
              offsetY: 10,
              formatter: function (val) {
                return val + "%";
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#14B8A6", "#FC5046"],
  };

  return (
    <ReactApexChart options={options} series={series} height="180" type="donut" />
  );
};

export default Donut;
