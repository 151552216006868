import React, { useState, useEffect, useRef } from "react";
import { TweenMax, Power3 } from "gsap";
import { Box, Stack, Typography } from "@mui/material";
import "./newnew2.css";

const Counter2 = ({ counterValue }) => {
  const [isComplete, setIsComplete] = useState(0);
  const [total, setTotal] = useState(0);
  const counterRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false); // Track whether animation has been triggered

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, 
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !hasAnimated) {
        setHasAnimated(true);
        initCounter();
        observer.unobserve(entry.target); // Remove observer after animation is triggered
      }
    });
  };

  useEffect(() => {
    TweenMax.set('.flip', {
      perspective: 150
    });
    setTimeout(initCounter, 1000);

    return () => {
      document.querySelectorAll('button').forEach(button => button.removeEventListener('click', initCounter));
    }
  }, []);

  const initCounter = () => {
    const wraps = document.querySelectorAll('.wrap2-counter .wrap2');
    const totalCounter = wraps.length;
    setTotal(totalCounter);

    wraps.forEach((wrap, i) => {
      const loop = parseInt(wrap.getAttribute('data-loop'));
      wrap.setAttribute('data-cur', 0);
      setIsComplete(0);

      setTimeout(() => {
        animate(wrap, counterValue.toString()[i], loop);
      }, i * 100);
    });

    document.querySelectorAll('button').forEach(button => button.addEventListener('click', initCounter));
  };

  const animate = (wrap, value, loop) => {
    const dur = parseFloat(wrap.getAttribute('data-dur'));
    let current = parseInt(wrap.getAttribute('data-cur'));
    let next = parseInt(wrap.getAttribute('data-next'));
    wrap.querySelector('.top .number').textContent = next;

    TweenMax.fromTo(wrap.querySelector('.flip'), dur, {
      rotationX: 0,
      backgroundColor: '#FFF'
    }, {
      rotationX: -90,
      backgroundColor: '#FFF',
      ease: Power3.easeIn,
      onComplete: () => {
        wrap.querySelector('.flip').classList.remove('in');
        wrap.querySelector('.flip').classList.add('out');
        wrap.querySelector('.flip .number').textContent = next;
        TweenMax.fromTo(wrap.querySelector('.flip'), dur, {
          rotationX: -90,
          backgroundColor: '#FFF'
        }, {
          rotationX: -180,
          backgroundColor: '#FFF',
          ease: Power3.easeOut,
          onComplete: () => {
            TweenMax.set(wrap.querySelector('.flip'), {
              rotationX: 0,
              backgroundColor: '#FFF'
            });
            wrap.querySelector('.bottom .number').textContent = next;
            wrap.querySelector('.flip').classList.remove('out');
            wrap.querySelector('.flip').classList.add('in');
            if (current === 0) {
              loop--;
            }
            if (next < value || loop >= 0) {
              current++;
              current = current > 9 ? 0 : current;
              next++;
              next = next > 9 ? 0 : next;
              wrap.setAttribute('data-cur', current);
              wrap.setAttribute('data-next', next);
              animate(wrap, value, loop);
            } else {
              setIsComplete(isComplete + 1);
            }
            if (isComplete >= total) {
                if(document.querySelector('.wrap2-counter')){
                  const newValue=Math.floor(Math.random() * 9000) + 1000;
                  document.querySelector('.wrap2-counter').setAttribute('data-value', newValue);
                document.querySelectorAll('.wrap2-counter .wrap2').forEach(wrap => {
                  wrap.setAttribute('data-next', 0);
                });
                document.querySelectorAll('button').forEach(button => button.addEventListener('click', initCounter));
              }
            }
          }
        });
      }
    });
  };

  const renderBoxes = () => {
    const counterString = counterValue.toString();
    const paddedValue = counterString;
    const digits = paddedValue.split("").map(Number);

    return digits.map((digit, index) => (
      <Box
        key={`digit-${index}`}
        className="wrap2"
        data-loop="1"
        data-dur="0.3"
        data-cur="1"
        data-next={digit}
      >
        <Box className="top wrap2-num">
          <Typography variant="body1" className="number">
            {digit}
          </Typography>
        </Box>
        <Box className="flip wrap2-num in">
          <Typography variant="body1" className="number">
            {digit}
          </Typography>
        </Box>
        <Box className="bottom wrap2-num">
          <Typography variant="body1" className="number">
            {digit}
          </Typography>
        </Box>
      </Box>
    ));
  };
  
  return (
    <>
      <Stack direction="column" spacing={2} alignItems="center">
        <Box
          className="wrap2-counter"
          data-value={counterValue}
          ref={counterRef} 
        >
          <Stack direction="row" spacing={2} justifyContent="center">
            {renderBoxes(counterValue)}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default Counter2;
