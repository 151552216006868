import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import modules from './cardpoc.module.css';
import axios from 'axios';
import { getCookie } from '../../Services/Cookies';

const Linechart = ({octT, novT, decT, octG, novG, decG, octD, novD, decD, currentstate, secondchart}) => {
  const [trendsRes, setTrendsRes] = useState(
    {
    octTrail: 0,
    octGolive: 0,
    octDct: 0,

    novTrail: 0,
    novGolive: 0,
    novDct: 0,

    decTrail: 0,
    decGolive: 0,
    decDct: 0,
})
const[keep, setKeep]=useState();

  const[prestate, setPrestate]=useState("");
  const seriesData = [
    // { name: 'Trail Run', data: [0, 40, 45] },
    // { name: 'Go-Live', data: [15, 20, 25] },
    // { name: 'HD', data: [45, 20 , 30] },
    // { name: 'Trail Run', data: [octT, novT, decT] },
    // { name: 'Go-Live', data: [octG, novG, decG] },
    // { name: 'DcT Completed', data: [octD, novD, decD] },
    { 
      name: 'Trail Run', 
      data: [trendsRes.octTrail, trendsRes.novTrail, trendsRes.decTrail] 
    },
    { 
      name: 'Go-Live', 
      data: [trendsRes.octGolive, trendsRes.novGolive, trendsRes.decGolive] 
    },
    { 
      name: 'DcT Completed', 
      data: [trendsRes.octDct, trendsRes.novDct, trendsRes.decDct] 
    },
  ];

  const options = {
    chart: {
      type: 'area',
      height: 250,
      // stacked: true,
      events: {
        selection: function (chart, e) {
          console.log(new Date(e.xaxis.min));
        },
        mouseMove: function (event, chartContext, config) {
          if(prestate != currentstate){
            // alert();
            const config = {
              "state": currentstate
          };
          const config1 = {
            headers: {
              'Content-Type': 'application/json',
              'email': getCookie('email2'),
              'access-token': getCookie('token'), 
              'Authorization': `Bearer ${getCookie('token')}` 
            }
          };
  
          axios.post(`https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/stateWiseLatestDataDetailed/2023`,config1,config)
              .then((res) => {
                  console.log(res.data)
                  let values = {
                      ...trendsRes
                  }
                  values.decTrail = res?.data?.DECEMBER?.erpTrailRun
                  values.decGolive = res?.data?.DECEMBER?.goLive
                  values.decDct = res?.data?.DECEMBER?.dctCompleted
  
                  values.novTrail = res?.data?.NOVEMBER?.erpTrailRun
                  values.novGolive = res?.data?.NOVEMBER?.goLive
                  values.novDct = res?.data?.NOVEMBER?.dctCompleted
  
                  values.octTrail = res?.data?.OCTOBER?.erpTrailRun
                  values.octGolive = res?.data?.OCTOBER?.goLive
                  values.octDct = res?.data?.OCTOBER?.dctCompleted
                  // setSelectedCardTrends((prev) => {
                  //     const newTrendsState = [...prev];
                  //     console.log(newTrendsState)
                  //     newTrendsState[index] = !newTrendsState[index];
                  //     return newTrendsState;
                      
                  // });
                  // console.log(newTrendsState)
                  setTrendsRes(values)
                  // setLinechart(true);
                  // setFirstchart(false);
                  // setSelectedCardIndex(index);
              })
              .catch((err) => {
                  console.log(err)
              })
          }
          setPrestate(currentstate)
        },
          animationEnd: function (chartContext, options) {
            // console.log("Chart animation has ended");

          }
            // Perform additional actions or updates here
          
      },
    },
    colors: ['#87C0C3', '#EEDBBD', '#B2B2B2'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     opacityFrom: 0.6,
    //     opacityTo: 0.8,
    //   },
    // },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'left',
    // },
    xaxis: {
      type: 'category',
      categories: ['Oct', 'Nov', 'Dec'],
    },
  };

  return (
    <div>
      <style>
        {`
        .apexcharts-toolbar {
          display: none !important;
        }
        .apexcharts-gridlines-horizontal{
          display: none !important;
        }
        .apexcharts-legend.apx-legend-position-top.apexcharts-align-left{
          justify-content: center !important;
      }
      .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
        width:253px !important;
      }
      .apexcharts-legend-text{
        color: white !important;
        font-size:10px !important;
      } 
        `}
      </style>
      <div id="chart" 
      className={modules['custom-chart-width-28']}
      >
        <ReactApexChart options={options} series={seriesData} type="area" height={250} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Linechart;
