import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useAuth } from "../../Services/Auth";

const GoiDonut = () => {
  const [apiRes, setApiRes] = useState([]);
  const { selectedBtnSancGr, selectedYear } = useAuth();

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    const requestBody = {
      financialYear: selectedYear,
      stateName: selectedBtnSancGr,
    };

    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/quarters-summary",
        requestBody,
        config
      )
      .then((response) => {
        if (response?.status === 200) {
          setApiRes(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedBtnSancGr, selectedYear]);

  const labels = apiRes ? Object.keys(apiRes).filter(key => key.includes('Quarter')) : [];
  const series = [
    apiRes.firstQuarterReleased || 0,
    apiRes.secondQuarterReleased || 0,
    apiRes.thirdQuarterReleased || 0,
    apiRes.fourthQuarterReleased || 0,
  ];

  const options = {
    labels,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        radius: 4,
      },
    },
    stroke: {
      show: false,
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          background: "#EEF4ED",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "20px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 500,
              color: "#737373",
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 700,
              color: "black",
              offsetY: 10,
              formatter: function (val) {
                return val;
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: `FY ${selectedYear}`,
                fontSize: "26px",
                fontWeight: 700,
                color: "black",
                formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#14B8A6", "#FC5046"],
  };
  return (
    <>
      <ReactApexChart options={options} series={series} height="350" type="donut" />
    </>
  );
};

export default GoiDonut;
