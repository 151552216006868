import React, { useEffect } from "react";
import { Stack, Button, Typography } from "@mui/material";
import downloadcld from "../../Assets/download-cloud-02.png";
import uploadcld from "../../Assets/Icon.png";
import down from "../../Assets/down1.svg";
import { useState } from "react";
import { getCookie } from "../../Services/Cookies";
import axios from "axios";
import DownloadIcon from "../../Assets/download.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import c_icon from "../../Assets/c_icon.png";

export default function Report() {
  const [role, setRole] = useState(getCookie("userRole"))
  const [selectedFileType, setSelectedFileType] = useState("excel");
  const apiUrl1 =
    "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/";
  const apiUrl2 =
    "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/";
  const [fileNameLabel, setFileNameLabel] = useState(
    "Drag or drop Excel sheet here"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [hi, setHi] = useState(false)

  const handleFileChange = (event) => {
    if (role !== "HO") {
      alert("Only users with the HO role can upload files.");
      return;
    }

    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setHi(true);
    }
  };

  const handleFileUpload = async () => {
    if (hi) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

      await axios.post(
        // process.env.REACT_APP_BACKEND_URI + "api/v1/nabard/upload/receive",
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/upload_MoReport",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

        alert("File uploaded successfully!");
        setSelectedFile(null);
        setFileNameLabel("Drag or drop Excel sheet here");
      } catch (error) {
        console.error("File upload failed:", error);
        alert("File upload failed. Please try again.");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleFileUpload();
      setHi(false)
    }, [1000])
  }, [hi])

  const apiUrl =
    "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/file/download/";
  const reportsToDownload = [1, 2, 3];

  const downloadFile = async (reportNumber) => {
    try {
      const response = await axios.post(
        `${apiUrl}mo_report${reportNumber}`,
        {},
        {
          responseType: "blob",
          headers: {
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      const blob = new Blob([response.data], { type: contentType });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `MO_Report_${reportNumber}.xlsx`; // You can customize the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownAll = () => {
    reportsToDownload.forEach((reportNumber) => {
      downloadFile(reportNumber);
    });
    handleDownload();
    handleDownload2();
  };

  const handleDownload = () => {
    const config = {
      headers: {
        email: getCookie("email2"), // Get the email from the cookie
        "access-token": getCookie("token"), // Get the token from the cookie
        Authorization: `Bearer ${getCookie("token")}`, // Use the token for Authorization header
      },
    };
    // get todays date
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + mm + yyyy;
    if (selectedFileType === "excel") {
      downloadFile1(
        "excel_summaryProgress",
        "ProgressReport_" + formattedToday + ".xlsx"
      );
    } else if (selectedFileType === "pdf") {
      downloadFile1(
        "pdf_summaryProgress",
        "ProgressReport_" + formattedToday + ".pdf"
      );
    }
  };

  const downloadFile1 = async (fileType, fileName) => {
    try {
      const response = await axios.post(
        `${apiUrl1}${fileType}`,
        {},
        {
          responseType: "blob",
          headers: {
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      let contentType;
      if (selectedFileType === "excel") {
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (selectedFileType === "pdf") {
        contentType = "application/pdf";
      }
      const blob = new Blob([response.data], { type: contentType });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownload2 = () => {
    // get todays date
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + mm + yyyy;
    if (selectedFileType === "excel") {
      downloadFile2(
        "excel_mission10000Form",
        "ProgressReport_" + formattedToday + ".xlsx"
      );
    } else if (selectedFileType === "pdf") {
      downloadFile2(
        "pdf_mission10000Form",
        "ProgressReport_" + formattedToday + ".pdf"
      );
    }
  };

  const downloadFile2 = async (fileType, fileName) => {
    try {
      const response = await axios.post(
        `${apiUrl2}${fileType}`,
        {},
        {
          responseType: "blob",
          headers: {
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      let contentType;

      if (selectedFileType === "excel") {
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (selectedFileType === "pdf") {
        contentType = "application/pdf";
      }
      const blob = new Blob([response.data], { type: contentType });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>

      <Typography
        sx={{
          fontSize: { md: "24px", xs: "20px" },
          paddingLeft: "2%",
          color: "#00261C",
          fontWeight: "600",
          fontFamily: "Poppins",
        }}
      >
        Reports
      </Typography>
      <Stack
        direction={{ lg: "row", md: "column", xs: "column" }}
        sx={{ marginLeft: "3% !important", width: "100%" }}
        alignItems={{ lg: "center", md: "center", xs: "flex-start" }}
        spacing={2}
      >
        <Button
          variant="contained"
          startIcon={<img src={c_icon} alt="download Icon" />}
          onClick={handleDownAll}
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#065036",
            height: "38px",
            fontSize: "14px",
            lineHeight: "0px",
            marginLeft: { md: "80% !important", xs: "0px" },
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Download All
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          marginTop: "2%",
          fontFamily: "Poppins",
          marginLeft: "1%",
          marginRight: "1%",
          width: { lg: "auto", md: "70vw", xs: "75vw" },
        }}
      >
        <TableContainer>
          <Table style={{ borderCollapse: "collapse", width: "100%" }}>
            <TableHead
            // sx={{
            //   "& th:first-child": {
            //     borderRadius: "1em 0 0 1em",
            //   },
            //   "& th:last-child": {
            //     borderRadius: "0 1em 1em 0",
            //   },
            // }}
            >
              <TableRow style={{ backgroundColor: "#EAEAEA" }}>
                <TableCell
                  className="TableHead3"
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    borderRadius: "6px 0px 0px 6px",
                    fontWeight: "bold",
                  }}
                >
                  SL:NO
                </TableCell>
                <TableCell
                  className="TableHead3"
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  List of Reports
                </TableCell>
                {getCookie("userRole") === "HO" ? (
                  <TableCell
                    className="TableHead3"
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      borderRadius: "0px 6px 6px 0px",
                      fontWeight: "bold",
                    }}
                  >
                    Upload
                  </TableCell>
                ):
                <TableCell></TableCell>
                }
                <TableCell
                  className="TableHead4"
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "0px 6px 6px 0px",
                    fontWeight: "bold",
                  }}
                >
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ background: "#fff" }}>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell sx={{ color: "#029CCD" }}>
                  Date wise report
                </TableCell>
                {getCookie("userRole") === "HO" ? (
                  <TableCell sx={{ textAlign: "center" }}>
                    <label htmlFor="fileInput">
                      <img
                        src={DownloadIcon}
                        alt=""
                        style={{ width: "20px", height: "20px", cursor: "pointer", transform: "rotate(180deg)" }}
                        onClick={handleFileUpload}
                      />
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".xlsx,.xls,.csv"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </TableCell>
                ):
                <TableCell></TableCell>
                }
                <TableCell sx={{ textAlign: "center" }}>
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => downloadFile(1)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell sx={{ color: "#029CCD" }}>
                  MoC mission 10,000
                </TableCell>
                <TableCell />
                <TableCell sx={{ textAlign: "center" }}>
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => downloadFile(2)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell sx={{ color: "#029CCD" }}>
                  Hardware details report
                </TableCell>
                <TableCell />
                <TableCell sx={{ textAlign: "center" }}>
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => downloadFile(3)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4</TableCell>
                <TableCell sx={{ color: "#029CCD" }}>
                  PACS form summary
                </TableCell>
                <TableCell />
                <TableCell sx={{ textAlign: "center" }}>
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => handleDownload()}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5</TableCell>
                <TableCell sx={{ color: "#029CCD" }}>
                  DPR form summary
                </TableCell>
                <TableCell />
                <TableCell sx={{ textAlign: "center" }}>
                  <img
                    src={DownloadIcon}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => handleDownload2()}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
