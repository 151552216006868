import axios from "axios";
import Cookies from "js-cookie";

const key1 =
  "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku";
const key2 =
  "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u";

  const axiosInstance = axios.create({
  baseURL:
    "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/",
  headers: {
    "Content-Type": "application/json",
    email: Cookies.get("email2"),
    "access-token": Cookies.get("token"),
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    const dataWithoutKeys = response.data.replace(key1, "").replace(key2, "");
    const decodedData = JSON.parse(atob(dataWithoutKeys));
    response.data = decodedData;
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
