import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreateAccount from "./Components/create/CreateAccount";
import LoginPage from "./Components/Login/LoginPage";
import Cardsite from "./Components/cards/Cardsite";
import "./App.css";
import StateWideCard from "./Components/StateWiseCards/StateWideCard";
import SystemIntegration from "./Components/SystemIntegration/SystemIntegration";
import Homepage from "./Components/HomepageNew/MainHome";
import Map from "./Components/Map/MapCompNew";
import CardStates from "./Components/28States/CardPoc";
import Main28 from "./Components/28States/Main28";
import State1 from "./Components/Map/state1";
import PacsSanc from "./Components/PacsSanc/pacsSanc";
import { AuthProvider } from "./Services/Auth";
import PrivateRoute from "./Services/PrivateRoute";
import StateDashboard from "./Components/28States/version-4/28Parent";
import PacsParent from "./Components/PacsForm/Pacsparent";
import MissionParent from "./Components/SystemIntegration/MissionParent";
import AttandenceParent from "./Components/Attendance/AttendanceParent";
import ParentReport from "./Components/MO_Report/ParentReport";
import PredictAnaly from "./Components/PredictAnaly/PredictAnaly";
import BiParent from "./Components/BI/Biparent";
import Graph from "./Components/BuisnessIntelligence/graph";
import CA from "./Components/CentralAn/CentralAn";
import GraphParent from "./Components/BuisnessIntelligence/mainGraph";
import LeaderBoard from "./Components/HomepageNew/Leaderboard";
import BudgetSancParent from "./Components/Budget Analysis/budgetParent";
import ButtonSelector from "./Components/StateDrawer/StateDrawer";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" Component={Homepage}></Route>
          <Route path="/create" Component={CreateAccount}></Route>
          <Route path="/login" Component={LoginPage}></Route>
          <Route path="/card" Component={Cardsite}></Route>
          <Route path="/statecard" Component={StateWideCard}></Route>
          <Route path="/homepage" Component={Homepage}></Route>
          <Route path="/map" Component={Map}></Route>
          <Route path="/28state" Component={CardStates}></Route>
          <Route path="/state1/:stateId" Component={State1}></Route>
          <Route path="/budgetSanc" Component={BudgetSancParent} />
          
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" Component={StateDashboard} />
            <Route path="/pacssanctioned" Component={PacsSanc}/>
            <Route path="/landingpage28state" Component={Main28} />
            <Route path="/systemIntegration" Component={SystemIntegration} />
            <Route path="/DailyProgressReport" Component={MissionParent} />
            <Route path="/datacollection" Component={PacsParent} />
            <Route path="/moReport" Component={ParentReport} />
            <Route path="/attendance" Component={AttandenceParent} />
            <Route path="/trendAnalysis" Component={PredictAnaly} />
            <Route path="/Bi" Component={BiParent} />
            <Route path="/graph" Component={Graph} />
            <Route path="/centralAnalysis" Component={CA} />
            <Route path="/graphP" Component={GraphParent} />
            <Route path="/leaderboard" Component={LeaderBoard} />
            <Route path="/buttons" Component={ButtonSelector} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;