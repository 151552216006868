import React from "react";
import "./homepage.css";
import {
  Typography,
  Box,
  Stack,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import Icon from "../../Assets/footer_nabard_icon.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import GO from "../../Assets/go_button.png";
import Bag from "../../Assets/bg_flower.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="FootDiv">
      <Stack
        direction="column"
        spacing={2}
        sx={{ zIndex: "1", position: "relative" }}
      >
        <img
          src={Bag}
          alt="Background Flower"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        />
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 4, md: 2 }}
          sx={{
            paddingLeft: { xs: "40px", md: "90px" },
            paddingTop: { xs: "10px", md: "60px" },
            width:"90%"
          }}
          justifyContent="space-between"
        >
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: "#0F6446",
                    height: "50px",
                    alignSelf: "auto",
                  }}
                />
              }
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Stack direction="column" alignItems="center">
                <img
                  src={Icon}
                  alt=""
                  style={{ width: "50px", height: "57px" }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                  }}
                >
                  NABARD
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: "white",
                  width: { xs: "162px", md: "239px" },
                  fontWeight: "500",
                  fontSize: { xs: "10px", md: "14px" },
                  lineHeight: "1.2",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT
              </Typography>
            </Stack>
            <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.2)" }} />
            <Stack direction="row" spacing={3}>
              <a
                href="https://www.facebook.com/nabardonline"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  openLinkInNewTab("https://www.facebook.com/nabardonline")
                }
              >
                <FacebookIcon sx={{ color: "white", width: "20px" }} />
              </a>

              <a
                href="https://twitter.com/nabardonline"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  openLinkInNewTab("https://twitter.com/nabardonline")
                }
              >
                <XIcon sx={{ color: "white", width: "20px" }} />
              </a>

              <a
                href="https://www.youtube.com/c/nabardonline"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  openLinkInNewTab("https://www.youtube.com/c/nabardonline")
                }
              >
                <YouTubeIcon sx={{ color: "white", width: "20px" }} />
              </a>

              <a
                href="https://www.instagram.com/nabardonline/?igshid=NzZlODBkYWE4Ng%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  openLinkInNewTab(
                    "https://www.instagram.com/nabardonline/?igshid=NzZlODBkYWE4Ng%3D%3D"
                  )
                }
              >
                <InstagramIcon sx={{ color: "white", width: "20px" }} />
              </a>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={10} className="stack2H">
            <Stack direction="column" spacing={2}>
              <Typography sx={{ fontSize: "16px" }}>Useful Links</Typography>
              <Stack direction="column" spacing={1}>
                <Typography sx={{ fontSize: "12px" }}>
                  Sign In / Sign Up
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>Testimonials</Typography>
                <Typography sx={{ fontSize: "12px" }}>About Us</Typography>
                <Typography sx={{ fontSize: "12px" }}>Contact us</Typography>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2} className="stack3H">
              <Typography sx={{ fontSize: "16px" }}>Newsletter</Typography>
              <Stack direction="column" spacing={2}>
                <Typography sx={{ width: "250px", fontSize: "12px" }}>
                  Subscribe to our weekly Newsletter and receive updates via
                  email.
                </Typography>
                <Box>
                  <TextField
                    id="textF1"
                    type="text"
                    placeholder="Enter your mail ID"
                    className="textField"
                    sx={{
                      "& .MuiInputBase-root": {
                        color: "white",
                        backgroundColor: "#274e40",
                        borderRadius: "50px",
                        border: "none",
                        width: "120%",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                  <img
                    src={GO}
                    alt="Mail Icon"
                    style={{
                      position: "relative",
                      top: "49%",
                      right: "3%",
                      cursor: "pointer",
                      transform: "translateY(-50%)",
                      height: "37px",
                      width: "37px",
                    }}
                    className="img1H"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.2)" }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingLeft: { xs: "40px", md: "90px" },
            paddingRight: "90px",
            paddingBottom: "20px",
          }}
        >
          <Typography sx={{ fontSize: { xs: "12px", md: "14px" } }}>
            Copyright ©NABARD. All Right Reserved.
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography sx={{ fontSize: "12px" }}>
              Terms & Conditions
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>Privacy Policy</Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default Footer;
