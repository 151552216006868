import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import Project from "./projectC";

export default function Budget() {
  // Data for the chart
  const chartData = [
    {
      componentName: "Total Software & DC",
      cabinetApprovalAmount: 454.24,
      committedExpenditureLiability: 568.56,
    },
    {
      componentName: "Hardware",
      cabinetApprovalAmount: 769.59,
      committedExpenditureLiability: 679.3,
    },
    {
      componentName: "SI and Support",
      cabinetApprovalAmount: 1177.53,
      committedExpenditureLiability: 886.19,
    },
    {
      componentName: "Training",
      cabinetApprovalAmount: 64.24,
      committedExpenditureLiability: 48.74,
    },
  ];

  // Extracting labels and data for cabinetApprovalAmount and committedExpenditureLiability
  const labels = chartData.map((item) => item.componentName);
  const c1Data = chartData.map((item) => item.cabinetApprovalAmount);
  const c2Data = chartData.map((item) => item.committedExpenditureLiability);

  // Chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Remove download option
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: ["#333333"],
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#333333"],
        },
      },
      title: {
        text: "Amount in Crore",
      },
    },
    colors: ["#01A76C", "#C1DEC4"],
    legend: {
      show: false, // Remove apexcharts legend
    },
  };

  // Chart series
  const chartSeries = [
    { name: "cabinetApprovalAmount", data: c1Data },
    { name: "committedExpenditureLiability", data: c2Data },
  ];

  return (
    <>
      <style>
        {`
                .apexcharts-text tspan{
                    font-family:"Poppins";
                    font-size:10px;
                }
                `}
      </style>
      <Typography
        sx={{
          color: "#00261C",
          paddingTop: "1%",
          fontSize: "24px",
          fontWeight: "550",
          lineHeight: "42px",
        }}
      >
        Budget Analysis
      </Typography>
      <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
        <Stack
          className="component1"
          sx={{ background: "#EEF4ED", paddingLeft: "2%"}}
        >
          <Stack direction="row">
            <Stack sx={{ marginTop: "3%", marginBottom: "3%", width: "100%" }}>
              <Typography
                variant="body"
                sx={{
                  color: "#333333",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "poppins",
                }}
              >
                Centrally Budget Overlay Status
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#333333",
                  fontWeight: "700",
                  fontSize: "26px",
                  fontFamily: "poppins",
                }}
              >
                2515.60 cr
              </Typography>
            </Stack>
            <Stack sx={{ justifyContent: "center", paddingRight: "2%" }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#01A76C",
                    marginRight: "5px",
                  }}
                ></div>
                <Typography
                  variant="body1"
                  sx={{ color: "#333333", fontWeight: "400", fontSize: "10px" }}
                >
                  As per cabinent approval
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#C1DEC4",
                    marginRight: "5px",
                  }}
                ></div>
                <Typography
                  variant="body1"
                  sx={{ color: "#333333", fontWeight: "400", fontSize: "10px" }}
                >
                  Committed expenditure & Liability (Cr)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              background: "#DDE5DC",
              marginRight: "4%",
              marginBottom: "3%",
            }}
          >
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={300}
            />
          </Stack>
        </Stack>
        <Stack
          className="component1"
          sx={{ background: "#EEF4ED", paddingLeft: "10px" }}
        >
          <Project />
        </Stack>
      </Stack>
    </>
  );
}
