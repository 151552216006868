import React from "react";
import nablogo from "../../Assets/nabardlogo.svg";
import profile from "../../Assets/mayor 1.svg";
import setting from "../../Assets/settings 1.svg";
import logout from "../../Assets/logout 1.svg";
import profileImage from "../../Assets/Profile image.svg";
import "./header.css";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    const config = {
      headers: {
        "access-token": getCookie("token"),
        email: getCookie("email2"),
        Authorization: `Bearer ${getCookie("token")}`,
        "Content-Type": "application/json",
      },
    };
    const requestData = {
      email: getCookie("email2"),
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/authenticate/logoff",
        requestData,
        config
      )
      .then((response) => {
        Cookies.remove("email2");
        Cookies.remove("token");
        Cookies.remove("userName");
        Cookies.remove("loginStatus");
        Cookies.remove("userRole");
        Cookies.remove("state");
        Cookies.remove("email");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const avatarStyle = {
    width: "50px",
    height: "45px",
    borderRadius: "6px",
    backgroundColor: "#065036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color:"white",
    fontFamily: "Poppins",
    fontWeight:"600",
  };

  const initials = getCookie("userName")
    .split(' ')
    .map(name => name[0])
    .join('');

  return (
    <>
      <header className="App-header1">
        <div className="options-container">
          <div className="option">
            <img
              src={profileImage}
              alt="Background"
              className="background-image"
            />
            <img src={profile} alt="Profile" className="profile-image" />
            <span className="option-label">Profile_Name</span>
          </div>

          <div className="option">
            <img src={setting} alt="Settings" className="settings-image" />
            <span className="option-label">Settings</span>
          </div>

          <div
            className="option"
            onClick={handleLogout}
            style={{ cursor: "pointer" }}
          >
            <img src={logout} alt="Log Out" className="logout-image" />
            <span className="option-label">Log Out</span>
          </div>
        </div>
      </header>
    </>
  );
}
