import React, { useState } from "react";
import searchIcon from "../../Assets/search 1.svg";
import dropdown from "../../Assets/Polygon 1.svg";
import Header from "../Header/Header";
import "./dashboard.css";
import { Navigate, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "card") {
      navigate("/card");
    }
    if (option === "statecard") {
      navigate("/statecard");
    }
    // navigate(`/${option}`);
  };
  const handleDropdownClick = (option) => {
    // Handle the click on a dropdown option here
    console.log(`Selected option: ${option}`);

    // Close the dropdown after selecting an option
    setIsDropdownOpen(false);
  };

  const overallData = () => {
    Navigate("/card");
  };

  const stateWiseData = () => {
    Navigate("/statecard");
  };

  return (
    <div className="App">
      <Header />
      {/* <header className="App-header1">
                <img src={nablogo} alt="nabLogo"  />
                <div className="options-container">
                    <div className="option">
                        <img src={profileImage} alt="Background" className="background-image" />
                        <img src={profile} alt="Profile" className="profile-image" />
                        <span className="option-label">Profile_Name</span>
                    </div>

                    <div className="option">
                        <img src={setting} alt="Settings" className="settings-image" />
                        <span className="option-label">Settings</span>
                    </div>

                    <div className="option">
                        <img src={logout} alt="Log Out" className="logout-image" />
                        <span className="option-label">Log Out</span>
                    </div>
                </div>

            </header> */}
      <div className="background-image-container">
        <div className="sub-container">
          <div className="welcome-text1">Welcome to your</div>
          <div className="dashboard-text">Dashboard</div>
          <div className="random-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
            <div>elit, sed do eiusmod tempor</div>
          </div>

          <div className="search-menu">
            <div className="search-container">
              <div className="search-icon-container">
                <img src={searchIcon} alt="Search" className="search-icon" />
              </div>
              <div className="search-input-container">
                <input
                  type="text"
                  placeholder="Search your PACs/States here"
                  className="search-field"
                />
              </div>
            </div>
            <div className="additional-options">
              <div className="button" onClick={toggleDropdown}>
                <span className="select-text">Update</span>
                <img
                  src={dropdown}
                  alt="Dropdown Icon"
                  className={` ${isDropdownOpen ? "rotate" : ""}`}
                />
              </div>
              {isDropdownOpen && (
                <div className="dropdown-options">
                  <div onClick={() => handleDropdownClick("Option 1")}>
                    PAC's
                  </div>
                  <div onClick={() => handleDropdownClick("Option 2")}>
                    Hardware
                  </div>
                  <div onClick={() => handleDropdownClick("Option 3")}>
                    System Integration
                  </div>
                </div>
              )}
              <div className="button">
                <span className="select-text">Add PACs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="options-below-container">
        <div
          className={`option-below ${
            selectedOption === "card" ? "selected" : ""
          }`}
          onClick={() => handleOptionClick("card")}
        >
          <span className="option-below-label">Overall Data</span>
        </div>
        <div
          className={`option-below ${
            selectedOption === "statecard" ? "selected" : ""
          }`}
          onClick={() => handleOptionClick("statecard")}
        >
          <span className="option-below-label">State wise Data</span>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
