import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import modules from './cardpoc.module.css';

const Firstchart = ({pacs, hardware, erpTrail, goLive}) => {
    const [data, setdata]=useState({
        pacs:4000,
        hardware:200,
        erpTrail:2700,
        goLive:100,
    })

    //http://localhost:8080/api/v1/nabard/stateWiseLatestDataDetailed
  const [chartState, setChartState] = useState({
    series: [
      {
        // data:  [4000, 200, 2700,1300],
        // data: [data.pacs, data.hardware, data.erpTrail, data.goLive]
        data: [pacs, hardware, erpTrail, goLive]
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 250,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          // dataLabels: {
          //   position: 'bottom',
          // },
        },
      },
      colors: ['#337377', '#264F57', '#1B414B', '#0A2432'],
      dataLabels: {
        enabled: true,
        // textAnchor: 'start',
        // position: 'top',
        // textAnchor: 'start',
        textAnchor: 'start',
        position: 'right',
        style: {
          colors: ['#fff'],
          fontSize:'10px',
          fontFamily:'Open Sans',
          textShadow:'none'
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
        },
        offsetX: 0,
        // dropShadow: {
        //   enabled: true,
        // },
      },
      xaxis: {
        categories: ['Trial Run', 'Go-Live', 'Total PACS','HD'],
        labels:{
            style:{
                fontSize:'12px',
                fontShadow:'none',
                // color:"white"
            }
        }
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: 'light',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
    },
  });

  return (
    <div>
      <style>
        {`
          .apexcharts-toolbar {
            display: none !important;
          }
          .apexcharts-yaxis{
            display: none !important;
          }
          .apexcharts-xaxis{
            display: none !important;
          }
          .apexcharts-gridline{
            display: none !important;
          }
          .apexcharts-grid-borders{
            display: none !important;
          }
          .apexcharts-xaxis-tick{
            display : none !important;
          },
          .apexcharts-bar-area {
            strokeWidth: 2;
            strokeColor: '#775DD0';
          }
          .apexcharts-legend-text{
            color:"white" !important;
          }
    //       path:nth-child(1) {
    // stroke: yellow;
    // stroke-dasharray: 0,62,41,400;
    // stroke-width: 2px
    //       }
        `}
      </style>
      <div id="chart" 
      // className="custom-chart-width-28"
      >
        <ReactApexChart options={chartState.options} series={chartState.series} type="bar" height={250} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Firstchart;
