import React from "react";
import Stack from "@mui/material/Stack";
import cc from "../../Assets/cc.svg";
import { Typography, Box, Divider } from "@mui/material";
export default function PcChild({ left, paddingT, align, name, value }) {
    return (
        <>
            <Stack direction="row" paddingTop={1} paddingBottom={paddingT} paddingLeft={paddingT} paddingRight={1}>
                {left === true && (
                    <Stack marginRight={1}>
                        <Box sx={{ backgroundColor: "#D9D9D980", paddingTop: "7px", paddingBottom: "-5px", paddingLeft: "16px", paddingRight: "7px", borderRadius: "8px", width: "56px" }}>
                            <img alt="credit-card" src={cc}></img>
                        </Box>

                    </Stack>
                )}
                <Stack direction="column">
                    <Typography
                        sx={{
                            color: "#065036",
                            // fontFamily: "poppins",
                            fontWeight: 600,
                            textAlign: align
                        }}>
                        {name === "Cost per unit guidelines" ?
                            (value !== undefined ? `₹ ${value.toFixed(2)}` : "₹ 0.00") :
                            (value !== undefined ? `${value} Cr.` : "0 Cr.")
                        }

                    </Typography>
                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "poppins",
                            fontWeight: 400,
                            fontSize: "12px",
                            width: "150px",
                            textAlign: align
                        }}
                    >{name}</Typography>
                </Stack>
                {left === false && (
                    <Stack marginLeft={1}>
                        <Box sx={{ backgroundColor: "#D9D9D980", paddingTop: "7px", paddingBottom: "-5px", paddingLeft: "7px", paddingRight: "7px", borderRadius: "8px" }}>
                            <img alt="credit-card" src={cc}></img>
                        </Box>

                    </Stack>
                )}

            </Stack>
        </>
    )
}