import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DrawerComp from "../Drawer/drawer";
import MainHeader from "../Header/MainHeader";
import MissionTable from "./MissionTable";

export default function MissionParent() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <style>
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            `}
      </style>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MainHeader breadcrumbs={true} />
        <DrawerComp listName={"Data Collection"} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, backgroundColor: "#f4faf8" }}
        >
          <Toolbar />
          <MissionTable />
        </Box>
      </Box>
    </div>
  );
}
