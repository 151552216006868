import React, { useEffect, useState } from 'react';
import modules from './cardpoc.module.css';
import top5 from '../../Assets/top5view.svg';
import stateimage from '../../Assets/finalstate.svg';
import Firstchart from './Firstchart';
import Linechart from './Linechart';
import axios from 'axios';
import { getCookie } from '../../Services/Cookies';
import Andaman from '../../Assets/AndamanandNicobarIsland.png'
import Arunachal from '../../Assets/ArunachalPradesh.png'
import Andhra from '../../Assets/Andhrapradesh.png'
import Assam from '../../Assets/Assam.png'
import Bihar from '../../Assets/Bihar.png'
import Chhattisgarh from '../../Assets/Chhattisgarh.png'
import Goa from '../../Assets/Goa.png'
import Gujarat from '../../Assets/Gujarat.png'
import Haryana from '../../Assets/Haryana.png'
import Himachal from '../../Assets/HimachalPradesh.png'
import Jammu from '../../Assets/JammuandKashmir.png'
import Jharkhand from '../../Assets/Jharkhand.png'
import Karnataka from '../../Assets/karnataka.png'
import Ladakh from '../../Assets/Ladakh.png'
import Madhya from '../../Assets/MadhyaPradesh.png'
import Maharashtra from '../../Assets/Maharashtra.png'
import Manipur from '../../Assets/Manipur.png'
import Meghalaya from '../../Assets/Meghalaya.png'
import Mizoram from '../../Assets/Mizoram.png'
import Nagaland from '../../Assets/Nagaland.png'
import Puducherry from '../../Assets/Puducherry.png'
import Punjab from '../../Assets/Punjab.png'
import Rajasthan from '../../Assets/Rajasthan.png'
import Sikkim from '../../Assets/Sikkim.png'
import Tamilnadu from '../../Assets/Tamilnadu.png';
import Tripura from '../../Assets/Tripura.png'
import Uttarpradesh from '../../Assets/uttarpradesh.png';
import West from '../../Assets/westbengal.png';
import close from '../../Assets/close.svg';


const CardStates = () => {
    const [isGridExpanded, setIsGridExpanded] = useState(false);
    const [states, setStates] = useState(['Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Ladakh', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Tripura', 'Uttar Pradesh', 'West Bengal'])
    const images = [Andaman, Andhra, Arunachal, Assam, Bihar, Chhattisgarh, Goa, Gujarat, Haryana, Himachal, Jammu, Jharkhand, Karnataka, Ladakh, Madhya, Maharashtra, Manipur, Meghalaya, Mizoram, Nagaland, Puducherry, Punjab, Rajasthan, Sikkim, Tamilnadu, Tripura, Uttarpradesh, West];
    const [image, setImage]= useState(stateimage);
    const [data, setdata] = useState();
    const [pacsvalues, setPacsvalues] = useState([]);
    const [hardwarevalues, setHardwrevalues] = useState([]);
    const [erpvalues, setErpvalues] = useState([]);
    const [golivevalues, setGolivevalues] = useState([]);
    const [linechart, setLinechart] = useState(false);
    const [firstchart, setFirstchart] = useState(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [top5data, setTop5data] = useState({});
    const [statename, setStatename] = useState();

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const [trendsRes, setTrendsRes] = useState(
        {
        octTrail: '',
        octGolive: '',
        octDct: '',

        novTrail: '',
        novGolive: '',
        novDct: '',

        decTrail: '',
        decGolive: '',
        decDct: '',
    })

    const octoberData = {
        "DECEMBER": {
            "pacs": 4495,
            "hardwareDelivered": 1000,
            "erpTrailRun": 1881,
            "goLive": 14,
            "dctCompleted": 2559
        },
        "NOVEMBER": {
            "pacs": 4495,
            "hardwareDelivered": 0,
            "erpTrailRun": 1204,
            "goLive": 140,
            "dctCompleted": 1634
        },
        "OCTOBER": {
            "pacs": 4495,
            "hardwareDelivered": 0,
            "erpTrailRun": 625,
            "goLive": 300,
            "dctCompleted": 950
        }
    }

    useEffect(() => {
        const config = {
            headers: {
              'email': getCookie('email2'), 
              'access-token': getCookie('token'), 
              'Authorization': `Bearer ${getCookie('token')}` 
            }
          };
        axios.post('https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/stateWiseLatestDataDetailed',{},config)
            .then((res) => {
                // console.log(res.data)
                setdata(res.data);
                setPacsvalues(Object.values(res.data).map(i => i.pacs));
                setHardwrevalues(Object.values(res.data).map(i => i.hardwareDelivered));
                setErpvalues(Object.values(res.data).map(i => i.erpTrailRun));
                setGolivevalues(Object.values(res.data).map(i => i.goLive));


            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // console.log(pacsvalues, hardwarevalues, erpvalues, golivevalues)

    const handleGridEnter = (index) => {
        setIsGridExpanded(true);
        setFirstchart(true);
        setSelectedCardIndex(index);
    };

    const handleGridLeave = () => {
        setIsGridExpanded(false);
        setFirstchart(false);
        setSelectedCardIndex(null);
    };
    const [selectedCardTrends, setSelectedCardTrends] = useState(Array(states.length).fill(false));
    const handleTrendsClick = (event, index) => {
        // alert(states[index]+ index);
        event.preventDefault();

        // const config = {
        //     "state": states[index]
        // };

        // axios.post(`https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/stateWiseLatestDataDetailed/2023`, config)
        //     .then((res) => {
        //         console.log(res.data)
        //         let values = {
        //             ...trendsRes
        //         }
        //         values.decTrail = res?.data?.DECEMBER?.erpTrailRun
        //         values.decGolive = res?.data?.DECEMBER?.goLive
        //         values.decDct = res?.data?.DECEMBER?.dctCompleted

        //         values.novTrail = res?.data?.NOVEMBER?.erpTrailRun
        //         values.novGolive = res?.data?.NOVEMBER?.goLive
        //         values.novDct = res?.data?.NOVEMBER?.dctCompleted

        //         values.octTrail = res?.data?.OCTOBER?.erpTrailRun
        //         values.octGolive = res?.data?.OCTOBER?.goLive
        //         values.octDct = res?.data?.OCTOBER?.dctCompleted
                setSelectedCardTrends((prev) => {
                    const newTrendsState = [...prev];
                    console.log(newTrendsState)
                    newTrendsState[index] = !newTrendsState[index];
                    return newTrendsState;
                    
                });
        //         // console.log(newTrendsState)
        //         setTrendsRes(values)
                setLinechart(true);
                setFirstchart(false);
                setSelectedCardIndex(index);
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })

        console.log(trendsRes)

    };


    const handleTop5 = (e, index) => {
        e.preventDefault();
        const config1 = {
            headers: {
              'Content-Type': 'application/json',
              'email': getCookie('email2'), // Get the email from the cookie
              'access-token': getCookie('token'), // Get the token from the cookie
              'Authorization': `Bearer ${getCookie('token')}` // Use the token for Authorization header
            }
          };

        setStatename(states[index])

        const config = {
            "state": states[index]
        };

        axios.post('https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/top5Districts',config1,config)
            .then((res) => {
                setIsDialogOpen(true);
                if (res?.data) {
                    setTop5data(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div
           className={ `${modules['grid-container']} ${isGridExpanded ? modules['expanded'] : ''}`}
                // className={`grid-container-pratikshaa ${isGridExpanded ? 'expanded-pratikshaa' : ''}`}
                
                // style={containerStyle}
            // onMouseEnter={()=>{handleGridEnter()}}
            // onMouseLeave={handleGridLeave}
            >
                <div className={modules['card-grid']} >
                    {states.map((i, index) => (
                        <div
                            key={index}
                            // style={{ backgroundImage: `url(${images[index]})` }}
                             style={{ backgroundImage: `linear-gradient(rgba(62, 103, 63, 0.91), rgba(62, 103, 63, 0.91)), url(${image})`, }}
                            className={ `${modules['card']} ${isGridExpanded ? modules['card-expanded'] : ''}`}
                            onMouseEnter={() => { handleGridEnter(index) }}
                            onMouseLeave={handleGridLeave}
                        >
                            
                            <div className={modules['extra-content']} >
                                <div className={modules['extra-info']} >
                                    <div  className={modules['left-content']}>
                                        <span  className={modules['statename']} >{i}</span>

                                    </div>
                                    <div  className={modules['right-content']} >
                                        <span className={ `${modules['trends']} ${selectedCardTrends[index]  ? modules['active'] : ''}`} >
                                            <a href="#" style={{ color: 'white' }}
                                                onClick={(event) => handleTrendsClick(event, index)}
                                            >TRENDS</a>
                                        </span>
                                    </div>
                                </div>
                                <div  className={modules['extra-info']} >
                                    <span className={modules['numbers']} >{pacsvalues[index]}</span>
                                </div>
                                <div  className={modules['extra-info']} >
                                    <span className={modules['description-text']} >Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</span>
                                </div>

                                {isGridExpanded && (
                                    <div style={{margin:'0', padding:'0'}}>
                                        {/* {selectedCardTrends[index] && selectedCardIndex === index ? ( */}
                                        {selectedCardTrends[index] && (

                                            <Linechart
                                                octT={trendsRes.octTrail}
                                                novT={trendsRes.novTrail}
                                                decT={trendsRes.decTrail}
                                                octG={trendsRes.octGolive}
                                                novG={trendsRes.novGolive}
                                                decG={trendsRes.decGolive}
                                                octD={trendsRes.octDct}
                                                novD={trendsRes.novDct}
                                                decD={trendsRes.decDct}
                                                currentstate={states[index]}
                                                
                                                // octT={octoberData.OCTOBER.erpTrailRun}
                                                // novT={octoberData.NOVEMBER.erpTrailRun}
                                                // decT={octoberData.DECEMBER.erpTrailRun}
                                                // octG={octoberData.OCTOBER.goLive}
                                                // novG={octoberData.NOVEMBER.goLive}
                                                // decG={octoberData.DECEMBER.goLive}
                                                // octD={octoberData.OCTOBER.dctCompleted}
                                                // novD={octoberData.NOVEMBER.dctCompleted}
                                                // decD={octoberData.DECEMBER.dctCompleted}
                                            ></Linechart>
                                            // ) : (
                                        )}
                                        {!selectedCardTrends[index] && (
                                            <Firstchart
                                                pacs={pacsvalues[index] ? pacsvalues[index] : 0}
                                                hardware={hardwarevalues[index] ? hardwarevalues[index] : 0}
                                                erpTrail={erpvalues[index] ? erpvalues[index] : 0}
                                                goLive={golivevalues[index] ? golivevalues[index] : 0}
                                            />
                                        )}
                                    </div>
                                )}

                                <div className={modules['extra-info']} style={{marginTop:"5px"}}>
                                    <div  className={modules['left-content']}>
                                        <a href="#" className={modules['see-detailed-report']} >See Detailed Report </a>

                                    </div>
                                    <div  className={modules['right-content']}>
                                        {/* <a href="#" className='top-5-view' onClick={(event)=>handleTop5(event,index)}>Top 5 View</a> */}
                                        <a href="#" className={modules['top-5-view']} onClick={(e) => handleTop5(e, index)}>Top 5 View</a>

                                        <img src={top5} className={modules['top-5img']} ></img>


                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}
                    {/* { isDialogOpen &&(
                                        <Top5 data={top5data} handleCloseDialog={handleCloseDialog}></Top5>
                                    )} */}
                </div>

            </div>
            {isDialogOpen && (

                // <Top5 data={top5data} handleCloseDialog={handleCloseDialog}></Top5>
                <>
                    <div
                        style={{
                            backgroundColor: '#C6E4D6',
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',

                        }}
                        className={modules['dialog']}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,paddingTop: '3%'}}>
                            <div className={modules['header']} >
                                Top 5 Districts for {statename}
                            </div>
                            <img src={close} className={modules['img']} 
                                onClick={handleCloseDialog}
                                alt="close"
                            ></img>
                        </div>

                        <table className={modules['custom-table']} >
                            <thead className={modules['custom-table-thead']}
                            >
                                <tr>
                                    <th>SI no.</th>
                                    <th>District Name</th>
                                    <th>Total PAC’s</th>
                                    <th>ERP Onboarded</th>
                                    <th>DCT Completed</th>
                                    <th>Go Live</th>
                                </tr>
                            </thead>
                            <tbody className={modules['custom-table-tbody']} >
                                {top5data && Object.keys(top5data).map((location, index) => (
                                    <tr key={index} className={modules['custom-table-tbody-tr']}>
                                        <td>{index + 1}</td>
                                        <td>{location}</td>
                                        <td>{top5data[location].pacs}</td>
                                        <td>{top5data[location].erpTrialRun}</td>
                                        <td>{top5data[location].dctCompleted}</td>
                                        <td>{top5data[location].goLive}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div></>
            )}
        </>


    );
};

export default CardStates;
