import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './custom.css';
import { Button } from '@mui/material';
import marked from "../../Assets/marked.png";
import { format } from 'date-fns';

const CustomCalendar = ({ date, onDateSelect, filledDates }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const curretDate = date ? date : new Date();
    const currentMonth = curretDate.getMonth();
    const currentYear = curretDate.getFullYear();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date();
    let name = month[d.getMonth()];
    const isDateFilled = (date) => {
        return filledDates.some((filledDate) => {
            // Convert filledDate to a JavaScript Date object
            const filledDateParts = filledDate.split('/');
            const filledDateObj = new Date(filledDateParts[2], filledDateParts[1] - 1, filledDateParts[0]);
            
            // Check if filledDateObj and date are on the same day
            return isSameDay(filledDateObj, date);
        });
    };
    
    // Function to compare two dates if they are on the same day
    const isSameDay = (date1, date2) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    };
    
    // Function to apply specific styles to filled dates
    const getTileClassName = ({ date }) => {
        return isDateFilled(date) ? 'filled-date' : '';
    };
    const handleClickDay = (date) => {
        onDateSelect(date);
    }
    const tileContent = ({ date }) => {
        const isFilled = isDateFilled(date);
        if (isFilled) {
            return (
                <div className="custom-tile-content">
                    <img src={marked} alt="Marked Date" className='marked-image'/>
                </div>
            );
        } else {
        }
    };


    // Get the first and last day of the current month
    const currentQuarter = Math.floor((currentMonth ) / 3); 
    console.log(currentQuarter);

    // Calculate first day of the current quarter
    const firstMonthOfQuarter = currentQuarter * 3 ; // First month of the quarter
    const firstDayOfMonth = new Date(currentYear, firstMonthOfQuarter , 1); // Set first day to 1st of first month of the quarter
    
    // Calculate last day of the current quarter
    const lastMonthOfQuarter = firstMonthOfQuarter + 2; // Last month of the quarter
    const lastDayOfMonth = new Date(currentYear, lastMonthOfQuarter+1, 0); // Set last day to the last day of the last month of the quarter

    // Ensure minDate and maxDate are within the current quarter
// const minDate = firstDayOfMonth.getMonth() === currentMonth ? firstDayOfMonth : new Date();
// const maxDate = lastDayOfMonth.getMonth() === currentMonth ? lastDayOfMonth : new Date();
    
    const isWeekend = (date) => {
        const day = date.getDay();
        return day === 0 || day === 6; // Sunday is 0, Saturday is 6
    };


    const tileDisabled = ({ date }) => {
        return (
            isWeekend(date)
        );
    };
    return (
        <div className="custom-calendar-container">
            <style>
                {
                    `
                    .react-calendar__tile{
                        border-radius: 3px;
                    }
                    .react-calendar__tile:disabled {
                        background: #f8e6e6 ; 
                         color: #d30a0a !important;
                         cursor: context-menu !important;
                         font-family:Poppins !important
                    }
                    .react-calendar__tile--now {
                        background: #FFD87A !important ;
                        color: black ;
                        font-family:Poppins !important
                      }
                      
                      .react-calendar__tile--now:enabled:hover {
                        background: #FFD87A ;
                        color: black ;
                        font-family:Poppins !important
                      }
                      .react-calendar__tile--now:enabled:focus {
                        background: #065036 ;
                        color: white !important;
                        font-family:Poppins !important
                      }
                      .react-calendar__tile {
                        max-width: 100%;
                        padding: 10px 6.6667px;
                        background: #ededed ;
                        text-align: center;
                        line-height: 16px;
                        font: inherit;
                        font-size: 0.833em;
                      }
                      abbr[title] {
                        text-decoration: none;
                    }
                    .react-calendar button {
                        margin: 0;
                        border: 0;
                        outline: none;
                        flex: 0 0 12% !important;
                        overflow: hidden;
                        margin-inline-end: 0px;
                        margin:5px 10px 5px 10px;
                    }
                    .react-calendar{
                        background-color: #F5F5F5 !important;
                    }

                    @media only screen and (max-width:360px ) {
                        .react-calendar {
                            padding:0px !important;
                            // width:230px !important
                        }
                        .react-calendar__month-view__weekdays{
                            font-size:10px !important;
                        }
                        .react-calendar__month-view__weekdays__weekday {
                            margin: 5px 0px 5px 5px !important
                        }
                        .react-calendar button{
                            margin: 5px 0px 5px 5px !important;
                            font-size:10px !important;
                        }
                    }
                    @media screen and (max-width: 606px) and (min-width: 400px) {
                        .react-calendar__month-view__weekdays{
                            font-size: 9px;
                            justify-content: center;
                        }
                        .react-calendar__month-view__days{
                            font-size: 12px;
                            justify-content: center;
                        }
                        .react-calendar button{
                            margin: 5px 0px 5px 5px !important;
                            font-size:10px !important;
                        }
                        .react-calendar__month-view__weekdays__weekday {
                            margin: 5px 0px 5px 8px !important
                        }
                    }
                    @media only screen and (width: 390px){
                        .react-calendar__month-view__weekdays{
                            font-size:10px !important;
                        }
                        .react-calendar__month-view__weekdays__weekday {
                            margin: 5px 0px 5px 5px !important
                        }
                        .react-calendar button{
                            margin: 5px 0px 5px 5px !important;
                            font-size:10px !important;
                        }
                    }
                    @media only screen and (width: 1024px){
                        .react-calendar button{
                            flex: 0 0 11.7% !important;
                        }
                    }
                    @media only screen and (width: 912px){
                        .react-calendar button{
                            flex: 0 0 11.3% !important;
                        }
                        .react-calendar__month-view__weekdays {
                            justify-content: space-around !important;
                        }
                    }
                    @media only screen and (width: 375px){
                        .react-calendar button{
                            flex: 0 0 10.2% !important;
                        }
                        .react-calendar__month-view__weekdays {
                            justify-content: space-around !important;
                        }
                    }
                    .react-calendar__tile--hasActive {
                       background: #065036 !important;
                        color: white !important;
                      }
                      
                      .react-calendar__tile--hasActive:enabled:hover,
                      .react-calendar__tile--hasActive:enabled:focus {
                        background: #065036 !important;
                        color: white !important;
                      }
                      
                      .react-calendar__tile--active {
                       background: #065036 !important;
                        color: white !important;
                        border: 2px solid #1DE29D !important;
                        box-shadow: 0.5px 0.5px 3px 3px #b9cbc5 !important;
                      }
                      
                      .react-calendar__tile--active:enabled:hover,
                      .react-calendar__tile--active:enabled:focus {
                        background: #065036 ;
                        color: white !important;
                        border: 2px solid #1DE29D !important;
                        box-shadow: 0.5px 0.5px 3px 3px #b9cbc5 !important;
                      }
                      .react-calendar__month-view__weekdays__weekday {
                        padding: 0.5em;
                        flex: 0 0 12% !important;
                        overflow: hidden;
                        margin-inline-end: 0px;
                        margin:5px 10px 5px 10px;
                      }
                      .react-calendar__navigation{
                        justify-content: space-between;
                      }
                    `
                }
            </style>
            <Calendar
                onClickDay={handleClickDay}
                value={date}
                tileDisabled={tileDisabled}
                minDate={firstDayOfMonth}
                maxDate={lastDayOfMonth}
                tileClassName={getTileClassName}
            tileContent={tileContent}
            // selectRange={true}
            />
        </div>
    );
}

export default CustomCalendar;
