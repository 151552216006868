import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DrawerComp from "../Drawer/drawer";
import MainHeader from "../Header/MainHeader";
import Budget from "./budget";
import SD from "../StateDrawer/StateDrawer";
import Row2 from "./row2comp";
import uploadcld from "../../Assets/download_btn.svg";
import GoiC from "./goi";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";

export default function BudgetSancParent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileNameLabel, setFileNameLabel] = useState("Drag or drop Excel sheet here");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleFileChange=(e)=>{
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileNameLabel(file ? file.name : "Drag or drop Excel sheet here");
  }

  const handleFileUploadHSI = async() => {
    if(!selectedFile){
      alert("Please select a file to upload.");
      return;
    }
    try{
      const formData=new FormData();
      formData.append("file",selectedFile);

      await axios.post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/upload/budget-overlay",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            email: getCookie("email2"),
            "access-token": getCookie("token"),
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      alert("File uploaded successfully!");
      setSelectedFile(null);
      setFileNameLabel("Drag or drop Excel sheet here");
    }
    catch(err){
      console.log("file upload failed : "+err);
      alert("File upload failed. Please try again.");
    }
  };
  

  return (
    <div style={{}}>
      <style>
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            `}
      </style>
      <Box sx={{ display: "flex", width: "100%" }}>
        <CssBaseline />
        <MainHeader />
        <DrawerComp listName={"Sanctioned Budget"} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#f4faf8",
            padding: "10px",
            width: "100%",
            // overflowY: "hidden",
          }}
        >
          <Toolbar />
          <Budget />
          <Stack
            direction="row"
            sx={{ paddingTop: "5%", paddingBottom: "3%" }}
            justifyContent="space-between"
          >
            <Stack direction="column">
              <Typography sx={{ fontFamily: "poppins", fontSize: "18px" }}>
                State Budget Overlay Status
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "poppins",
                  fontSize: "26px",
                }}
              >
                Hardware & SI Status
              </Typography>
            </Stack>
            <Stack 
            direction={{xs:"column", md:"row"}}
            justifyContent="space-around"
            alignItems="center"
            >
           <Typography
           sx={{
            color: "#000000",
            fontSize: "15px",
            fontWeight: "600",
            fontFamily:"Poppins",
            marginRight:"10px"
           }}
           >Upload here</Typography>
           <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                style={{
                  height: "45px",
                  flexShrink: 0,
                  borderRadius: "5px",
                  border: "1px dashed rgba(0, 0, 0, 0.46)",
                  display: "none",
                  padding:"10px"
                }}
                id="fileInput"
              />
              <label
                htmlFor="fileInput"
                className="upload-button"
                style={{
                  color: "#A4A4A4",
                  fontSize: "12px",
                  fontWeight: "100",
                  display: "inline-block",
                  cursor: "pointer",
                  textAlign: "center",
                  height: "38px",
                  flexShrink: 0,
                  borderRadius: "7.69px",
                  border: "0.96px solid #D0D5DD",
                  lineHeight: "38px",
                  fontFamily: "Poppins",
                  paddingLeft:"10px",
                  paddingRight:"10px"
                }}
              >
                {fileNameLabel}
              </label>
           <Button
              variant="contained"
              startIcon={<img src={uploadcld} alt="upload Icon" />}
              onClick={handleFileUploadHSI}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#1B8D26",
                height: "38px",
                fontSize: "14px",
                fontFamily:"Poppins",
                lineHeight: "0px",
                display:"flex",
                justifyItems:"flex-start",
                marginLeft: { md: "10px", xs: "0px" },
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              Upload
            </Button>

            </Stack>
           
          </Stack>
          <Stack
            direction="row"
            sx={{ width: "100%", height: { md: "550px", xs: "1000px" } }}
            spacing={1}
          >
            <SD />
            <Row2 />
          </Stack>
          <Stack
            sx={{ width: "100%", marginTop: "3%", backgroundColor: "#EEF4ED" }}
          >
            <GoiC />
          </Stack>
        </Box>
      </Box>
    </div>
  );
}
