// src/components/LandingPage.js
import React, { useState } from 'react';
import nablogo from "../../Assets/nabardlogo.svg";
import vec2 from "../../Assets/Vector 2.svg";
import vec3 from "../../Assets/Vector 3.svg";
import { Link, useNavigate } from 'react-router-dom';
import './style2.css'

const CreateAccount = () => {

const navigate=useNavigate();
const [userName,setUserName]=useState('');
const [email,setEmail]=useState('');
const [password,setPassword]=useState('');

const HandleSignUp=()=>{
  console.log("username:"+userName);
  console.log("email:"+email);
  console.log("password:"+password);
}

  return (
    <div className="App">
      <header className="App-header">
        <img src={nablogo} alt="Nab Logo" className="nablogo1" />
        <img src={vec2} alt="Vector 2" className="vec2" />
        <img src={vec3} alt="Vector 3" className="vec3" />

       <div> <p className="Create-text">Create Account</p>
        <h1 className="logo-header-two">or use your email for registration</h1>
        </div>
        <div className="text-fields">
          <input type="text" placeholder="User name" value={userName} onChange={(e)=>{setUserName(e.target.value)}}/>
          <input type="email" placeholder="Email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
          <input type="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
        </div>
        <button className="signup-button" onClick={HandleSignUp}>Sign Up</button>
        <p className="alternate">
          Already have an account?<Link to="/loginPage">Log in</Link>
          </p>
      </header>
    </div>
  );
}

export default CreateAccount;
