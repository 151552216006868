import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import Icon from "../../Assets/files_icon.svg";
import GBar from "./goiBar";
import Gdon from "./goiDonut";
import GoiDonut1 from "./goiDonut2";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useAuth } from "../../Services/Auth";
import { set } from "date-fns";

export default function GoiC() {
  const { selectedBtnSancGr, setSelectedBtnSancGr, setSelectedYear } = useAuth();
  const [stateNames, SetStateNames] = useState([]);
  const [financialY, SetFinancialY] = useState([]);
  const [percent, setPercent] = useState(false);
  const [goiData, setGoiData] = useState({});
  const { funds, setFunds, fund1, setFund1 } = useAuth();
  const [relFund, setRelFund] = useState();

  useEffect(() => {
    const fetchStates = async () => {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
      try {
        const response = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/fetchStates",
          {},
          config
        );
        if (response.status === 200) {
          const stateNames = response.data.map((state) => state.stateName);
          SetStateNames(stateNames);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
      const requestBody = {
        stateName: selectedBtnSancGr,
      };

      try {
        const response2 = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/state-goi",
          requestBody,
          config
        );
        if (response2.status === 200) {
          SetFinancialY(response2.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedBtnSancGr]);

  useEffect(() => {
    const handleStateCall = async () => {
      const config = {
        headers: {
          email: getCookie("email2"),
          "access-token": getCookie("token"),
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
      try {
        const response = await axios.post(
          "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/budget/goi-summary",
          {},
          config
        );
        const formattedData = Object.keys(response.data).reduce((acc, key) => {
          acc[key] = parseFloat(response.data[key]).toFixed(2);
          return acc;
        }, {});

        setGoiData(formattedData);
        setFunds(formattedData["si goi"]);
        setFund1(formattedData["hardware goi"]);
      } catch (error) {
        console.log("state call failed");
      }
    };

    handleStateCall();
  }, []);

  useEffect(() => {
    let x =
      parseFloat(goiData["fund released"]) + parseFloat(goiData["fund needed"]);
    console.log(x);
    let y = ((parseFloat(goiData["fund released"]) / x) * 100).toFixed(3);
    setPercent(y);
    console.log(y);
  }, [goiData]);

  const handleStateName = (e) => {
    setSelectedBtnSancGr(e);
  };

  const handleYear = (e) => {
    setSelectedYear(e);
  };

  return (
    <>
      <Stack padding={1}>
        <Typography
          sx={{ fontFamily: "poppins", fontSize: "20px", fontWeight: "400" }}
        >
          GoI Share
        </Typography>
        <Typography
          sx={{ fontFamily: "poppins", fontSize: "26px", fontWeight: "700" }}
        >
          {/* {data ? JSON.stringify(goiData.fundReleased):"Loading..."} */}
          {goiData["fund released"]}&nbsp;Cr.
        </Typography>
      </Stack>
      <Stack direction="row">
        <Stack
          sx={{
            backgroundColor: "#D9D9D980",
            margin: "10px",
            width: "46%",
          }}
        >
          <Stack
            direction="row"
            sx={{ paddingLeft: "2%", paddingTop: "10%", paddingRight: "1%" }}
            spacing={1}
          >
            <Stack direction="column" spacing={1}>
              <Stack
                sx={{
                  background: "#F7FCF7",
                  height: "100%",
                  borderRadius: "6px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "18px", margin: "10px" }}
                >
                  Fund Released
                </Typography>
                <Stack direction="row">
                  <img
                    src={Icon}
                    alt=""
                    style={{ width: "50px", margin: "10px" }}
                  />
                  <Typography
                    sx={{
                      color: "#333333",
                      fontWeight: "700",
                      fontSize: "20px",
                      marginTop: "10%",
                      marginRight: "1%",
                    }}
                  >
                    {goiData["fund released"]} cr
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ marginRight: "1%" }}>
                  <Stack
                    spacing={1}
                    sx={{
                      marginLeft: "5%",
                      overflow: "hidden",
                      width: "180px",
                    }}
                  >
                    <Stack
                      sx={{
                        // width: "100%",
                        height: "16px",
                        borderRadius: "16px",
                        background: "#DBDDDB",
                        overflow: "hidden",
                        opacity: "1",
                      }}
                    >
                      <Stack
                        sx={{
                          width: `${percent}%`,
                          height: "100%",
                          backgroundColor: "#058256",
                          borderRadius: "10px 0px 0px 0px",
                        }}
                      />
                    </Stack>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      {percent} %
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  background: "#F7FCF7",
                  height: "100%",
                  borderRadius: "6px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "18px", margin: "10px" }}
                >
                  Fund Needed
                </Typography>
                <Stack direction="row">
                  <img
                    src={Icon}
                    alt=""
                    style={{ width: "50px", margin: "10px" }}
                  />
                  <Stack alignContent="center">
                    <Typography
                      sx={{
                        color: "#333333",
                        fontWeight: "700",
                        fontSize: "24px",
                        marginTop: "10%",
                      }}
                    >
                      {goiData["fund needed"]} cr
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ paddingRight: "1%", height: "100%", overflow: "hidden" }}
            >
              <Stack
                sx={{
                  background: "#F7FCF7",
                  height: "100%",
                  borderRadius: "6px",
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Components wise fund needed
                </Typography>
                <Stack sx={{ marginLeft: "0%" }}>
                  <GoiDonut1 />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* //Box-2 (Sanajana's component will be there) */}
        <Box
          sx={{
            backgroundColor: "#D9D9D980",
            margin: "10px 10px 10px 0px",
            padding: "10px",
            width: "54%",
          }}
        >
          <Stack direction="row" justifyContent="end">
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              State by
            </Typography>
            <select
              style={{
                borderColor: "#D0D5DD",
                borderRadius: "7.96px",
                padding: "5px",
                marginLeft: "10px",
                width: "150px",
                outline: "none",
                cursor: "pointer",
                fontFamily: "Poppins",
              }}
              onChange={(e) => handleStateName(e.target.value)}
            >
              {stateNames &&
                stateNames.map((state, index) => (
                  <option
                    key={index}
                    value={state}
                    style={{ fontFamily: "Poppins" }}
                  >
                    {state}
                  </option>
                ))}
            </select>
          </Stack>
          <Stack direction="row" justifyContent="end" sx={{ marginTop: "5px" }}>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Financial Year
            </Typography>
            <select
              style={{
                borderColor: "#D0D5DD",
                borderRadius: "7.96px",
                padding: "5px",
                marginLeft: "10px",
                width: "150px",
                outline: "none",
                cursor: "pointer",
                fontFamily: "Poppins",
              }}
              onChange={(e) => handleYear(e.target.value)}
            >
              {financialY.financialYears &&
                Object.keys(financialY.financialYears).map((year) => (
                  <option key={year} style={{ fontFamily: "Poppins" }}>
                    {year}
                  </option>
                ))}
            </select>
          </Stack>
          <Stack
            direction="row"
            sx={{ paddingTop: "30px" }}
            spacing={1}
            alignItems="baseline"
          >
            <GBar />
            <div>
              <Gdon />
            </div>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
