import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import { useAuth } from "../../Services/Auth";
import { parse } from "date-fns";

const GoiDonut1 = () => {
  const [apiRes, setApiRes] = useState([]);
  const { funds, setFunds,fund1, setFund1 } = useAuth();

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/secretary-vs-pacs-count?category=PACS",
        {},
        config
      )
      .then((response) => {
        if (response?.status === 200) {
          setApiRes(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // const labels = apiRes.map((item) => item.hasFullTimeSecretary).reverse();
  // const series = apiRes.map((item) => item.pacsCount).reverse();

  const labels = ["Hardware GoI", "SI GoI"];

  const series = [parseFloat(fund1), parseFloat(funds)];

  const options = {
    labels,
    legend: {
        position: "bottom",
        horizontalAlign: "center",
        markers: {
            radius: 4,
        },
        itemMargin: {
            horizontal: 10,
            vertical: 5,
        },
    },
    stroke: {
        show: false,
        width: 0,
    },
    plotOptions: {
        pie: {
            donut: {
                size: "80%",
                background: "#EEF4ED",
                labels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: "20px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 500,
                        color: "#737373",
                        offsetY: -10,
                    },
                    value: {
                        show: true,
                        fontSize: "20px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 700,
                        color: "black",
                        offsetY: 10,
                    },
                    total: {
                        show: true,
                        showAlways: true,
                        label: "Fund Needed",
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "black",
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                        },
                    },
                },
            },
        },
    },
    dataLabels: {
        enabled: false,
        formatter: function (val, { seriesIndex }) {
            return `${series[seriesIndex]} cr`;
        },
        style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#333'],
        },
        dropShadow: {
            enabled: false,
        },
        // Adjust offset to position data labels outside the donut chart
        offset: -50, // You can adjust this value as needed
    },
    colors: ["#25ADE7", "#FF8267"],
};


  return (
    <>
      <ReactApexChart options={options} series={series} height="350" type="donut" />
    </>
  );
};

export default GoiDonut1;
