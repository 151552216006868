import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import "./homepage.css";
import Bag from "../../Assets/bg_flower.png";
import Card from "../../Assets/cards.png";
import { useSpring, animated } from "react-spring";
import rec1 from "../../Assets/Rectangle10.png";
import rec2 from "../../Assets/Rectangle11.png";
import rec3 from "../../Assets/Rectangle12.png";
import rec4 from "../../Assets/Rectangle13.png";
import rec5 from "../../Assets/Rectangle14.png";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { useAuth } from "../../Services/Auth";
import { getCookie } from "../../Services/Cookies";

const HomeTwo = () => {
  const navigate = useNavigate();
  const handlenavigate = (p) => {
    navigate(`/${p}`);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { ok,setOk,accessToken, setAccessToken,setNav} =
  useAuth();

  const handleClick=()=>{
    if(!getCookie("token"))
    {
    setOk(!ok);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMarginTop = (imageIndex) => {

    const baseMargins = ["-8%", "-35%", "-22%", "-32%", "-56%"]; 
    const mobileMargins = ["3.5%", "-7%", "-4%", "-10%", "-18%"]; 

    if (windowWidth <= 900) {
      return mobileMargins[imageIndex];
    } else {
      return baseMargins[imageIndex];
    }
  };

  return (
    <div className="greBox-2">
      <Box
        sx={{
          paddingRight: { xs: "0px", md: "100px" },
          paddingLeft: { xs: "20px", md: "100px" },
          paddingTop: "50px",
          paddingBottom: "2%",
          zIndex: "1",
          position: "relative",
        }}
      >
        <img
          src={Bag}
          alt="Background Flower"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: "-1",
          }}
        />
        <Stack direction="column" spacing={2}>
          <Stack
            direction="column"
            spacing={2}
            style={{
              textAlign: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: "-2%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: { xs: "30px", sm: "48px", md: "50px",lg:"50px" },
                fontFamily: "'Poppins', sans-serif",
                alignItems: "center",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              Our Benefits
            </Typography>
          </Stack>
          <Stack
            direction={{ md: "column",lg:"row", xs: "column" }}
            spacing={{ md: 2.5, xs: 1 }}
            alignItems="center"
            sx={{ marginLeft: "0%", marginTop: "2%"}}
            justifyContent="center"
          >
            <Stack
              direction={{ md: "row", xs: "column" }}
              spacing={{ md: 2.5, xs: 2 }}
              sx={{ paddingTop: { xs: "2%", md: "0%" } }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  width: { lg: "200px",md:"200px",xs: "18rem" },
                  height: { lg: "450px",md:"450px",xs: "350px" },
                  borderRadius: "6px",
                }}
              >
                <Stack direction="column" spacing={2.45} alignItems="center"
                onClick={()=>{
                  navigate('/Bi') ;setNav('Bi');}}
                style={{ cursor: "pointer" }}
                >
                  <img
                    src={rec1}
                    alt="rec1"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "0%",
                      position: "relative",
                      marginTop: "3.5%",
                      borderRadius: "6px",
                      paddingTop: "2px",
                      marginTop: window.innerWidth <= 900 ? "3.5%" : "-8%",
                    }}
                    onClick={handleClick}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#204e51",
                      fontSize: { md: "18.5px", xs: "18px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                      paddingTop:"2%"
                    }}
                  >
                    Business Intelligence
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      color: "#1E1E1E",
                      fontSize: { md: "16px", xs: "14px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                      paddingBottom: "5px",
                    }}
                  >
                    This feature helps assess Financial health of PACS,
                    aggregate it at block, district, state level to draw
                    insights on to PACS’S Business
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  width: { md: "200px", xs: "18rem" },
                  height: { md: "450px", xs: "350px" },
                  borderRadius: "6px",
                }}
              >
                <Stack direction="column" spacing={2.5} alignItems="center"
                onClick={()=>{
                  navigate('/moReport') ;setNav('moReport');}}
                style={{ cursor: "pointer" }}
                >
                  
                  <img
                    src={rec2}
                    alt="rec2"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "0%",
                      position: "relative",
                      marginTop:getMarginTop(1)
                    }}
                    onClick={handleClick}
                  />
                  
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#204e51",
                      fontSize: { md: "20px", xs: "18px" },
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Reports
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      color: "#1E1E1E",
                      fontSize: { md: "16px", xs: "14px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    This feature allows to download various reports in simple
                    csv, excel format for further analysis
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
               direction={{ md: "row", xs: "column" }}
              spacing={{ md: 2.5, xs: 2 }}
              sx={{ paddingTop: { xs: "2%", md: "0%" } }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  width: { md: "200px", xs: "18rem" },
                  height: { md: "450px", xs: "350px" },
                  borderRadius: "6px",
                }}
              >
                <Stack direction="column" spacing={2.5} alignItems="center"
                onClick={()=>{
                  navigate('/trendAnalysis') ;setNav('trendAnalysis');}}
                style={{ cursor: "pointer" }}
                >
                  <img
                    src={rec3}
                    alt="rec3"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "0%",
                      marginTop:getMarginTop(2)
                    }}
                    onClick={handleClick}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#204e51",
                      fontSize: { md: "20px", xs: "18px" },
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Trend Analysis
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      color: "#1E1E1E",
                      fontSize: { md: "16px", xs: "14px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    This feature tracks the project progress trends, PACS
                    financials trends and helps predict future trends for key
                    metrics
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  width: { md: "200px", xs: "18rem" },
                  height: { md: "450px", xs: "350px" },
                  borderRadius: "6px",
                }}
              >
                <Stack direction="column" spacing={2.5} alignItems="center"
                onClick={()=>{
                  navigate('/centralAnalysis') ;setNav('centralAnalysis');}}
                >
                  <img
                    src={rec4}
                    alt="rec4"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "0%",
                      marginTop:getMarginTop(3),
                      cursor:"pointer"
                    }}
                    onClick={handleClick}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#204e51",
                      fontSize: { md: "20px", xs: "18px" },
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Central Analytics
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      color: "#1E1E1E",
                      fontSize: { md: "16px", xs: "14px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Summary on System Integrators across India, their resource
                    utilization, project progress.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={{ md: 2.5, xs: 2 }}
              sx={{ paddingTop: { xs: "2%", md: "0%" } }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  width: { md: "200px", xs: "18rem" },
                  height: { md: "450px", xs: "350px" },
                  borderRadius: "6px",
                }}
              >
                <Stack
                  direction="column"
                  spacing={2.5}
                  alignItems="center"
                  onClick={()=>{
                    navigate('/datacollection') ;setNav('datacollection');}}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={rec5}
                    alt="rec5"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "0%",
                      marginTop:getMarginTop(4),
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#204e51",
                      fontSize: { md: "20px", xs: "18px" },
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Data Collection
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      color: "#1E1E1E",
                      fontSize: { md: "16px", xs: "14px" },
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Console for PACS level data collection across states
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default HomeTwo;
