import React from "react";
import { Typography, Stack, Box, Paper } from "@mui/material";
import "./homepage.css";
import Bag from "../../Assets/bg_flower.png";
import Card from "../../Assets/cards.png";
import { useSpring, animated } from "react-spring";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 100,
    config: { mass: 1, tension: 120, friction: 40 },
  });
  return (
    <animated.div style={{ textShadow: "0px 11px 5px rgba(0, 0, 0, 0.25)" }}>
      {number.to((n) => n.toFixed(0))}
    </animated.div>
  );
}

function Number1({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 100,
    config: { mass: 1, tension: 120, friction: 40 },
  });
  return (
    <>
      <Stack direction="row" spacing={1}>
        <animated.div
          style={{ textShadow: "0px 11px 5px rgba(0, 0, 0, 0.25)" }}
        >
          {number.to((n) => n.toFixed(0))}
        </animated.div>
        <Typography
          sx={{
            fontSize: "60px",
            color: "white",
            fontWeight: "600",
            height: "80px",
            textShadow: "0px 11px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          Cr.
        </Typography>
      </Stack>
    </>
  );
}

const Homeone = () => {
  return (
    <Box
      className="greBox"
      sx={{
        position: "relative",
        zIndex: "-99",
        paddingRight: { xs: "0px", md: "100px" },
        paddingLeft: { xs: "0px", md: "100px" },
        paddingTop: "50px",
      }}
    >
      <img
        src={Bag}
        alt="Background Flower"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1",
        }}
      />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 6, md: 24 }}
        alignItems={{ xs: "center", md: "flex-start" }}
        justifyContent="space-around"
        className="stack1"
      >
        <Stack direction="column" spacing={2}>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              fontFamily: "'Poppins', sans-serif",
            }}
            className="textMainH"
          >
            {" "}
            Computerization Project MIS{" "}
          </Typography>
          <Typography
            sx={{
              width: "450px",
              lineHeight: "30px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "400",
              textAlign: { xs: "center", md: "left" },
            }}
            className="textBodyH"
          >
            {" "}
            This tool is used for decision-making, and for the coordination,
            control, analysis, and visualization of information of the Centrally
            Sponsored Project for Computerisation of Primary Agricultural Credit
            Societies. In order to make PACS self-reliant in tune with
            ‘Atmanirbhar Bharat Abhiyaan’, the Government of India (GoI) has
            launched this scheme for a period of five years from 2022-23 to
            2026-27
          </Typography>
        </Stack>
        <Stack direction="column" spacing={4} alignItems="center" className="stack22">
          <Stack direction="row" spacing={6} alignItems="center">
            <div>
              <Paper
                elevation={3}
                sx={{
                  backgroundColor: "#386e5b",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "-9px 9px 4px rgb(20 29 19 / 29%)",
                }}
              >
                <Stack direction="column" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "60px",
                      color: "white",
                      fontWeight: "600",
                      height: "80px",
                    }}
                  >
                    <Number n={28} />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: "white",
                      textShadow: "0px 5px 2px #0000006b",
                    }}
                  >
                    STATES INVOLVED
                  </Typography>
                </Stack>
              </Paper>
            </div>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#386e5b",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "-9px 9px 4px rgb(20 29 19 / 29%)",
              }}
              className="pacsDivH"
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography
                  sx={{
                    fontSize: "60px",
                    color: "white",
                    fontWeight: "600",
                    height: "80px",
                  }}
                >
                  <Number n={62317} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: "white",
                    textShadow: "0px 5px 2px #0000006b",
                  }}
                >
                  PACS SANCTIONED
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Paper
            elevation={3}
            sx={{
              backgroundColor: "#386e5b",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "-9px 9px 4px rgb(20 29 19 / 29%)",
            }}
          >
            <Stack direction="column" alignItems="flex-start">
              <Typography
                sx={{
                  fontSize: "60px",
                  color: "white",
                  fontWeight: "600",
                  height: "80px",
                }}
              >
                <Number1 n={2516} />
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "white",
                  textShadow: "0px 5px 2px #0000006b",
                }}
              >
                SANCTIONED BUDGET
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Homeone;
