export function setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + 12 * 60 * 60 * 1000);
    document.cookie =
        // cname + "=" + cvalue + ";" + expires + ";path=/";
        cname +
        "=" +
        cvalue +
        ";";
}
  
export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }