import React from "react";
import './28Dashboard.css';
export default function ShimmerUi(){
    return(
        <div className="main-shimmer">
        <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line end"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line end"></div>
          <div className="shimmerBG content-line"></div>
</div>
    )
}