import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DrawerComp from "../Drawer/drawer";
import MainHeader from "../Header/MainHeader";
import CentralGraph from "./CentralAnChart";
import axios from "axios";
import { getCookie } from "../../Services/Cookies";
import {
  Divider,
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const CentralAnaly = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <style>
        {/* check */}
        {`
            .css-10hburv-MuiTypography-root{
              font-size:11px !important;
              font-family: Poppins 
            }
            .css-yb0lig{
              font-size:12px !important
            }
            .apexcharts-grid-borders{
                display: none !important;
            }
            .apexcharts-toolbar{
                display:none !important;
            }
            .apexcharts-xaxis-tick{
                display:none !important;
            }
            .apexcharts-legend-text{
                margin-left:5px !important;
            }
            `}
      </style>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MainHeader />
        <DrawerComp listName={"Central Analytics"} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: "#fff",
            overflowY: "hidden",
            overflowX: "auto",
          }}
        >
          <Toolbar />
          <CentralGraph />
        </Box>
      </Box>
    </div>
  );
};

export default CentralAnaly;
