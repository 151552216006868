import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCookie } from "../../Services/Cookies";
import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import axios from "axios";
import deco1 from "../../Assets/deco1.png";
import deco2 from "../../Assets/deco2.png";
import "./donut.css";
import { AutoLogout } from "../../Services/AutoLogout";

const DonutChart = () => {
  const [apiRes, setApiRes] = useState([]);
  const [key1, setKey1] = useState(
    "wVgGhlIOKAnFRleHQgdG8gQmFzZTY04oCdIGNvbnZlcnRlciBpcyBhIHNpbXBsZSBlbmNvZGVyIHRvb2wgdGhhdCBhbGxvd3MgeW91IHRvIGNvbnZlcnQgb25saW5lIHRleHQgdG8gQmFzZTY0ICh0aGF0IGlzLCBpdCBlbmNvZGVzIGFueSB0ZXh0dWFsIGNoYXJhY3RlcnMgaW50byBhIGJhc2ljIEFTQ0lJIHN0cmluZyku"
  );
  const [key2, setKey2] = useState(
    "wRG8geW91IGhhdmUgdG8gZGVhbCB3aXRoIEJhc2U2NCBmb3JtYXQ/IFRoZW4gdGhpcyBzaXRlIGlzIHBlcmZlY3QgZm9yIHlvdSEgVXNlIG91ciBzdXBlciBoYW5keSBvbmxpbmUgdG9vbCB0byBlbmNvZGUgb3IgZGVjb2RlIHlvdXIgZGF0YXNldC4u"
  );

  useEffect(() => {
    const config = {
      headers: {
        email: getCookie("email2"),
        "access-token": getCookie("token"),
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
    axios
      .post(
        "https://nabard-dev-backend.centralindia.cloudapp.azure.com/api/v1/nabard/bo/secretary-vs-pacs-count?category=PACS",{},
        config
      )
      .then((response) => 
      {
        const dataWithoutKeys = response.data.replace(key1, '').replace(key2, '');
        const decodedData = JSON.parse(atob(dataWithoutKeys));
        if(response?.status === 200){
          setApiRes(decodedData);
        }
        else if (response?.response?.status === 403) {
          AutoLogout();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const labels = apiRes.map((item) => item.hasFullTimeSecretary).reverse();
  const series = apiRes.map((item) => item.pacsCount).reverse();
  const yesValue = apiRes.find(item => item.hasFullTimeSecretary === "yes")?.pacsCount;

  const options = {
    labels,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        radius: 4,
        width: 16,
        height: 16,
      },
      itemMargin: {
        horizontal: 20,
        vertical: 30,
      },
    },
    stroke: {
      show: false,
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          background: "#EEF4ED",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "40px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 500,
              color: "#737373",
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "40px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 700,
              color: "black",
              offsetY: 10,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total Value",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#14B8A6","#FC5046" ],
  };

  return (
    <div className="donutChartCont">
      <img
        src={deco1}
        alt="deco1"
        style={{ position: "absolute", bottom: 0, right: 0 }}
      />
      <img
        src={deco2}
        alt="deco2"
        style={{ position: "absolute", top: 0, left: 0, width:"300px", paddingLeft:"13px", paddingTop: "8px",}}
      />
      <Typography
        sx={{ paddingLeft: "20px", paddingTop: "20px", fontSize: "20px",fontFamily:"Poppins" }}
      >
        {" "}
        Do you have full time secretary?
      </Typography>
      <Typography
        sx={{
          paddingLeft: "20px",
          // paddingTop: "5px",
          paddingBottom: "50px",
          fontSize: "25px",
          fontWeight: "700",
          fontFamily:"Poppins"
        }}
      >
        {" "} Yes - {yesValue}{" "}
      </Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="320"
        width="100%"
      />
    </div>
  );
};

export default DonutChart;
