
import React from 'react';
import "./StateCard.css"
import { useState } from 'react';
import NavbarCard from '../CardNav/Navbar';
import Andaman from '../../Assets/AndamanandNicobarIsland.png'
import Arunachal from '../../Assets/ArunachalPradesh.png'
import Andhra from '../../Assets/Andhrapradesh.png'
import Assam from '../../Assets/Assam.png'
import Bihar from '../../Assets/Bihar.png'
import Chhattisgarh from '../../Assets/Chhattisgarh.png'
import Goa from '../../Assets/Goa.png'
import Gujarat from '../../Assets/Gujarat.png'
import Haryana from '../../Assets/Haryana.png'
import Himachal from '../../Assets/HimachalPradesh.png'
import Jammu from '../../Assets/JammuandKashmir.png'
import Jharkhand from '../../Assets/Jharkhand.png'
import Karnataka from '../../Assets/karnataka.png'
import Ladakh from '../../Assets/Ladakh.png'
import Madhya from '../../Assets/MadhyaPradesh.png'
import Maharashtra from '../../Assets/Maharashtra.png'
import Manipur from '../../Assets/Manipur.png'
import Meghalaya from '../../Assets/Meghalaya.png'
import Mizoram from '../../Assets/Mizoram.png'
import Nagaland from '../../Assets/Nagaland.png'
import Puducherry from '../../Assets/Puducherry.png'
import Punjab from '../../Assets/Punjab.png'
import Rajasthan from '../../Assets/Rajasthan.png'
import Sikkim from '../../Assets/Sikkim.png'
import Tamilnadu from '../../Assets/Tamilnadu.png'
import Tripura from '../../Assets/Tripura.png'
import Uttarpradesh from '../../Assets/uttarpradesh.png'
import West from '../../Assets/westbengal.png'
import vector5 from '../../Assets/Vector5.svg'
import Dashboard from '../Dashboard/Dashboard';

const StateWideCard = () => {
const images=[Andaman,Andhra,Arunachal,Assam,Bihar,Chhattisgarh,Goa,Gujarat,Haryana,Himachal,Jammu,Jharkhand,Karnataka,Ladakh,Madhya,Maharashtra,Manipur,Meghalaya,Mizoram,Nagaland,Puducherry,Punjab,Rajasthan,Sikkim,Tamilnadu,Tripura,Uttarpradesh,West];
const [states,setStates]=useState(['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Ladakh','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Puducherry','Punjab','Rajasthan','Sikkim','Tamil Nadu','Tripura','Uttar Pradesh','West Bengal'])
const [pac,setPac]=useState(0);
const [hardware,setHardware]=useState(0);
const [system,setSystem]=useState(0);


const MoreInfo=()=>{
  console.log("hello");
} 

 return (
<div>
     <Dashboard/>
     <div className="card-container">
    {
      states.map((i,index)=>{
        return <div style={{backgroundImage:`url(${images[index]})`}} className="statecard">
          <div className='textUnder'>
            {i}
            <div className="separate-card">
              <div>
              <p className='data'>PAC Delivered</p>
              <p className='data'>Hardware Delivered</p>
              <p className='data'>System Integration Status</p>
              </div>
              <div className='bar' style={{width:'1px',height:'95px',strokeWidth:"2px",stroke:"rgba(255, 255, 255, 0.21)",backgroundImage:`url(${vector5})`,marginLeft:"5%",marginTop:"3%"}}>
              </div>
              <div>
              <p className='values'>{pac}</p>
              <p className='values'>{hardware}</p>
              <p className='values'>{system}</p>
              </div>
            </div>
            </div>
            <button className='More-Info' onClick={MoreInfo}>More Info</button>
          </div>
      })
     }
    </div>
  </div>
  );
}

export default StateWideCard;

